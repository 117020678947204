import axios from "axios";
// export const SERVER_URL = "http://localhost:8000";
export const SERVER_URL = "https://bronxrivermedical.com/api";
export const API_URL = `${SERVER_URL}/api`;

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const secureApi = (token) =>
  axios.create({
    baseURL: API_URL,
    headers: {
      "Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      Authorization: `Bearer ${token}`,
    },
  });

// Patient Type
export const PERSONAL_INJURY_PATIENT = "Personal Injury Patient";
export const CHRONIC_PAIN_PATIENT = "Chronic Pain Patient";

// Note PDF URL
export const PAIN_CONSULT_NOTE_URL = `${SERVER_URL}/pain-consult-note`;
export const PROCEDURE_NOTE_URL = `${SERVER_URL}/procedure-note`;
export const FOLLOW_UP_NOTE_URL = `${SERVER_URL}/followup-note`;
export const TELEHEALTH_CONSULT_NOTE_URL = `${SERVER_URL}/telehealth-consult-note`;
export const AVATAR_URL = `${SERVER_URL}/avatar`;

// Form PDF URL
export const CONSENT_FORM_URL = `${SERVER_URL}/consent-form`;
export const HIPAA_FORM_URL = `${SERVER_URL}/hipaa-form`;

// Attachment File URL
export const DEMOGRAPHICS_URL = `${SERVER_URL}/demographics`;
export const IMAGES_REVIEWED_URL = `${SERVER_URL}/images-reviewed`;
export const ATTACHMENT_URL = `${SERVER_URL}/attachment`;

// Define types of notes
export const PAIN_CONSULT_NOTE_TYPE = "Pain Consult Note";
export const PROCEDURE_NOTE_TYPE = "Procedure Note";
export const FOLLOW_UP_NOTE_TYPE = "Follow Up Note";
export const TELEHEALTH_CONSULT_NOTE_TYPE = "Telehealth Consult Note";

// Define complaints
export const complaints = [
  "Neck + Left Arm",
  "Neck + Right Arm",
  "Neck + Bilateral Arms",
  "Neck (left)",
  "Neck (right)",
  "Neck (bilateral)",
  "Low Back + Left Leg",
  "Low Back + Right Leg",
  "Low Back + Bilateral Legs",
  "Low Back (left)",
  "Low Back (right)",
  "Low Back (bilateral)",
];

export const complaints_keys = [
  "neck and left arm pain",
  "neck and right arm pain",
  "neck and both arms pain",
  "left neck pain",
  "right neck pain",
  "both sides of neck pain",
  "lower back and left leg pain",
  "lower back and right leg pain",
  "lower back and both legs pain",
  "left lower back pain",
  "right lower back pain",
  "lower back pain on both sides",
];

export const complaints_values = [
  "Patient presents for initial evaluation for neck and arm pain. Pain started 6 weeks ago. It is not associated with any inciting injury. Pain radiates to down the left arm. Pain is described as shooting and electric shock when radiating. Pain is worsened with sitting, coughing and bearing down. Patient has failed conservative treatment with acetaminophen, NSAIDs, muscle relaxants, and physical therapy/HEP. Pain is causing significant functional limitation resulting in diminished quality of life and impaired age-appropriate ADL's. Patient denies loss of bowel/bladder function, new motor deficits, fevers, or chills. There is also associated numbness/tingling.",
  "Patient presents for initial evaluation for neck and arm pain. Pain started 6 weeks ago. It is not associated with any inciting injury. Pain radiates to down the right arm. Pain is described as shooting and electric shock when radiating. Pain is worsened with sitting, coughing and bearing down. Patient has failed conservative treatment with acetaminophen, NSAIDs, muscle relaxants, and physical therapy/HEP. Pain is causing significant functional limitation resulting in diminished quality of life and impaired age-appropriate ADL's. Patient denies loss of bowel/bladder function, new motor deficits, fevers, or chills. There is also associated numbness/tingling.",
  "Patient presents for initial evaluation for neck and arm pain. Pain started 6 weeks ago. It is not associated with any inciting injury. Pain radiates to down the bilateral arms. Pain is described as shooting and electric shock when radiating. Pain is worsened with sitting, coughing and bearing down. Patient has failed conservative treatment with acetaminophen, NSAIDs, muscle relaxants, and physical therapy/HEP. Pain is causing significant functional limitation resulting in diminished quality of life and impaired age-appropriate ADL's. Patient denies loss of bowel/bladder function, new motor deficits, fevers, or chills. There is also associated numbness/tingling.",
  "Patient presents for initial evaluation for neck pain. Pain is not associated with any inciting injury. Pain does not radiate to the upper extremities; it at times, refers to the base of the head. Pain is exacerbated by extension and rotation of the neck. Pain occurs intermittently. It is described as dull and aching. Patient has failed conservative treatment with NSAIDs, muscle relaxants, Tylenol, and physical therapy. Pain is causing significant functional limitation resulting in diminished quality of life and impaired age-appropriate ADLs. Denies fever, chills, numbness/tingling, bowel/bladder dysfunction, motor weakness. Pain started 4 months ago and has progressively worsened.",
  "Patient presents for initial evaluation for neck pain. Pain is not associated with any inciting injury. Pain does not radiate to the upper extremities; it at times, refers to the base of the head. Pain is exacerbated by extension and rotation of the neck. Pain occurs intermittently. It is described as dull and aching. Patient has failed conservative treatment with NSAIDs, muscle relaxants, Tylenol, and physical therapy. Pain is causing significant functional limitation resulting in diminished quality of life and impaired age-appropriate ADLs. Denies fever, chills, numbness/tingling, bowel/bladder dysfunction, motor weakness. Pain started 4 months ago and has progressively worsened.",
  "Patient presents for initial evaluation for neck pain. Pain is not associated with any inciting injury. Pain does not radiate to the upper extremities; it at times, refers to the base of the head. Pain is exacerbated by extension and rotation of the neck. Pain occurs intermittently. It is described as dull and aching. Patient has failed conservative treatment with NSAIDs, muscle relaxants, Tylenol, and physical therapy. Pain is causing significant functional limitation resulting in diminished quality of life and impaired age-appropriate ADLs. Denies fever, chills, numbness/tingling, bowel/bladder dysfunction, motor weakness. Pain started 4 months ago and has progressively worsened.",
  "Patient presents for initial evaluation for low back and leg pain. Pain started 8 weeks ago and pain rated 7/10 or higher. It is not associated with any inciting injury. Pain radiates to down the left leg. Pain is described as shooting and electric shock when radiating. Pain is worsened with sitting, coughing and bearing down. Patient has failed conservative treatment with acetaminophen, NSAIDs, muscle relaxants, and physical therapy/HEP. Pain is causing significant functional limitation resulting in diminished quality of life and impaired age-appropriate ADL's. Patient denies loss of bowel/bladder function, new motor deficits, fevers, or chills. There is associated numbness/tingling.",
  "Patient presents for initial evaluation for low back and leg pain. Pain started 8 weeks ago and pain rated 7/10 or higher. It is not associated with any inciting injury. Pain radiates to down the right leg. Pain is described as shooting and electric shock when radiating. Pain is worsened with sitting, coughing and bearing down. Patient has failed conservative treatment with acetaminophen, NSAIDs, muscle relaxants, and physical therapy/HEP. Pain is causing significant functional limitation resulting in diminished quality of life and impaired age-appropriate ADL's. Patient denies loss of bowel/bladder function, new motor deficits, fevers, or chills. There is associated numbness/tingling.",
  "Patient presents for initial evaluation for low back and leg pain. Pain started 8 weeks ago and pain rated 7/10 or higher. It is not associated with any inciting injury. Pain radiates to down the bilateral legs. Pain is described as shooting and electric shock when radiating. Pain is worsened with sitting, coughing and bearing down. Patient has failed conservative treatment with acetaminophen, NSAIDs, muscle relaxants, and physical therapy/HEP. Pain is causing significant functional limitation resulting in diminished quality of life and impaired age-appropriate ADL's. Patient denies loss of bowel/bladder function, new motor deficits, fevers, or chills. There is associated numbness/tingling.",
  "Patient presents for initial evaluation for longstanding low back pain greater than 3 months with pain rated 7/10 or higher. Pain is described as dull and aching. Pain does not radiate to the lower extremities. Pain is worsened with extension and rotation of the lumbar spine. Patient has failed conservative treatment with acetaminophen, NSAIDs, muscle relaxants, and physical therapy. Pain is causing significant functional limitation resulting in diminished quality of life and impaired age-appropriate ADLs of making bed and grooming. Patient denies numbness/tingling, loss of bowel/bladder function, new motor deficits, fevers, or chills.",
  "Patient presents for initial evaluation for longstanding low back pain greater than 3 months with pain rated 7/10 or higher. Pain is described as dull and aching. Pain does not radiate to the lower extremities. Pain is worsened with extension and rotation of the lumbar spine. Patient has failed conservative treatment with acetaminophen, NSAIDs, muscle relaxants, and physical therapy. Pain is causing significant functional limitation resulting in diminished quality of life and impaired age-appropriate ADLs of making bed and grooming. Patient denies numbness/tingling, loss of bowel/bladder function, new motor deficits, fevers, or chills.",
  "Patient presents for initial evaluation for longstanding low back pain greater than 3 months with pain rated 7/10 or higher. Pain is described as dull and aching. Pain does not radiate to the lower extremities. Pain is worsened with extension and rotation of the lumbar spine. Patient has failed conservative treatment with acetaminophen, NSAIDs, muscle relaxants, and physical therapy. Pain is causing significant functional limitation resulting in diminished quality of life and impaired age-appropriate ADLs of making bed and grooming. Patient denies numbness/tingling, loss of bowel/bladder function, new motor deficits, fevers, or chills.",
];

export const interimHPIs = [
  "Neck pain with radiation to left arm that has not improved despite conservative and physical therapy.",
  "Neck pain with radiation to right arm that has not improved despite conservative and physical therapy.",
  "Neck pain with radiation to bilateral arms that has not improved despite conservative and physical therapy.",
  "Left neck pain that has not improved despite conservative and physical therapy.",
  "Right neck pain that has not improved despite conservative and physical therapy.",
  "Bilateral neck pain that has not improved despite conservative and physical therapy.",
  "Low back pain with radiation to the left leg persists and has not improved despite conservative and physical therapy.",
  "Low back pain with radiation to the right leg persists and has not improved despite conservative and physical therapy.",
  "Low back pain with radiation to the bilateral legs persists and has not improved despite conservative and physical therapy.",
  "Left axial low back pain persists and has not improved despite conservative and physical therapy.",
  "Right axial low back pain persists and has not improved despite conservative and physical therapy.",
  "Bilateral axial low back pain persists and has not improved despite conservative and physical therapy.",
  "Additionally, myofascial pain persists and has not improved despite conservative, physical therapy, and prior trigger point injections.",
];

export const CHRONIC_PAINS = {
  headache: "headache",
  neckPain: "neck pain",
  cervicalDystonia: "cervical dystonia",
  backPain: "back pain",
  shoulderPain: "shoulder pain",
  elbowPain: "elbow pain",
  wristPain: "wrist pain",
  fingerPain: "finger pain",
  chestPain: "chest pain",
  abdominalPain: "abdominal pain",
  hipPain: "hip pain",
  legPain: "leg pain",
  kneePain: "knee pain",
  customPain: "custom pain",
};

export const CHARACTERISTICS = {
  onset: [
    "Suddenly within the past hour",
    "Earlier today",
    "A few days ago",
    "Over a week ago",
    "Gradually over several weeks or longer",
  ],
  provocationPalliation: [
    "Movement or activity makes it worse",
    "Rest improves it",
    "Medication provides relief",
    "Heat or cold application affects it",
    "It's unaffected by position or other factors",
  ],
  quality: [
    "Sharp or stabbing",
    "Dull or aching",
    "Throbbing or pulsating",
    "Burning or tingling",
    "Numbness",
  ],
  regionRadiation: [
    "Localized to one specific area",
    "Spreads to surrounding areas",
    "Radiates down a limb",
    "Moves to different parts of the body",
    "Entire body is affected",
  ],
  severity: [
    "No pain (0)",
    "Mild (1-3)",
    "Moderate (4-6)",
    "Severe (7-9)",
    "Worst possible pain (10)",
  ],
  timing: [
    "Constant without relief",
    "Comes and goes intermittently",
    "Mostly present in the morning",
    "Worsens at night",
    "Variable timing",
  ],
  understanding: [
    "Result of a known injury or activity",
    "Related to a medical condition I have",
    "Caused by stress or emotional factors",
    "Appears to be random or unexplained",
    "Other reason",
  ],
};

export const HEADACHE_CHARACTERISTICS = {
  onset: ["Over a month", "Over 6 months", "Over 12 months"],
  headacheDaysPerMonth: ["10", "15", "Other"],
  headacheHoursPerDay: ["4", "6", "Other"],
  disability: ["Missing work", "Missing school", "Other"],
  intensity: ["Mild", "Moderate", "Severe"],
  erVisits: ["Yes", "No"],
  associatedSymptoms: [
    "Nausea",
    "Vomiting",
    "Photophobia",
    "Phonophobia",
    "Unilateral",
    "Pulsating",
  ],
  reviewOfSystems: [
    { key: "fever", label: "Fever" },
    { key: "chills", label: "Chills" },
    { key: "visualDisturbances", label: "Visual disturbances" },
    { key: "neckStiffness", label: "Neck stiffness" },
    { key: "numbnessTingling", label: "Numbness/Tingling" },
    { key: "dizziness", label: "Dizziness" },
    { key: "changesInBowel", label: "Changes in bowel/bladder function" },
    { key: "motorWeakness", label: "Motor weakness" },
    { key: "speechDifficulties", label: "Speech difficulties" },
  ],
};

export const CERVICALDYSTONIA_CHARACTERISTICS = {
  onset: [
    "Over a week ago",
    "Over 1 month ago",
    "Over 3 months ago",
    "Over 6 months ago",
  ],
  severity: ["Mild", "Moderate", "Severe"],
  muscleTightness: [
    "None",
    "Tonic Constractions",
    "Clonic Contractions",
    "Mixed Tonic/Clonic",
  ],
  headPosturingIssues: [
    "None",
    "Sustained head tilt",
    "Abnormal posturing with limited neck range of motion",
    "Both sustained head tilt and abnormal posturing with limited neck range of motion",
  ],
  region: [
    { key: "sternocleidomastoid", label: "Sternocleidomastoid" },
    { key: "trapezius", label: "Trapezius" },
    { key: "levatorScapulae", label: "Levator scapulae" },
    { key: "scalene", label: "Scalene" },
    { key: "posteriorCervicalMuscles", label: "Posterior cervical muscles" },
  ],
  reviewOfSystems: [
    { key: "fever", label: "Fever" },
    { key: "chills", label: "Chills" },
    { key: "numbnessOrTingling", label: "Numbness/tingling" },
    { key: "bowelOrBladderDysfunction", label: "Bowel/bladder dysfunction" },
    { key: "motorWeakness", label: "Motor weakness" },
  ],
};

// user roles
export const ADMIN = "admin";
export const MEDICAL_ASSISTANT = "medical assistant";
export const MEDICAL_BILLING_PERSON = "medical billing person";

// CPT codes
export const CPT_CODES = {
  "New Patient": [
    {
      code: "99202",
      text: "This visit was classified as a straightforward visit. The time spent with the patient was 15-29 minutes.",
    },
    {
      code: "99203",
      text: "This visit was classified as a low complexity visit. The time spent with the patient was 30-44 minutes.",
    },
    {
      code: "99204",
      text: "This visit was classified as a moderate complexity visit. The time spent with the patient was 45-59 minutes.",
    },
    {
      code: "99205",
      text: "This visit was classified as a high complexity visit. The time spent with the patient was 60-74 minutes.",
    },
  ],
  "New Consult": [
    {
      code: "99241",
      text: "This visit was classified as a straightforward visit. The time spent with the patient was 15 minutes.",
    },
    {
      code: "99242",
      text: "This visit was classified as a straightforward visit. The time spent with the patient was 30 minutes.",
    },
    {
      code: "99243",
      text: "This visit was classified as a low complexity visit. The time spent with the patient was 40 minutes.",
    },
    {
      code: "99244",
      text: "This visit was classified as a moderate complexity visit. The time spent with the patient was 60 minutes.",
    },
    {
      code: "99245",
      text: "This visit was classified as a high complexity visit. The time spent with the patient was 80 minutes.",
    },
  ],
  "Established Patient": [
    {
      code: "99211",
      text: "This visit was classified as a minimal complexity visit. The time spent with the patient was 5 minutes.",
    },
    {
      code: "99212",
      text: "This visit was classified as a straightforward visit. The time spent with the patient was 10-19 minutes.",
    },
    {
      code: "99213",
      text: "This visit was classified as a low complexity visit. The time spent with the patient was 20-29 minutes.",
    },
    {
      code: "99214",
      text: "This visit was classified as a moderate complexity visit. The time spent with the patient was 30-39 minutes.",
    },
    {
      code: "99215",
      text: "This visit was classified as a high complexity visit. The time spent with the patient was 40-54 minutes.",
    },
  ],
  "Telehealth Visit": [
    {
      code: "99212",
      text: "This telehealth visit was classified as a straightforward visit. The time spent with the patient was 10-19 minutes.",
    },
    {
      code: "99213",
      text: "This telehealth visit was classified as a low complexity visit. The time spent with the patient was 20-29 minutes.",
    },
    {
      code: "99214",
      text: "This telehealth visit was classified as a moderate complexity visit. The time spent with the patient was 30-39 minutes..",
    },
    {
      code: "99215",
      text: "This telehealth visit was classified as a high complexity visit. The time spent with the patient was 40-54 minutes.",
    },
  ],
};
