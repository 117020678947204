import React, { useContext, useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import { secureApi } from "../../config";
import { Context } from "../../context";
import { useNavigate } from "react-router-dom";

const Authorization = ({ authorization, setAuthorization }) => {
  const navigate = useNavigate();
  const { authorizations, setLoggedIn } = useContext(Context);
  const [content, setContent] = useState("");
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [keyError, setKeyError] = useState(false);
  const [valueError, setValueError] = useState(false);
  const [currentAuthorizationID, setCurrentAuthorizationID] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editAuthorizationModalOpen, setEditAuthorizationModalOpen] =
    useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [deleteAuthorizationModalOpen, setDeleteAuthorizationModalOpen] =
    useState(false);

  const handleClickAuthorization = (event, index) => {
    if (authorization)
      setAuthorization(`${authorization}\n\n${authorizations[index]["value"]}`);
    else setAuthorization(`${authorizations[index]["value"]}`);
  };

  const handleEditModalOpen = () => {
    setContent(authorization);
    setEditModalOpen(true);
  };

  const handleEditModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setEditModalOpen(false);
    }
  };

  const handleUpdateAuthorizationText = () => {
    setAuthorization(content);
    setEditModalOpen(false);
  };

  const handleAddModalOpen = () => {
    setAddModalOpen(true);
  };

  const handleAddModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setKey("");
      setValue("");
      setKeyError(false);
      setValueError(false);
      setAddModalOpen(false);
    }
  };

  const handleAddAuthorization = () => {
    setKeyError(key ? false : true);
    setValueError(value ? false : true);

    if (key && value) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .post(`/authorization`, {
          key,
          value,
        })
        .then((response) => {
          setAuthorizations([
            ...authorizations,
            {
              _id: response.data["authorization_id"],
              key,
              value,
            },
          ]);
          toast.success("New authorization has been created successfully!");
          handleAddModalClose();
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
  };

  const handleEditAuthorizationModalOpen = (e, authorization) => {
    setCurrentAuthorizationID(authorization["_id"]);
    setKey(authorization["key"]);
    setValue(authorization["value"]);
    setEditAuthorizationModalOpen(true);
  };

  const handleEditAuthorizationModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setKey("");
      setValue("");
      setKeyError(false);
      setValueError(false);
      setEditAuthorizationModalOpen(false);
    }
  };

  const handleUpdateAuthorization = () => {
    if (currentAuthorizationID) {
      if (key && value) {
        secureApi(window.localStorage.getItem("alex-med-token"))
          .put(`/authorization/${currentAuthorizationID}`, {
            key,
            value,
          })
          .then((response) => {
            let newAuthorizations = [...authorizations];
            for (let i = 0; i < authorizations.length; i++) {
              if (authorizations[i]["_id"] === currentAuthorizationID) {
                authorizations[i]["key"] = key;
                authorizations[i]["value"] = value;
                break;
              }
            }
            setAuthorizations(newAuthorizations);
            toast.success("Authorization has been updated successfully!");
            handleEditAuthorizationModalClose();
          })
          .catch((e) => {
            console.log(e);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else {
              toast.error(e.response.data.message);
            }
          });
      }
    } else {
      toast.error("Current authorization is invalid!");
    }
  };

  const handleDeleteModalOpen = (e, authorization) => {
    setCurrentAuthorizationID(authorization["_id"]);
    setDeleteAuthorizationModalOpen(true);
  };

  const handleDeleteAuthorizationModalClose = () => {
    setDeleteAuthorizationModalOpen(false);
  };

  const handleKeyChange = (event) => {
    setKeyError(event.target.value ? false : true);
    setKey(event.target.value);
  };

  const handleValueChange = (event) => {
    setValueError(event.target.value ? false : true);
    setValue(event.target.value);
  };

  const handleDeleteAuthorization = () => {
    if (currentAuthorizationID) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .delete(`/authorization/${currentAuthorizationID}`)
        .then((response) => {
          let newAuthorizations = [...authorizations];
          for (let i = 0; i < authorizations.length; i++) {
            if (authorizations[i]["_id"] === currentAuthorizationID) {
              newAuthorizations.splice(i, 1);
              break;
            }
          }
          setAuthorizations(newAuthorizations);
          toast.success("Authorization has been deleted successfully!");
          handleDeleteAuthorizationModalClose();
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else {
      toast.error("Current Authorization is invalid!");
    }
  };

  return (
    <Stack direction="row">
      <Stack
        direction="column"
        style={{ width: "250px", minWidth: "250px", marginRight: "10px" }}
        spacing={0}
      >
        {authorizations.map((authorization, index) => (
          <Stack direction="row" spacing={1} key={index}>
            <Button
              key={authorization["key"]}
              variant="outlined"
              style={{ width: "80%" }}
              onClick={(e) => handleClickAuthorization(e, index)}
            >
              {authorization["key"]}
            </Button>
            {
              <IconButton
                onClick={(e) => handleDeleteModalOpen(e, authorization)}
              >
                <DeleteIcon color="error" />
              </IconButton>
            }
            <IconButton
              onClick={(e) =>
                handleEditAuthorizationModalOpen(e, authorization)
              }
            >
              <EditIcon color="secondary" />
            </IconButton>
          </Stack>
        ))}
        <Button
          variant="contained"
          style={{ marginTop: "10px" }}
          onClick={handleEditModalOpen}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          style={{ marginTop: "10px" }}
          onClick={handleAddModalOpen}
        >
          Add
        </Button>
      </Stack>
      <Stack direction="column">
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          AUTHORIZATION:
        </Typography>
        {authorization &&
          authorization.split("\n").map((item, index) => (
            <Typography variant="h6" key={index} style={{ minHeight: "15px" }}>
              {item}
            </Typography>
          ))}
      </Stack>

      <Dialog
        open={editModalOpen}
        onClose={handleEditModalClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Edit authorization</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Content"
            multiline
            minRows={10}
            fullWidth
            variant="standard"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditModalClose}>Cancel</Button>
          <Button onClick={handleUpdateAuthorizationText}>Update</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addModalOpen}
        onClose={handleAddModalClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Add authorization</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Key"
            fullWidth
            variant="standard"
            value={key}
            error={keyError}
            onChange={handleKeyChange}
          />
          <TextField
            margin="dense"
            label="Value"
            fullWidth
            multiline
            minRows={10}
            variant="standard"
            value={value}
            error={valueError}
            onChange={handleValueChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddModalClose}>Cancel</Button>
          <Button onClick={handleAddAuthorization}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editAuthorizationModalOpen}
        onClose={handleEditAuthorizationModalClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Edit authorization</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Key"
            fullWidth
            variant="standard"
            value={key}
            error={keyError}
            onChange={handleKeyChange}
          />
          <TextField
            margin="dense"
            label="Value"
            fullWidth
            minRows={10}
            multiline
            variant="standard"
            value={value}
            error={valueError}
            onChange={handleValueChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditAuthorizationModalClose}>Cancel</Button>
          <Button onClick={handleUpdateAuthorization}>Update</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteAuthorizationModalOpen}
        onClose={handleDeleteAuthorizationModalClose}
        fullWidth
      >
        <DialogTitle>Delete authorization</DialogTitle>
        <DialogContent>Are you sure to delete the authorization?</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            onClick={handleDeleteAuthorizationModalClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDeleteAuthorization}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Authorization;
