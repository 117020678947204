import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Visibility as ViewIcon,
  Delete as DeleteIcon,
  Summarize as SummarizeIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";
import {
  TableRow,
  TableCell,
  IconButton,
  Stack,
  Button,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import DateBillingTable from "./DateBillingTable";

const PatientBillingTable = ({
  patient,
  handleEditModalOpen,
  handleDeleteModalOpen,
  handleSummaryModalOpen,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleViewPatient = (patientID) => {
    window.open(`/patient/${patientID}`, "_blank");
  };

  return (
    <>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
            background: theme.palette.action.hover,
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {patient["chart"]}
        </TableCell>
        <TableCell>{`${patient["first_name"]} ${patient["last_name"]}`}</TableCell>
        <TableCell>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              disabled={!patient["billing"]}
              onClick={() =>
                handleDeleteModalOpen(patient["billing"]?._id, 0, 0, 0)
              }
            >
              Delete
            </Button>
            <Button
              variant="contained"
              color="secondary"
              endIcon={<ViewIcon />}
              onClick={() => handleViewPatient(patient["_id"])}
            >
              Patient
            </Button>
            <Button
              variant="contained"
              color="primary"
              endIcon={<SummarizeIcon />}
              onClick={() => handleSummaryModalOpen(patient)}
            >
              Summary
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Billing for a patient
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={{ background: "#fce5cd" }}>
                    <TableCell />
                    <TableCell align="right">Insurance</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Service Date</TableCell>
                    <TableCell align="right">Cost share paid</TableCell>
                    <TableCell align="right">Date billed</TableCell>
                    <TableCell align="right">Total charges</TableCell>
                    <TableCell align="right">Total receipts by mail</TableCell>
                    <TableCell align="right">Total receipts by Tebra</TableCell>
                    <TableCell align="right">Receipt Date</TableCell>
                    <TableCell align="right">Total balance</TableCell>
                    <TableCell align="right">Sent to patient?</TableCell>
                    <TableCell align="right">Collected from patient?</TableCell>
                    <TableCell align="right">Appeal needed?</TableCell>
                    <TableCell align="right">Appeal Date</TableCell>
                    <TableCell align="right">Comments</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {patient.billing &&
                    patient.billing.history.length > 0 &&
                    patient.billing.history.map((historyRow, index) => (
                      <DateBillingTable
                        billing={historyRow}
                        key={index}
                        billingID={patient.billing["_id"]}
                        dateOfServiceIdx={index}
                        handleEditModalOpen={handleEditModalOpen}
                        handleDeleteModalOpen={handleDeleteModalOpen}
                      />
                    ))}
                  {(!patient.billing ||
                    patient.billing.history.length === 0) && (
                    <TableRow>
                      <TableCell colSpan={17} sx={{ textAlign: "center" }}>
                        No Billing Information
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PatientBillingTable;
