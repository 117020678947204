import React, { useState } from "react";
import {
  FormGroup,
  Stack,
  Typography,
  TextField,
  Box,
  Button,
} from "@mui/material";

const VitalSigns = ({ vitalSigns, setVitalSigns }) => {
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [pulse, setPulse] = useState("");
  const [bloodPressure, setBloodPressure] = useState("");
  const [respiratoryRate, setRespiratoryRate] = useState("");

  const handleApply = () => {
    const heightStr = height;
    let cm = "";
    let kg = "";
    let bmi = "";

    if (heightStr.trim()) {
      const foot = parseFloat(heightStr.split("'")[0].trim());
      const inch = heightStr.split("'")[1]
        ? parseFloat(heightStr.split("'")[1].split('"')[0].trim())
        : 0;
      cm = (foot * 30.48 + inch * 2.54).toFixed(2);
    }
    if (weight.trim()) kg = (parseFloat(weight) * 0.4536).toFixed(2);

    if (cm && kg) {
      bmi = (kg / ((cm / 100) * (cm / 100))).toFixed(2) + "kg/m^2";
    }

    setVitalSigns({
      height: cm,
      weight: kg,
      BMI: bmi,
      pulse,
      bloodPressure,
      respiratoryRate,
    });
  };

  return (
    <Stack direction="row" spacing={5} sx={{ marginBottom: "20px" }}>
      <FormGroup sx={{ width: "30%" }}>
        <Box component="form">
          <TextField
            variant="outlined"
            label="Height(ft,in)"
            color="primary"
            size="medium"
            fullWidth
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
          <TextField
            variant="outlined"
            label="Weight(lb)"
            color="primary"
            size="medium"
            fullWidth
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            style={{ marginTop: "10px" }}
          />
          <TextField
            variant="outlined"
            label="Pulse"
            color="primary"
            size="medium"
            fullWidth
            value={pulse}
            onChange={(e) => setPulse(e.target.value)}
            style={{ marginTop: "10px" }}
          />
          <TextField
            variant="outlined"
            label="Blood Pressure"
            color="primary"
            size="medium"
            fullWidth
            value={bloodPressure}
            onChange={(e) => setBloodPressure(e.target.value)}
            style={{ marginTop: "10px" }}
          />
          <TextField
            variant="outlined"
            label="Respiratory Rate(rpm)"
            color="primary"
            size="medium"
            fullWidth
            value={respiratoryRate}
            onChange={(e) => setRespiratoryRate(e.target.value)}
            style={{ marginTop: "10px" }}
          />
          <Button
            variant="contained"
            style={{ marginTop: "10px" }}
            onClick={handleApply}
          >
            Apply
          </Button>
        </Box>
      </FormGroup>
      <Stack direction="column" sx={{ width: "70%" }}>
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          GENERAL PE:
        </Typography>
        <div>
          <Typography
            variant="h5"
            style={{ marginTop: "20px", textDecoration: "underline" }}
          >
            Vital Signs:
          </Typography>
          {vitalSigns.height && (
            <Typography variant="h5">Height: {vitalSigns.height}cm</Typography>
          )}
          {vitalSigns.weight && (
            <Typography variant="h5">Weight: {vitalSigns.weight}kg</Typography>
          )}
          {vitalSigns.BMI && (
            <Typography variant="h5">BMI: {vitalSigns.BMI}</Typography>
          )}
          {vitalSigns.pulse && (
            <Typography variant="h5">Pulse: {vitalSigns.pulse}</Typography>
          )}
          {vitalSigns.bloodPressure && (
            <Typography variant="h5">
              Blood Pressure: {vitalSigns.bloodPressure}
            </Typography>
          )}
          {vitalSigns.respiratoryRate && (
            <Typography variant="h5">
              Respiratory Rate: {vitalSigns.respiratoryRate}rpm
            </Typography>
          )}
        </div>
      </Stack>
    </Stack>
  );
};

export default VitalSigns;
