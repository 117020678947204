import React, { useContext, useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { ContactEmergency as ContactEmergencyIcon } from "@mui/icons-material";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timelinePlugin from "@fullcalendar/timeline";
import listPlugin from "@fullcalendar/list";
import AppointmentModal from "../components/appointments/AppointmentModal";
import ConfirmModal from "../components/appointments/ConfirmModal";
import { toast } from "react-toastify";
import TopNav from "../components/topnav";
import { secureApi, ADMIN, MEDICAL_ASSISTANT } from "../config";
import dayjs from "dayjs";
import { Context } from "../context";
import { useNavigate } from "react-router-dom";

const now = new Date();
const year = now.getFullYear();
const month = now.getMonth() + 1;
const day = now.getDate();
const hour = now.getHours();
const minute = now.getMinutes();

const Appointments = () => {
  const navigate = useNavigate();
  const { setLoggedIn, user } = useContext(Context);
  const [appointmentModalOpen, setAppointmentModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [appointment, setAppointment] = useState({});
  const [events, setEvents] = useState([]);
  const [state, setState] = useState("add");
  const [checkInfo, setCheckInfo] = useState();

  useEffect(() => {
    const events = appointments.map((item, index) => {
      const title = `${item.patient.patientInfo.first_name} ${item.patient.patientInfo.last_name}$$$Doctor:${item.doctor}`;
      const start = new Date(
        `${item.date.$y}-${item.date.$M + 1}-${item.date.$D} ${item.time.$H}:${
          item.time.$m
        }`
      );
      const end = new Date(start.getTime() + parseInt(item.duration) * 60000);

      let color = "red";
      if (item.status === "pending") color = "grey";
      else if (item.status === "verified") color = "blue";
      else if (item.status === "confirmed") color = "green";

      return {
        id: index,
        title,
        start,
        end,
        backgroundColor: color,
        borderColor: color,
        item,
      };
    });
    setEvents(events);
  }, [appointments]);

  useEffect(() => {
    secureApi(window.localStorage.getItem("alex-med-token"))
      .get(`/appointments`)
      .then((response) => {
        let { appointments } = response.data;
        appointments = appointments.map((item) => {
          return {
            ...item,
            date: dayjs(item.date),
            time: dayjs(`${item.date} ${item.time}`),
          };
        });
        setAppointments(appointments);
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          toast.error(e.response.data.message);
        }
      });
  }, []);

  const handleCancel = () => {
    setConfirmModalOpen(false);
    setAppointmentModalOpen(false);
    if (checkInfo) {
      checkInfo.revert();
      setCheckInfo(null);
    }
  };

  const handleAdd = (e, appointment) => {
    if (user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT))
      secureApi(window.localStorage.getItem("alex-med-token"))
        .post(`/appointment`, {
          patient_id: appointment.patient._id,
          doctor: appointment.doctor,
          place: appointment.place,
          type: appointment.type,
          date: appointment.date.format("MM/DD/YYYY"),
          time: appointment.time.format("hh:mm A"),
          duration: appointment.duration,
          status: appointment.status,
        })
        .then((response) => {
          const { appointment_id } = response.data;
          toast.success("New appointment has been created successfully!");
          setAppointments([
            ...appointments,
            {
              _id: appointment_id,
              patient_id: appointment.patient._id,
              patient: appointment.patient,
              doctor: appointment.doctor,
              place: appointment.place,
              type: appointment.type,
              date: appointment.date,
              time: appointment.time,
              duration: appointment.duration,
              status: appointment.status,
            },
          ]);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    else toast.error("You don't have permission to create appointments");
    setAppointmentModalOpen(false);
  };

  const handleUpdate = (e, appointment, appointment_id) => {
    if (user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT))
      secureApi(window.localStorage.getItem("alex-med-token"))
        .put(`/appointment/${appointment_id}`, {
          patient_id: appointment.patient._id,
          doctor: appointment.doctor,
          place: appointment.place,
          type: appointment.type,
          date: appointment.date.format("MM/DD/YYYY"),
          time: appointment.time.format("hh:mm A"),
          duration: appointment.duration,
          status: appointment.status,
        })
        .then(() => {
          toast.success("Appointment has been updated successfully!");
          let newAppointments = [...appointments];
          for (let i = 0; i < newAppointments.length; i++) {
            if (newAppointments[i]._id === appointment_id) {
              newAppointments[i] = {
                _id: appointment_id,
                patient_id: appointment.patient._id,
                patient: appointment.patient,
                doctor: appointment.doctor,
                place: appointment.place,
                type: appointment.type,
                date: appointment.date,
                time: appointment.time,
                duration: appointment.duration,
                status: appointment.status,
              };
              break;
            }
          }
          setAppointments(newAppointments);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    else toast.error("You don't have permission to update appointments.");
    setAppointmentModalOpen(false);
  };

  const handleDelete = (e, appointment_id) => {
    if (user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT))
      secureApi(window.localStorage.getItem("alex-med-token"))
        .delete(`/appointment/${appointment_id}`)
        .then(() => {
          let newAppointments = [...appointments].filter(
            (item) => item._id !== appointment_id
          );
          setAppointments(newAppointments);
          toast.success("Appointment has been removed successfully!");
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    else toast.error("You don't have permission to delete appointments.");
    setAppointmentModalOpen(false);
  };

  const handleEventClick = (selectInfo) => {
    setState("update");
    setAppointment(appointments[parseInt(selectInfo.event.id)]);
    setAppointmentModalOpen(true);
  };

  const handleEventDrop = (checkInfo) => {
    if (user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT)) {
      setState("drop");
      setCheckInfo(checkInfo);
      setConfirmModalOpen(true);
    } else {
      toast.warn("You don't have permission to update appointments.");
      checkInfo.revert();
    }
  };

  const handleDrop = () => {
    if (user && user.role !== ADMIN && user.role !== MEDICAL_ASSISTANT) {
      toast.warn("You don't have permission to update appointments.");
      return;
    }

    const { id, start } = checkInfo.event;
    const year = start.getFullYear();
    const month = start.getMonth() + 1;
    const date = start.getDate();
    const hours = start.getHours();
    const minutes = start.getMinutes();
    const appointment = appointments[parseInt(id)];

    const dateStr = `${("0" + month).substr(-2)}/${("0" + date).substr(
      -2
    )}/${year}`;
    const timeStr = `${("0" + hours).substr(-2)}:${("0" + minutes).substr(-2)}`;

    secureApi(window.localStorage.getItem("alex-med-token"))
      .put(`/appointment/${appointment._id}`, {
        patient_id: appointment.patient_id,
        doctor: appointment.doctor,
        place: appointment.place,
        type: appointment.type,
        duration: appointment.duration,
        status: appointment.status,
        date: dateStr,
        time: timeStr,
      })
      .then((response) => {
        let newAppointments = [...appointments];
        for (let i = 0; i < appointments.length; i++) {
          if (newAppointments[i]._id === appointment._id) {
            newAppointments[i] = {
              ...newAppointments[i],
              date: dayjs(dateStr),
              time: dayjs(`${dateStr} ${timeStr}`),
            };
            break;
          }
        }
        setAppointments(newAppointments);
        toast.success("Appointment has been updated successfully!");
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          toast.error(e.response.data.message);
        }
      });

    setCheckInfo(null);
    setConfirmModalOpen(false);
  };

  const handleEventResize = (checkInfo) => {
    if (user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT)) {
      setState("resize");
      setCheckInfo(checkInfo);
      setConfirmModalOpen(true);
    } else {
      toast.warn("You don't have permission to update appointments.");
      checkInfo.revert();
    }
  };

  const handleResize = () => {
    if (user && user.role !== ADMIN && user.role !== MEDICAL_ASSISTANT) {
      toast.warn("You don't have permission to update appointments.");
      return;
    }
    const { id, start, end } = checkInfo.event;
    const duration = (end.getTime() - start.getTime()) / 60000;

    if (
      duration !== 15 &&
      duration !== 30 &&
      duration !== 45 &&
      duration !== 60 &&
      duration !== 90
    ) {
      toast.warn(
        `Duration is ${duration}min.Duration should be 15min, 30min, 45min, 60min or 90min!`
      );
      checkInfo.revert();
    } else {
      const appointment = appointments[parseInt(id)];
      secureApi(window.localStorage.getItem("alex-med-token"))
        .put(`/appointment/${appointment._id}`, {
          patient_id: appointment.patient_id,
          doctor: appointment.doctor,
          place: appointment.place,
          type: appointment.type,
          duration: `${duration}min`,
          status: appointment.status,
          date: appointment.date.format("MM/DD/YYYY"),
          time: appointment.time.format("hh:mm A"),
        })
        .then((response) => {
          let newAppointments = [...appointments];
          for (let i = 0; i < appointments.length; i++) {
            if (newAppointments[i]._id === appointment._id) {
              newAppointments[i] = {
                ...newAppointments[i],
                duration: `${duration}min`,
              };
              break;
            }
          }
          setAppointments(newAppointments);
          toast.success("Appointment has been updated successfully!");
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
    setCheckInfo(null);
    setConfirmModalOpen(false);
  };

  return (
    <>
      <TopNav item="appointments" />
      <div style={{ margin: "30px" }}>
        <FullCalendar
          customButtons={
            user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT)
              ? {
                  addNewButton: {
                    text: "Add New",
                    click: () => {
                      setState("add");
                      setAppointment({
                        patient: null,
                        doctor: "",
                        place: "",
                        type: "Pain Management",
                        duration: "15min",
                        status: "pending",
                        date: dayjs(`${month}/${day}/${year}`),
                        time: dayjs(
                          `${month}/${day}/${year} ${hour}:${minute}`
                        ),
                      });
                      setAppointmentModalOpen(true);
                    },
                  },
                }
              : {}
          }
          schedulerLicenseKey="0654248883-fcs-1699048448"
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
            timelinePlugin,
          ]}
          headerToolbar={
            user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT)
              ? {
                  left: "prev,next today addNewButton",
                  center: "title",
                  right:
                    "timeGridWeek,dayGridMonth listDay,listWeek,listMonth,timeGridDay timeline",
                }
              : {
                  left: "prev,next today",
                  center: "title",
                  right:
                    "timeGridWeek,dayGridMonth listDay,listWeek,listMonth,timeGridDay timeline",
                }
          }
          views={{
            listDay: {
              buttonText: "list day", // Displayed label for list day view
            },
            listWeek: {
              buttonText: "list week", // Displayed label for list week view
            },
            listMonth: {
              buttonText: "list month", // Displayed label for list month view
            },
          }}
          initialView="timeGridDay"
          slotDuration="00:15:00" // Set the slot duration to 15 minutes
          slotLabelInterval="00:15:00" // Display time labels every 15 minutes
          weekends={true}
          events={events}
          editable={true}
          selectable={true}
          dayMaxEvents={true}
          eventClick={handleEventClick}
          eventContent={renderEventContent}
          eventDrop={handleEventDrop}
          eventResize={handleEventResize}
        />

        <AppointmentModal
          openModal={appointmentModalOpen}
          handleCancel={handleCancel}
          handleDelete={handleDelete}
          handleAdd={handleAdd}
          handleUpdate={handleUpdate}
          state={state}
          appointment={appointment}
          setAppointment={setAppointment}
        />
        <ConfirmModal
          openModal={confirmModalOpen}
          state={state}
          handleCancel={handleCancel}
          handleDrop={handleDrop}
          handleResize={handleResize}
        />
      </div>
    </>
  );
};

// a custom render function
function renderEventContent(eventInfo) {
  const patient = eventInfo.event.title.split("$$$")[0];
  const doctor = eventInfo.event.title.split("$$$")[1];
  return (
    <div>
      <b>{eventInfo.timeText}</b>
      <Stack direction="row">
        <ContactEmergencyIcon
          style={{
            height: "20px",
            justifyContent: "center",
            marginRight: "5px",
          }}
        />
        {patient}
      </Stack>
      <div>{doctor}</div>
    </div>
  );
}

export default Appointments;
