import React, { useState, useMemo, useEffect, useContext } from "react";
import {
  Stack,
  TextField,
  Autocomplete,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { Diamond as CodeIcon, Close as CloseIcon } from "@mui/icons-material";
import { debounce } from "@mui/material/utils";
import { toast } from "react-toastify";
import { secureApi } from "../config";
import { Context } from "../context";
import { useNavigate } from "react-router-dom";

export const ICD10Codes = ({ db_icd10codes, icd10codes, setIcd10codes }) => {
  const { setLoggedIn } = useContext(Context);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        secureApi(window.localStorage.getItem("alex-med-token"))
          .post(`/icd10codes`, { filter: request.filter })
          .then((response) => {
            callback(response.data.results);
          })
          .catch((e) => {
            console.log(e);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else {
              toast.error(e.response.data.message);
            }
          });
      }, 400),
    []
  );

  useEffect(() => {
    if (db_icd10codes) setIcd10codes(db_icd10codes);
  }, [db_icd10codes]);

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ filter: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const removeICD10Code = (index) => {
    let newIcd10codes = [...icd10codes];
    newIcd10codes.splice(index, 1);
    setIcd10codes(newIcd10codes);
  };

  return (
    <Stack direction="row" spacing={5}>
      <Autocomplete
        sx={{ width: "30%" }}
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            : `${option.code} - ${option.shortDescription}`
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="No codes"
        onChange={(event, newValue) => {
          if (newValue) {
            const codeLabel = `${newValue["code"].trim()} - ${newValue[
              "shortDescription"
            ].trim()}`;
            if (icd10codes.indexOf(codeLabel) === -1)
              setIcd10codes([...icd10codes, codeLabel]);
            setValue(`${newValue["code"]} - ${newValue["shortDescription"]}`);
          }
          setOptions(newValue ? [newValue, ...options] : options);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Add an ICD-10 code" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <CodeIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {`${option["code"]} - ${option["shortDescription"]}`}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <Stack direction="column" sx={{ width: "70%" }}>
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Selected ICD-10 Codes:
        </Typography>
        {icd10codes && icd10codes.length ? (
          icd10codes.map((icd10code, idx) => (
            <Typography variant="h6" key={idx}>
              {icd10code}
              <IconButton
                variant="outlined"
                onClick={() => removeICD10Code(idx)}
              >
                <CloseIcon />
              </IconButton>
            </Typography>
          ))
        ) : (
          <Typography variant="h6">None</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default ICD10Codes;
