import React, { useState, useMemo, useEffect, useContext } from "react";
import {
  Stack,
  TextField,
  Autocomplete,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import {
  Medication as MedicationIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { debounce } from "@mui/material/utils";
import { toast } from "react-toastify";
import { secureApi } from "../../config";
import { Context } from "../../context";
import { useNavigate } from "react-router-dom";

export const Medications = ({ db_medications }) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const { medications, setMedications, setLoggedIn } = useContext(Context);
  const navigate = useNavigate();

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        secureApi(window.localStorage.getItem("alex-med-token"))
          .post(`/medications`, { filter: request.filter })
          .then((response) => {
            callback(response.data.results);
          })
          .catch((e) => {
            console.log(e);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else {
              toast.error(e.response.data.message);
            }
          });
      }, 400),
    []
  );

  useEffect(() => {
    if (db_medications) setMedications(db_medications);
  }, [db_medications]);

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ filter: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const removeMedication = (index) => {
    let newMedications = [...medications];
    newMedications.splice(index, 1);
    setMedications(newMedications);
  };

  return (
    <Stack direction="row" spacing={5}>
      <Autocomplete
        sx={{ width: "30%" }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        noOptionsText="No medications"
        onChange={(event, newValue) => {
          if (newValue) setMedications([...medications, newValue.trim()]);
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Add a medication" fullWidth />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <MedicationIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {option}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <Stack direction="column" sx={{ width: "70%" }}>
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Medications:
        </Typography>
        {medications &&
          medications.map((medication, idx) => (
            <Typography variant="h6" key={idx}>
              {medication}
              <IconButton
                variant="outlined"
                onClick={() => removeMedication(idx)}
              >
                <CloseIcon />
              </IconButton>
            </Typography>
          ))}
      </Stack>
    </Stack>
  );
};

export default Medications;
