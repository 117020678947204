import React, { useContext } from "react";
import { Paper, Typography, Stack, Input } from "@mui/material";
import { Context } from "../../context";
import { MEDICAL_BILLING_PERSON } from "../../config";

const PatientInfoEditInput = ({
  name,
  value,
  updatePatientInfo,
  painConsultNoteSigned,
}) => {
  const { user } = useContext(Context);
  return (
    <Input
      variant="filled"
      color="primary"
      size="medium"
      fullWidth
      value={value}
      name={name}
      onChange={(e) => updatePatientInfo(e)}
      readOnly={user && user.role === MEDICAL_BILLING_PERSON}
    />
  );
};

const Employment = ({ employment, setEmployment, painConsultNoteSigned }) => {
  const udpateEmployment = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setEmployment((values) => ({ ...values, [name]: value }));
  };

  return (
    <Paper style={{ padding: "20px", boxSizing: "border-box", width: "50%" }}>
      <Typography variant="h6" fontWeight="bold" marginBottom="10px">
        Employment:
      </Typography>
      <Stack spacing={1}>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Employer
          </Typography>
          <PatientInfoEditInput
            name="employer"
            value={employment.employer}
            updatePatientInfo={udpateEmployment}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Business Phone
          </Typography>
          <PatientInfoEditInput
            name="business_phone"
            value={employment.business_phone}
            updatePatientInfo={udpateEmployment}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Business Email
          </Typography>
          <PatientInfoEditInput
            name="business_email"
            value={employment.business_email}
            updatePatientInfo={udpateEmployment}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Work Address
          </Typography>
          <PatientInfoEditInput
            name="work_address"
            value={employment.work_address}
            updatePatientInfo={udpateEmployment}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default Employment;
