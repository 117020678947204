import React, { useContext, useEffect, useState } from "react";
import {
  FormGroup,
  Button,
  IconButton,
  Stack,
  Typography,
  TextField,
  Switch,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Context } from "../../context";

const Allergies = ({ db_allergies }) => {
  const { allergies, setAllergies } = useContext(Context);
  const [allergiesStatus, setAllergiesStatus] = useState(allergies.length > 0);
  const [medication, setMedication] = useState("");
  const [reaction, setReaction] = useState("");
  const [medicationError, setMedicationError] = useState(false);
  const [reactionError, setReactionError] = useState(false);

  useEffect(() => {
    if (db_allergies) {
      setAllergies(db_allergies);
      setAllergiesStatus(db_allergies.length > 0);
    }
  }, [db_allergies]);

  const handleChange = (e) => {
    setAllergiesStatus(e.target.checked);
  };

  const handleMedicationChange = (event) => {
    const { value: medication } = event.target;
    setMedication(medication);

    if (medication) setMedicationError(false);
    else setMedicationError(true);
  };

  const handleReactionChange = (event) => {
    const { value: reaction } = event.target;
    setReaction(reaction);

    if (reaction) setReactionError(false);
    else setReactionError(true);
  };

  const handleApply = () => {
    if (!medication) setMedicationError(true);

    if (!reaction) setReactionError(true);

    if (medication && reaction) {
      setAllergies([...allergies, `${medication} - ${reaction}`]);
      setMedication("");
      setReaction("");
    }
  };

  const removeAllergy = (index) => {
    let newAllergies = [...allergies];
    newAllergies.splice(index, 1);
    setAllergies(newAllergies);
  };

  return (
    <Stack direction="row" spacing={5}>
      <FormGroup sx={{ width: "30%" }}>
        <Typography variant="h6">Has allergies?</Typography>
        <Switch
          checked={allergiesStatus}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        {allergiesStatus && (
          <Box component="form">
            <TextField
              variant="outlined"
              label="Medication"
              color="primary"
              size="medium"
              fullWidth
              value={medication}
              onChange={handleMedicationChange}
              error={medicationError}
            />
            <TextField
              variant="outlined"
              label="Reaction"
              color="primary"
              size="medium"
              fullWidth
              value={reaction}
              onChange={handleReactionChange}
              error={reactionError}
              style={{ marginTop: "10px" }}
            />
            <Button
              variant="contained"
              style={{ marginTop: "10px" }}
              onClick={handleApply}
            >
              Apply
            </Button>
          </Box>
        )}
      </FormGroup>
      <Stack direction="column" sx={{ width: "70%" }}>
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Allergies:
        </Typography>
        {allergiesStatus ? (
          allergies.map((allergy, index) => (
            <Typography variant="h6" key={index}>
              {allergy}
              <IconButton
                variant="outlined"
                onClick={() => removeAllergy(index)}
              >
                <CloseIcon />
              </IconButton>
            </Typography>
          ))
        ) : (
          <Typography variant="h6">NKDA</Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default Allergies;
