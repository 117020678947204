import React from "react";
import {
  Typography,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const useStyles = makeStyles(() => ({
  bolded: {
    fontWeight: "bold",
  },
}));

const PatientInfo = ({
  patientName,
  dateOfBirth,
  dateOfProcedureNote,
  setDateOfProcedureNote,
  surgeon,
  setSurgeon,
  assistant,
  setAssistant,
  anesthesia,
  setAnesthesia,
  anesthesiologist,
  setAnesthesiologist,
  neuroMonitoring,
  setNeuroMonitoring,
  typeOfFacility,
  setTypeOfFacility,
  nameOfFacility,
  setNameOfFacility,
  addressOfFacility,
  setAddressOfFacility,
  cityStateZip,
  setCityStateZip,
}) => {
  const classes = useStyles();

  const handleDateChange = (e) => {
    setDateOfProcedureNote(e);
  };

  return (
    <Stack direction="row" style={{ marginTop: "30px" }}>
      <Stack
        direction="column"
        style={{ width: "250px", marginRight: "10px" }}
        spacing={1}
      >
        <Stack direction="column" width="250px" marginTop="20px">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={handleDateChange}
              value={dateOfProcedureNote}
              label="Date of Procedure Note"
            />
          </LocalizationProvider>
        </Stack>
        <TextField
          value={surgeon}
          onChange={(e) => setSurgeon(e.target.value)}
          label="Surgeon"
          multiline
        />
        <TextField
          value={assistant}
          onChange={(e) => setAssistant(e.target.value)}
          label="Assistant"
        />
        <FormControl>
          <InputLabel>Anestheisa</InputLabel>
          <Select
            value={anesthesia}
            label="Anestheisa"
            onChange={(e) => setAnesthesia(e.target.value)}
          >
            <MenuItem value="local">local</MenuItem>
            <MenuItem value="MAC">MAC</MenuItem>
            <MenuItem value="general">general</MenuItem>
          </Select>
        </FormControl>
        <TextField
          value={anesthesiologist}
          onChange={(e) => setAnesthesiologist(e.target.value)}
          label="Anesthesiologist"
        />
        <TextField
          value={neuroMonitoring}
          onChange={(e) => setNeuroMonitoring(e.target.value)}
          label="Neuro-monitoring"
        />
        <FormControl>
          <InputLabel>Type of Facility</InputLabel>
          <Select
            value={typeOfFacility}
            label="Type of Facility"
            onChange={(e) => setTypeOfFacility(e.target.value)}
          >
            <MenuItem value="Medical Office">Medical Office</MenuItem>
            <MenuItem value="Ambulatory Surgical Center">
              Ambulatory Surgical Center
            </MenuItem>
            <MenuItem value="Hospital">Hospital</MenuItem>
          </Select>
        </FormControl>
        <TextField
          value={nameOfFacility}
          onChange={(e) => setNameOfFacility(e.target.value)}
          label="Name of Facility"
        />
        <TextField
          value={addressOfFacility}
          onChange={(e) => setAddressOfFacility(e.target.value)}
          label="Address of Facility"
        />
        <TextField
          value={cityStateZip}
          onChange={(e) => setCityStateZip(e.target.value)}
          label="City, State, Zip Code"
        />
      </Stack>

      <Stack direction="column">
        <Typography variant="h6">
          <span className={classes.bolded}>Patient Name: </span>
          {patientName}
        </Typography>
        <Typography variant="h6">
          <span className={classes.bolded}>DOB: </span>
          {dateOfBirth}
        </Typography>
        <Typography variant="h6">
          <span className={classes.bolded}>Date of Procedure: </span>
          {dateOfProcedureNote.format("MM/DD/YYYY")}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography variant="h6" fontWeight="bold">
            Surgeon:{" "}
          </Typography>
          <Stack direction="column">
            {surgeon.split("\n").map((item, index) => (
              <Typography variant="h6" key={index}>
                {item}
              </Typography>
            ))}
          </Stack>
        </Stack>
        <Typography variant="h6">
          <span className={classes.bolded}>Assistant: </span>
          {assistant}
        </Typography>
        <Typography variant="h6">
          <span className={classes.bolded}>Anesthesia: </span>
          {anesthesia}
        </Typography>
        <Typography variant="h6">
          <span className={classes.bolded}>Anesthesiologist: </span>
          {anesthesiologist}
        </Typography>
        <Typography variant="h6">
          <span className={classes.bolded}>Neuro-monitoring: </span>
          {neuroMonitoring}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography variant="h6" fontWeight="bold">
            Location:{" "}
          </Typography>
          <Stack direction="column">
            <Typography variant="h6">{typeOfFacility}</Typography>
            <Typography variant="h6">{nameOfFacility}</Typography>
            <Typography variant="h6">
              {addressOfFacility} {cityStateZip}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PatientInfo;
