import React, { useState, useEffect, useMemo } from "react";

import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Stack,
  Typography,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import { debounce } from "@mui/material/utils";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import { secureApi } from "../../config";

// Create a styled TableCell component with vertical border
const StyledTableCell = styled(TableCell)(() => ({
  borderRight: `1px solid #e0e0e0`,
}));

const SummaryModal = ({ open, patient, handleClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [dateOfServices, setDateOfServices] = useState([]);
  const [dateOfService, setDateOfService] = useState("None");
  const [notes, setNotes] = useState([]);

  const handleGotoProfile = () => {
    window.open(`/patient/${patient["_id"]}`, "_blank");
  };

  const handleChangeDateOfService = (e) => {
    setDateOfService(e.target.value);
  };

  const handleSummaryChange = (event, field, index, noteID) => {
    const { value } = event.target;
    let newNotes = [...notes];
    newNotes[index]["summary"][field] = value;
    setNotes(newNotes);
    update({ noteID, field, value }, () => {});
  };

  const update = useMemo(
    () =>
      debounce((request, callback) => {
        secureApi(window.localStorage.getItem("alex-med-token"))
          .put(`/note-summary/${request.noteID}`, {
            field: request.field,
            value: request.value,
          })
          .then(() => {
            callback();
          })
          .catch((e) => {
            console.log(e);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else {
              toast.error(e.response.data.message);
            }
          });
      }, 400),
    []
  );

  useEffect(() => {
    if (patient["notes"]) {
      let dateOfServices = [];

      patient["notes"].map((note) => {
        const dateOfService = note["dateOfService"] || note["dateOfProcedure"];

        if (!note["summary"]) {
          note["summary"] = {
            auth: "",
            validity: "",
            comments: "",
          };
        } else {
          if (!note.summary.auth) note["summary"]["auth"] = "";
          if (!note.summary.validity) note["summary"]["validity"] = "";
          if (!note.summary.comments) note["summary"]["comments"] = "";
        }

        if (dateOfServices.indexOf(dateOfService) === -1) {
          dateOfServices.push(dateOfService);
        }
      });
      if (dateOfServices.length) setDateOfService(dateOfServices[0]);
      else setDateOfService("None");
      setDateOfServices(dateOfServices.sort());
    }
  }, [patient]);

  useEffect(() => {
    if (
      patient["notes"] &&
      patient["notes"].length &&
      dateOfService !== "None"
    ) {
      const notes = patient["notes"]
        .filter(
          (note) =>
            note["dateOfService"] === dateOfService ||
            note["dateOfProcedure"] === dateOfService
        )
        .map((note) => {
          if (note["CPTcode"]) return { ...note, cptCodes: [note["CPTcode"]] };
          return note;
        });
      setNotes(notes);
    } else {
      setNotes([]);
    }
  }, [patient, dateOfService]);

  return (
    <Dialog fullScreen={fullScreen} open={open} fullWidth maxWidth="lg">
      <DialogTitle>Visit Summary</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack direction="column">
          <Stack direction="row">
            <Typography variant="h6" fontWeight="bold">
              Patient:&nbsp;
            </Typography>
            <Typography variant="h6">{`${patient["first_name"]} ${patient["last_name"]}`}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="h6" fontWeight="bold">
              Primary Insurance:&nbsp;
            </Typography>
            <Typography variant="h6">{`${patient["insurance"]}`}</Typography>
          </Stack>
          <Stack direction="row">
            <Typography variant="h6" fontWeight="bold">
              Service Date:&nbsp;
            </Typography>
            <Select
              variant="standard"
              color="primary"
              size="medium"
              value={dateOfService}
              onChange={handleChangeDateOfService}
            >
              {dateOfServices.map((dateOfService, index) => (
                <MenuItem value={dateOfService} key={index}>
                  {dateOfService}
                </MenuItem>
              ))}
              {dateOfServices.length === 0 && (
                <MenuItem value={dateOfService}>None</MenuItem>
              )}
            </Select>
          </Stack>
          <Button
            sx={{
              textTransform: "none",
              width: "200px",
              marginTop: "10px",
            }}
            variant="contained"
            onClick={handleGotoProfile}
          >
            Go to Patient Profile &gt;
          </Button>
          <TableContainer
            component={Paper}
            sx={{ marginTop: "30px", maxHeight: "500px" }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{ background: "#055075" }}>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "#fff",
                    }}
                  >
                    CPT Code
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ color: "#fff" }}>
                    Diagnosis(ICD code)
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ color: "#fff" }}>
                    Auth #
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ color: "#fff" }}>
                    Auth Validity
                  </StyledTableCell>
                  <TableCell align="center" sx={{ color: "#fff" }}>
                    Comments
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notes.map(
                  (note, index) =>
                    ((note["cptCodes"] && note["cptCodes"].length > 0) ||
                      (note["icd10codes"] &&
                        note["icd10codes"].length > 0)) && (
                      <TableRow key={index}>
                        <StyledTableCell component="th" scope="row">
                          {note["cptCodes"] &&
                            note["cptCodes"].length > 0 &&
                            note["cptCodes"]
                              .map((item) => item.split(" - ")[0])
                              .join(", ")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {note["icd10codes"] &&
                            note["icd10codes"].length > 0 &&
                            note["icd10codes"]
                              .map((item) => item.split(" - ")[0])
                              .join(", ")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TextField
                            fullWidth
                            size="small"
                            value={note["summary"]["auth"]}
                            onChange={(e) =>
                              handleSummaryChange(e, "auth", index, note["_id"])
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <TextField
                            fullWidth
                            size="small"
                            value={note["summary"]["validity"]}
                            onChange={(e) =>
                              handleSummaryChange(
                                e,
                                "validity",
                                index,
                                note["_id"]
                              )
                            }
                          />
                        </StyledTableCell>
                        <TableCell align="center">
                          <TextField
                            fullWidth
                            size="small"
                            value={note["summary"]["comments"]}
                            onChange={(e) =>
                              handleSummaryChange(
                                e,
                                "comments",
                                index,
                                note["_id"]
                              )
                            }
                          />
                        </TableCell>
                      </TableRow>
                    )
                )}
                {(notes.length === 0 ||
                  notes.every(
                    (note) =>
                      (!note["cptCodes"] || note["cptCodes"].length === 0) &&
                      (!note["icd10codes"] || note["icd10codes"].length === 0)
                  )) && (
                  <TableRow>
                    <TableCell colSpan={5} sx={{ textAlign: "center" }}>
                      No records
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SummaryModal;
