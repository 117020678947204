import React, { useState, useContext } from 'react';
import {
  Stack,
  Paper,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../config';
import { Context } from '../context';

const Login = () => {
  const { setLoggedIn, setUser } = useContext(Context);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleChange = (e) => {
    if (e.target.name === "email") {
      if (e.target.value)
        setEmailError(false);
      else
        setEmailError(true);
    } else {
      if (e.target.value)
        setPasswordError(false);
      else
        setPasswordError(true);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.email) {
      setEmailError(true);
    }
    if (!formData.password) {
      setPasswordError(true);
    }
    if (formData.email && formData.password) {
      api.post(`/auth/login`, {
        ...formData
      }).then(response => {
        const { token, decoded_token } = response.data;
        toast.success("Logged in successfully!");
        window.localStorage.setItem('alex-med-token', token);
        setUser(decoded_token);
        setLoggedIn(true);
      }).catch(e => {
        toast.error(e.response.data.message);
      });
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper style={{ minWidth: '400px', maxWidth: '400px', padding: '50px' }}>
        <form onSubmit={handleSubmit}>
          <Stack direction='column' spacing={2}>
            <Typography variant='h4' style={{ textAlign: 'center' }}>Log In</Typography>
            <TextField label="Email" type="email" name="email" value={formData.email} autoFocus onChange={handleChange} error={emailError}></TextField>
            <TextField label="Password" type='password' name="password" value={formData.password} onChange={handleChange} error={passwordError}></TextField>
            <Button type="submit" variant='contained' color='primary'>Log in</Button>
            {/* <a href='forgot-password' style={{ textAlign: "center" }}>Forgot password?</a> */}
            <Link to="/forgot-password" style={{ textAlign: "center" }}> Forgot password?</Link>
          </Stack>
        </form>
      </Paper>
    </div >
  )
}

export default Login;