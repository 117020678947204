import React from "react";
import {
  Stack,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const KneePhysicalExam = ({ kneePainInfo, setKneePainInfo }) => {
  return (
    <Stack spacing={3}>
      {/* Laterality Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }}>
          <Typography variant="h6">Laterality</Typography>
          <RadioGroup
            value={kneePainInfo.laterality}
            onChange={(e) =>
              setKneePainInfo({ ...kneePainInfo, laterality: e.target.value })
            }
          >
            <FormControlLabel
              value="Right knee"
              control={<Radio />}
              label="Right"
            />
            <FormControlLabel
              value="Left knee"
              control={<Radio />}
              label="Left"
            />
            <FormControlLabel
              value="Bilateral knee"
              control={<Radio />}
              label="Bilateral"
            />
          </RadioGroup>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Laterality:
          </Typography>
          <Typography variant="h6">{kneePainInfo.laterality}</Typography>
        </Stack>
      </Stack>

      {/* Inspection Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Appearance</Typography>
            <RadioGroup
              value={kneePainInfo.appearance}
              onChange={(e) =>
                setKneePainInfo({ ...kneePainInfo, appearance: e.target.value })
              }
            >
              <FormControlLabel
                value="No visible abnormality"
                control={<Radio />}
                label="No visible abnormality"
              />
              <FormControlLabel
                value="Swelling"
                control={<Radio />}
                label="Swelling"
              />
              <FormControlLabel
                value="Deformity"
                control={<Radio />}
                label="Deformity"
              />
              <FormControlLabel
                value="Redness"
                control={<Radio />}
                label="Redness"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Skin</Typography>
            <RadioGroup
              value={kneePainInfo.skin}
              onChange={(e) =>
                setKneePainInfo({ ...kneePainInfo, skin: e.target.value })
              }
            >
              <FormControlLabel
                value="Normal"
                control={<Radio />}
                label="Normal"
              />
              <FormControlLabel value="Rash" control={<Radio />} label="Rash" />
              <FormControlLabel
                value="Bruising"
                control={<Radio />}
                label="Bruising"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Knee Inspection:
          </Typography>
          <Typography variant="h6">
            Appearance: {kneePainInfo.appearance}
          </Typography>
          <Typography variant="h6">Skin: {kneePainInfo.skin}</Typography>
        </Stack>
      </Stack>

      {/* Palpation section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Bony Landmarks</Typography>
            <RadioGroup
              value={kneePainInfo.bonyLandmarks}
              onChange={(e) =>
                setKneePainInfo({
                  ...kneePainInfo,
                  bonyLandmarks: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No tenderness"
                control={<Radio />}
                label="No tenderness"
              />
              <FormControlLabel
                value="Tenderness at patella"
                control={<Radio />}
                label="Tenderness at patella"
              />
              <FormControlLabel
                value="Tenderness at tibial tuberosity"
                control={<Radio />}
                label="Tenderness at tibial tuberosity"
              />
              <FormControlLabel
                value="Tenderness at joint line"
                control={<Radio />}
                label="Tenderness at joint line"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Soft Tissue</Typography>
            <RadioGroup
              value={kneePainInfo.softTissue}
              onChange={(e) =>
                setKneePainInfo({ ...kneePainInfo, softTissue: e.target.value })
              }
            >
              <FormControlLabel
                value="No tenderness"
                control={<Radio />}
                label="No tenderness"
              />
              <FormControlLabel
                value="Tenderness over quadriceps tendon"
                control={<Radio />}
                label="Tenderness over quadriceps tendon"
              />
              <FormControlLabel
                value="Tenderness over patellar tendon"
                control={<Radio />}
                label="Tenderness over patellar tendon"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Knee Palpation:
          </Typography>
          <Typography variant="h6">
            Bony landmarks: {kneePainInfo.bonyLandmarks}
          </Typography>
          <Typography variant="h6">
            Soft tissue: {kneePainInfo.softTissue}
          </Typography>
        </Stack>
      </Stack>

      {/* Range of Motion Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Active</Typography>
            <RadioGroup
              value={kneePainInfo.active}
              onChange={(e) =>
                setKneePainInfo({ ...kneePainInfo, active: e.target.value })
              }
            >
              <FormControlLabel
                value="Full range without pain"
                control={<Radio />}
                label="Full range without pain"
              />
              <FormControlLabel
                value="Pain with flexion"
                control={<Radio />}
                label="Pain with flexion"
              />
              <FormControlLabel
                value="Pain with extension"
                control={<Radio />}
                label="Pain with extension"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Passive</Typography>
            <RadioGroup
              value={kneePainInfo.passive}
              onChange={(e) =>
                setKneePainInfo({ ...kneePainInfo, passive: e.target.value })
              }
            >
              <FormControlLabel
                value="Full range without pain"
                control={<Radio />}
                label="Full range without pain"
              />
              <FormControlLabel
                value="Limited by pain"
                control={<Radio />}
                label="Limited by pain"
              />
              <FormControlLabel
                value="Crepitus"
                control={<Radio />}
                label="Crepitus"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Range of Motion:
          </Typography>
          <Typography variant="h6">Active: {kneePainInfo.active}</Typography>
          <Typography variant="h6">Passive: {kneePainInfo.passive}</Typography>
        </Stack>
      </Stack>

      {/* Strength Testing Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Quadriceps Muscle</Typography>
            <RadioGroup
              value={kneePainInfo.quadricepsMuscle}
              onChange={(e) =>
                setKneePainInfo({
                  ...kneePainInfo,
                  quadricepsMuscle: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal strength"
                control={<Radio />}
                label="Normal strength"
              />
              <FormControlLabel
                value="Weakness"
                control={<Radio />}
                label="Weakness"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Hamstrings Muscle</Typography>
            <RadioGroup
              value={kneePainInfo.hamstringsMuscle}
              onChange={(e) =>
                setKneePainInfo({
                  ...kneePainInfo,
                  hamstringsMuscle: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal strength"
                control={<Radio />}
                label="Normal strength"
              />
              <FormControlLabel
                value="Weakness"
                control={<Radio />}
                label="Weakness"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Strength Testing:
          </Typography>
          <Typography variant="h6">
            Quadriceps Muscle: {kneePainInfo.quadricepsMuscle}
          </Typography>
          <Typography variant="h6">
            Hamstrings Muscle: {kneePainInfo.hamstringsMuscle}
          </Typography>
        </Stack>
      </Stack>

      {/* Special Tests Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Meniscal Injury</Typography>
            <RadioGroup
              value={kneePainInfo.meniscalInjury}
              onChange={(e) =>
                setKneePainInfo({
                  ...kneePainInfo,
                  meniscalInjury: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Positive McMurray's test"
                control={<Radio />}
                label="Positive McMurray's test"
              />
              <FormControlLabel
                value="Positive Apley's test"
                control={<Radio />}
                label="Positive Apley's test"
              />
              <FormControlLabel
                value="Negative"
                control={<Radio />}
                label="Negative"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Ligamentous Injury</Typography>
            <RadioGroup
              value={kneePainInfo.ligamentousInjury}
              onChange={(e) =>
                setKneePainInfo({
                  ...kneePainInfo,
                  ligamentousInjury: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Positive Lachman test"
                control={<Radio />}
                label="Positive Lachman test"
              />
              <FormControlLabel
                value="Positive anterior drawer test"
                control={<Radio />}
                label="Positive anterior drawer test"
              />
              <FormControlLabel
                value="Positive posterior drawer test"
                control={<Radio />}
                label="Positive posterior drawer test"
              />
              <FormControlLabel
                value="Negative"
                control={<Radio />}
                label="Negative"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Special Tests:
          </Typography>
          <Typography variant="h6">
            Meniscal Injury: {kneePainInfo.meniscalInjury}
          </Typography>
          <Typography variant="h6">
            Ligamentous Injury: {kneePainInfo.ligamentousInjury}
          </Typography>
        </Stack>
      </Stack>

      {/* Neurovascular Assessment */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Sensation</Typography>
            <RadioGroup
              value={kneePainInfo.sensation}
              onChange={(e) =>
                setKneePainInfo({ ...kneePainInfo, sensation: e.target.value })
              }
            >
              <FormControlLabel
                value="Intact"
                control={<Radio />}
                label="Intact"
              />
              <FormControlLabel
                value="Decreased in peroneal nerve distribution"
                control={<Radio />}
                label="Decreased in peroneal nerve distribution"
              />
              <FormControlLabel
                value="Decreased in tibial nerve distribution"
                control={<Radio />}
                label="Decreased in tibial nerve distribution"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Pulses</Typography>
            <RadioGroup
              value={kneePainInfo.pulses}
              onChange={(e) =>
                setKneePainInfo({ ...kneePainInfo, pulses: e.target.value })
              }
            >
              <FormControlLabel
                value="Intact popliteal pulse"
                control={<Radio />}
                label="Intact popliteal pulse"
              />
              <FormControlLabel
                value="Intact dorsalis pedis pulse"
                control={<Radio />}
                label="Intact dorsalis pedis pulse"
              />
              <FormControlLabel
                value="Intact posterior tibial pulse"
                control={<Radio />}
                label="Intact posterior tibial pulse"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Neurovascular Assessment:
          </Typography>
          <Typography variant="h6">
            Sensation: {kneePainInfo.sensation}
          </Typography>
          <Typography variant="h6">Pulses: {kneePainInfo.pulses}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default KneePhysicalExam;
