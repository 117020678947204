import React, { useContext, useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Button,
  Stack,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import { Context } from "../context";
import {
  ADMIN,
  PAIN_CONSULT_NOTE_TYPE,
  TELEHEALTH_CONSULT_NOTE_TYPE,
  secureApi,
} from "../config";

import Demographics from "../components/pain_consult_note_chronic/demographics";
import HPI from "../components/pain_consult_note_chronic/hpi";
import PMH from "../components/pain_consult_note_chronic/pmh";
import PSH from "../components/pain_consult_note_chronic/psh";
import Allergies from "../components/pain_consult_note_chronic/allergies";
import Medications from "../components/pain_consult_note_chronic/medications";
import VitalSigns from "../components/pain_consult_note_chronic/vitalSigns";
import HeadPhysicalExam from "../components/pain_consult_note_chronic/physicalExam/headPhysicalExam";
import NeckPhysicalExam from "../components/pain_consult_note_chronic/physicalExam/neckPhysicalExam";
import BackPhysicalExam from "../components/pain_consult_note_chronic/physicalExam/backPhysicalExam";
import ShoulderPhysicalExam from "../components/pain_consult_note_chronic/physicalExam/shoulderPhysicalExam";
import ElbowPhysicalExam from "../components/pain_consult_note_chronic/physicalExam/elbowPhysicalExam";
import WristhysicalExam from "../components/pain_consult_note_chronic/physicalExam/wristPhysicalExam";
import KneePhysicalExam from "../components/pain_consult_note_chronic/physicalExam/kneePhysicalExam";
import ImagesReviewed from "../components/pain_consult_note_chronic/imagesReviewed";
import Assessment from "../components/pain_consult_note_chronic/assessment";
import Plan from "../components/pain_consult_note_chronic/plan";
import Authorization from "../components/pain_consult_note_chronic/authorization";
import CervicalPhysicalExam from "../components/pain_consult_note_chronic/physicalExam/cervicalPhysicalExamp";
import ICD10Codes from "../components/icd10codes";
import CPTcodes from "../components/CPTcodes";
// import SessionExpirationDialog from "../components/SessionExpirationDialog";
// import useSessionManagement from "../hooks/useSessionManagement";

const InitialConsultNote = ({
  patient_id,
  patientName,
  gender,
  dateOfBirth,
  dateOfFirstVisit,
  dateOfService,
  timeOfService,
  setDateOfService,
  setTimeOfService,
  note,
  provider,
  setProvider,
  imagesReviewedInfo,
  setImagesReviewedInfo,
  is_telehealth_consult_note,
}) => {
  const {
    pastMedicalHistory,
    pastSurgicalHistory,
    medications,
    allergies,
    imagesReviewed,
    assessment,
    planText,
    authorizationText,
    muscleStrain,
    setLoggedIn,
    user,
  } = useContext(Context);

  const navigate = useNavigate();

  // const { sessionDialogOpen, handleSessionContinue, handleSessionLogout } =
  //   useSessionManagement();

  const [menuItems, setMenuItems] = useState([
    { text: "Demographics", id: "demographics" },
    { text: "HPI", id: "hpi" },
    { text: "Past Medical History (PMH)", id: "pmh" },
    { text: "Past Surgical History (PSH)", id: "psh" },
    { text: "Medications", id: "medications" },
    { text: "Allergies", id: "allergies" },
    { text: "Physical Exam", id: "physical_exam" },
    { text: "Images Reviewed", id: "images_reviewed" },
  ]);

  const [pains, setPains] = useState([]);
  const [selectedPainIndex, setSelectedPainIndex] = useState(0); // To store selected pain index
  const [selectedPainKey, setSelectedPainKey] = useState();
  const [icd10codes, setIcd10codes] = useState([]);
  const [cptCodes, setCptCodes] = useState([]);

  const [vitalSigns, setVitalSigns] = useState({
    height: "",
    weight: "",
    pulse: "",
    BMI: "",
    bloodPressure: "",
    respiratoryRate: "",
  });
  const [physicalExams, setPhysicalExams] = useState([]);
  const [signPad, setSignPad] = useState({});
  const [signModalOpen, setSignModalOpen] = useState(false);
  const [signed, setSigned] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handlePhysicalExamChange = (data) => {
    const newPhysicalExams = [...physicalExams];
    newPhysicalExams[selectedPainIndex] = data;
    setPhysicalExams(newPhysicalExams);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      handleClear();
      setSignModalOpen(false);
    }
  };

  const handleSave = () => {
    let physicalSign = null;
    let status = "unsigned";
    if (signed) {
      physicalSign = signPad.getTrimmedCanvas().toDataURL("image/png");
      status = "signed";
    }
    const initial_note_type = is_telehealth_consult_note
      ? TELEHEALTH_CONSULT_NOTE_TYPE
      : PAIN_CONSULT_NOTE_TYPE;

    let initial_consult_note_info = {
      initial_note_type,
      patient_id,
      patientName,
      dateOfBirth,
      dateOfFirstVisit,
      gender,
      provider,
      dateOfService: dateOfService.format("MM/DD/YYYY"),
      timeOfService: timeOfService.format("hh:mm A"),
      pains,
      pastMedicalHistory,
      pastSurgicalHistory,
      medications,
      allergies,
      vitalSigns,
      physicalExams,
      imagesReviewed,
      imagesReviewedInfo,
      assessment,
      muscleStrain,
      plans: planText,
      authorizations: authorizationText,
      icd10codes,
      cptCodes,
      status,
      physicalSign,
    };

    // Check token expiration and refresh if necessary
    // const token = window.localStorage.getItem("alex-med-token");
    // const tokenPayload = JSON.parse(atob(token.split(".")[1]));
    // const isTokenExpired = tokenPayload.exp * 1000 < Date.now();

    // if (isTokenExpired) {
    //   await refreshToken();
    // }

    // Make a POST request to the backend using secureApi or fetch
    secureApi(window.localStorage.getItem("alex-med-token"))
      .post(`/initial-consult-note`, initial_consult_note_info)
      .then((response) => {
        if (is_telehealth_consult_note)
          toast.success("Telehealth consult note saved successfully!");
        else toast.success("Pain consult note saved successfully!");
      })
      .catch((e) => {
        handleClose();
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          toast.error(e.response.data.message);
        }
      });

    if (signed) handleClose();
  };

  const handleClear = () => {
    signPad.clear();
    setSigned(false);
  };

  const handleCancelModalClose = () => {
    setCancelModalOpen(false);
  };

  const handleCancel = () => {
    window.scrollTo(0, 0);
    navigate(`/patient/${patient_id}`);
  };

  const handleCancelModalOpen = () => {
    setCancelModalOpen(true);
  };

  useEffect(() => {
    if (note) {
      setPains(note.pains);
      if (!is_telehealth_consult_note) setPhysicalExams(note.physicalExams);
      if (note.pains && note.pains.length) {
        setSelectedPainIndex(0);
        setSelectedPainKey(note.pains[0]["key"]);
      }
      setVitalSigns(note.vitalSigns);
    }
  }, [note]);

  useEffect(() => {
    if (is_telehealth_consult_note)
      setMenuItems([
        { text: "Demographics", id: "demographics" },
        { text: "HPI", id: "hpi" },
        { text: "Past Medical History (PMH)", id: "pmh" },
        { text: "Past Surgical History (PSH)", id: "psh" },
        { text: "Medications", id: "medications" },
        { text: "Allergies", id: "allergies" },
        { text: "Images Reviewed", id: "images_reviewed" },
      ]);
  }, [is_telehealth_consult_note]);

  return (
    <div style={{ display: "flex" }}>
      <AppBar
        position="fixed"
        style={{
          width: "250px",
          left: 0,
          height: "100vh",
          background: "#001d57",
        }}
      >
        <div className="Horizontal-Bar">
          {menuItems.map((item) => (
            <ScrollLink
              key={item.id}
              activeClass="active"
              to={item.id}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <Button
                color="inherit"
                style={{
                  width: "100%",
                  fontSize: "20px",
                  justifyContent: "left",
                  textAlign: "left",
                }}
              >
                {item.text}
              </Button>
            </ScrollLink>
          ))}
          {user && user.role === ADMIN && (
            <ScrollLink
              key="assessment_plan"
              activeClass="active"
              to="assessment_plan"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <Button
                color="inherit"
                style={{
                  width: "100%",
                  fontSize: "20px",
                  justifyContent: "left",
                  textAlign: "left",
                }}
              >
                Assessment/Plan
              </Button>
            </ScrollLink>
          )}
          <ScrollLink
            key="icd-10_codes"
            activeClass="active"
            to="icd-10_codes"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <Button
              color="inherit"
              style={{
                width: "100%",
                fontSize: "20px",
                justifyContent: "left",
                textAlign: "left",
              }}
            >
              ICD-10 Codes
            </Button>
          </ScrollLink>
          <ScrollLink
            key="cpt_codes"
            activeClass="active"
            to="cpt_codes"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <Button
              color="inherit"
              style={{
                width: "100%",
                fontSize: "20px",
                justifyContent: "left",
                textAlign: "left",
              }}
            >
              CPT Codes
            </Button>
          </ScrollLink>
        </div>
      </AppBar>
      <Stack
        sx={{ marginLeft: "250px", padding: "40px", width: "100%" }}
        spacing={3}
      >
        <section id="demographics">
          <Demographics
            patientName={patientName}
            dateOfBirth={dateOfBirth}
            gender={gender}
            provider={provider}
            setProvider={setProvider}
            dateOfService={dateOfService}
            timeOfService={timeOfService}
            setDateOfService={setDateOfService}
            setTimeOfService={setTimeOfService}
          />
        </section>

        <section id="hpi">
          <HPI
            pains={pains}
            setPains={setPains}
            selectedPainIndex={selectedPainIndex}
            setSelectedPainIndex={setSelectedPainIndex}
            selectedPainKey={selectedPainKey}
            setSelectedPainKey={setSelectedPainKey}
            physicalExams={physicalExams}
            setPhysicalExams={setPhysicalExams}
            handlePhysicalExamChange={handlePhysicalExamChange}
            is_telehealth_consult_note={is_telehealth_consult_note}
            gender={gender}
            dateOfBirth={dateOfBirth}
          />
        </section>

        <section id="pmh">
          <PMH pmh={note?.pastMedicalHistory} />
        </section>

        <section id="psh">
          <PSH psh={note?.pastSurgicalHistory} />
        </section>

        <section id="medications">
          <Medications db_medications={note?.medications} />
        </section>

        <section id="allergies">
          <Allergies db_allergies={note?.allergies} />
        </section>

        {!is_telehealth_consult_note && (
          <section id="physical_exam">
            <VitalSigns vitalSigns={vitalSigns} setVitalSigns={setVitalSigns} />
            {user && user.role === ADMIN && (
              <>
                {selectedPainKey === "headache" ? (
                  <HeadPhysicalExam
                    headacheInfo={physicalExams[selectedPainIndex]}
                    setHeadacheInfo={handlePhysicalExamChange}
                  />
                ) : selectedPainKey === "neckPain" ? (
                  <NeckPhysicalExam
                    neckPainInfo={physicalExams[selectedPainIndex]}
                    setNeckPainInfo={handlePhysicalExamChange}
                  />
                ) : selectedPainKey === "cervicalDystonia" ? (
                  <CervicalPhysicalExam
                    cervicalDystoniaInfo={physicalExams[selectedPainIndex]}
                    setCervicalDystoniaInfo={handlePhysicalExamChange}
                  />
                ) : selectedPainKey === "backPain" ? (
                  <BackPhysicalExam
                    lowBackPainInfo={physicalExams[selectedPainIndex]}
                    setLowBackPainInfo={handlePhysicalExamChange}
                  />
                ) : selectedPainKey === "shoulderPain" ? (
                  <ShoulderPhysicalExam
                    shoulderPainInfo={physicalExams[selectedPainIndex]}
                    setShoulderPainInfo={handlePhysicalExamChange}
                  />
                ) : selectedPainKey === "elbowPain" ? (
                  <ElbowPhysicalExam
                    elbowPainInfo={physicalExams[selectedPainIndex]}
                    setElbowPainInfo={handlePhysicalExamChange}
                  />
                ) : selectedPainKey === "wristPain" ? (
                  <WristhysicalExam
                    wristPainInfo={physicalExams[selectedPainIndex]}
                    setWristPainInfo={handlePhysicalExamChange}
                  />
                ) : selectedPainKey === "kneePain" ? (
                  <KneePhysicalExam
                    kneePainInfo={physicalExams[selectedPainIndex]}
                    setKneePainInfo={handlePhysicalExamChange}
                  />
                ) : (
                  <TextField
                    multiline
                    value={physicalExams[selectedPainIndex]}
                    onChange={(e) => handlePhysicalExamChange(e.target.value)}
                    fullWidth
                    rows={5}
                  ></TextField>
                )}
              </>
            )}
          </section>
        )}

        <section id="images_reviewed">
          <ImagesReviewed
            db_imagesReviewed={note?.imagesReviewed}
            setImagesReviewedInfo={setImagesReviewedInfo}
          />
        </section>

        {user && user.role === ADMIN && (
          <section id="assessment_plan">
            <Stack spacing={3}>
              <Assessment
                patientName={patientName}
                dateOfBirth={dateOfBirth}
                gender={gender}
                db_assessment={note?.assessment}
              />
              <Plan db_plans={note?.plans} />
              <Authorization db_authorizations={note?.authorizations} />
            </Stack>
          </section>
        )}

        <section id="icd-10_codes">
          <ICD10Codes
            db_icd10codes={note?.icd10codes}
            icd10codes={icd10codes}
            setIcd10codes={setIcd10codes}
          />
        </section>

        <section id="cpt_codes">
          <CPTcodes
            db_CPTcodes={note?.cptCodes}
            note_type={
              is_telehealth_consult_note
                ? TELEHEALTH_CONSULT_NOTE_TYPE
                : PAIN_CONSULT_NOTE_TYPE
            }
            cptCodes={cptCodes}
            setCptCodes={setCptCodes}
          />
        </section>
        <Divider style={{ margin: "10px 0px" }} />
        <Stack direction="row" spacing={1} justifyContent="center">
          <Button
            variant="contained"
            color="error"
            onClick={handleCancelModalOpen}
          >
            Close
          </Button>
          {(note === null || note.status === "unsigned") && (
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          )}
          {user && user.role === ADMIN && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSignModalOpen(true)}
            >
              Sign and Save
            </Button>
          )}
        </Stack>
      </Stack>

      <Dialog
        fullScreen={fullScreen}
        open={cancelModalOpen}
        onClose={handleCancelModalClose}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure to close the consult note?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you agree, you will redirect to patient page.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelModalClose}>
            Disagree
          </Button>
          <Button onClick={handleCancel} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={signModalOpen} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Sign</DialogTitle>
        <Divider />
        <DialogContent style={{ width: "fit-content" }}>
          <div
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              width: "fit-content",
            }}
          >
            <SignatureCanvas
              penColor="black"
              canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
              ref={(ref) => {
                setSignPad(ref);
              }}
              onEnd={() => setSigned(true)}
            />
          </div>
        </DialogContent>
        <Divider style={{ margin: "5px 0px" }} />
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClear} variant="contained" color="warning">
            Clear
          </Button>
          <Button onClick={handleSave} variant="contained" disabled={!signed}>
            Sign
          </Button>
        </DialogActions>
      </Dialog>
      {/* <SessionExpirationDialog
        open={sessionDialogOpen}
        onContinue={handleSessionContinue}
        onLogout={handleSessionLogout}
      /> */}
    </div>
  );
};

export default InitialConsultNote;
