import React, { useContext } from "react";
import { Paper, Input, Typography, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Context } from "../../context";
import { MEDICAL_BILLING_PERSON } from "../../config";

const PatientInfoEditInput = ({
  name,
  value,
  updatePatientInfo,
  painConsultNoteSigned,
}) => {
  const { user } = useContext(Context);
  return (
    <Input
      variant="filled"
      color="primary"
      size="medium"
      fullWidth
      value={value}
      name={name}
      onChange={(e) => updatePatientInfo(e)}
      readOnly={user && user.role === MEDICAL_BILLING_PERSON}
    />
  );
};

const InsuranceInformation = ({
  insuranceInformation,
  setInsuranceInformation,
  painConsultNoteSigned,
}) => {
  const { user } = useContext(Context);
  const updateInsuranceInformation = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInsuranceInformation((values) => ({ ...values, [name]: value }));
  };

  const handleDateOfBirthOfSubscriberChange = (date) => {
    setInsuranceInformation({
      ...insuranceInformation,
      date_of_birth_of_subscriber: date,
    });
  };

  return (
    <Paper>
      <Typography
        variant="h6"
        fontWeight="bold"
        marginBottom="10px"
        paddingTop="20px"
        paddingLeft="20px"
      >
        Insurance Information:
      </Typography>
      <Stack direction="row" spacing={3} style={{ width: "100%" }}>
        <Stack
          direction="column"
          spacing={1}
          style={{
            width: "50%",
            padding: "20px",
            paddingTop: "0",
            boxSizing: "border-box",
          }}
        >
          <Stack direction="row">
            <Typography variant="h6" width="50%">
              Insurance Provider
            </Typography>
            <PatientInfoEditInput
              name="insurance_provider"
              value={insuranceInformation.insurance_provider}
              updatePatientInfo={updateInsuranceInformation}
              painConsultNoteSigned={painConsultNoteSigned}
            />
          </Stack>

          <Stack direction="row">
            <Typography variant="h6" width="50%">
              Policy Number
            </Typography>
            <PatientInfoEditInput
              name="policy_number"
              value={insuranceInformation.policy_number}
              updatePatientInfo={updateInsuranceInformation}
              painConsultNoteSigned={painConsultNoteSigned}
            />
          </Stack>

          <Stack direction="row">
            <Typography variant="h6" width="50%">
              Group Number
            </Typography>
            <PatientInfoEditInput
              name="group_number"
              value={insuranceInformation.group_number}
              updatePatientInfo={updateInsuranceInformation}
              painConsultNoteSigned={painConsultNoteSigned}
            />
          </Stack>

          <Stack direction="row">
            <Typography variant="h6" width="50%">
              Subscriber's name
            </Typography>
            <PatientInfoEditInput
              name="name_of_subscriber"
              value={insuranceInformation.name_of_subscriber}
              updatePatientInfo={updateInsuranceInformation}
              painConsultNoteSigned={painConsultNoteSigned}
            />
          </Stack>
        </Stack>

        <Stack
          direction="column"
          spacing={1}
          style={{
            width: "50%",
            padding: "20px",
            paddingTop: "0px",
            boxSizing: "border-box",
          }}
        >
          <Stack direction="row">
            <Typography variant="h6" width="43%">
              Subscriber's date of birth
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                onChange={handleDateOfBirthOfSubscriberChange}
                value={insuranceInformation.date_of_birth_of_subscriber}
                size="small"
                style={{ width: "100%" }}
                disabled={user && user.role === MEDICAL_BILLING_PERSON}
              />
            </LocalizationProvider>
          </Stack>

          <Stack direction="row">
            <Typography variant="h6" width="75%">
              Subscriber's relationship to the patient
            </Typography>
            <PatientInfoEditInput
              name="relationship_to_the_patient"
              value={insuranceInformation.relationship_to_the_patient}
              updatePatientInfo={updateInsuranceInformation}
              painConsultNoteSigned={painConsultNoteSigned}
            />
          </Stack>

          <Stack direction="row">
            <Typography variant="h6" width="75%">
              Subscriber's Address
            </Typography>
            <PatientInfoEditInput
              name="address_of_subscriber"
              value={insuranceInformation.address_of_subscriber}
              updatePatientInfo={updateInsuranceInformation}
              painConsultNoteSigned={painConsultNoteSigned}
            />
          </Stack>

          <Stack direction="row">
            <Typography variant="h6" width="75%">
              Subscriber's Phone Number
            </Typography>
            <PatientInfoEditInput
              name="phone_number_of_subscriber"
              value={insuranceInformation.phone_number_of_subscriber}
              updatePatientInfo={updateInsuranceInformation}
              painConsultNoteSigned={painConsultNoteSigned}
            />
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default InsuranceInformation;
