import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Stack,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  Input,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SignatureCanvas from "react-signature-canvas";
import PatientInfo from "../components/procedure_note/patientInfo";
import InterimHPI from "../components/procedure_note/interimHpi";
import ProcedurePerformed from "../components/procedure_note/procedurePerformed";
import TopNav from "../components/topnav";
import ICD10Codes from "../components/icd10codes";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { Context } from "../context";
import { secureApi, ADMIN, MEDICAL_ASSISTANT } from "../config";

const today = new Date();
const date = today.getDate();
const month = today.getMonth() + 1;
const year = today.getFullYear();

const ProcedureNote = () => {
  const { patient, setPatient, setLoggedIn, user } = useContext(Context);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const patient_id = params.get("patient_id"); // patient ID from the url link parameter
  const [patientID, setPatientID] = useState(null); // patient ID from a saved procedure note
  const { procedure_note_id } = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  if (user && user.role !== ADMIN && user.role !== MEDICAL_ASSISTANT) {
    toast.error("You don't have permission to access this page");
    navigate("/");
  }

  const [patientName, setPatientName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfProcedureNote, setDateOfProcedureNote] = useState(
    dayjs(`${month}/${date}/${year}`)
  );
  const [surgeon, setSurgeon] = useState("Alexander Movshis");
  const [assistant, setAssistant] = useState("none");
  const [anesthesia, setAnesthesia] = useState("local");
  const [anesthesiologist, setAnesthesiologist] = useState("none");
  const [neuroMonitoring, setNeuroMonitoring] = useState("none");
  const [typeOfFacility, setTypeOfFacility] = useState("Medical Office");
  const [nameOfFacility, setNameOfFacility] = useState(
    "Active Physical Therapy & Chiropractic Group"
  );
  const [addressOfFacility, setAddressOfFacility] = useState(
    "369 Lexington Ave 25th Floor"
  );
  const [cityStateZip, setCityStateZip] = useState("New York, NY 10016");

  const [note, setNote] = useState(null);
  const [interimHPI, setInterimHPI] = useState("");
  const [performedProcedure, setPerformedProcedure] = useState({});
  const [signPad, setSignPad] = useState({});
  const [signModalOpen, setSignModalOpen] = useState(false);
  const [signed, setSigned] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [comments, setComments] = useState("");
  const [icd10codes, setIcd10codes] = useState([]);

  useEffect(() => {
    if (patient_id) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .get(`/patient/${patient_id}`)
        .then((response) => {
          const { data: patient } = response;
          setPatient(patient);
          if (!patient.dateOfService || patient.dateOfService.length == 0) {
            toast.warn("Create initial consult note first!");
            navigate(`/patient/${patient_id}`);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else if (procedure_note_id) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .get(`/procedure-note/${procedure_note_id}`)
        .then((response) => {
          const { data: procedureNote } = response;
          setNote(procedureNote);
          setDateOfProcedureNote(dayjs(procedureNote.dateOfProcedure));
          setSurgeon(procedureNote.surgeon);
          setAssistant(procedureNote.assistant);
          setAnesthesia(procedureNote.anesthesia);
          setAnesthesiologist(procedureNote.anesthesiologist);
          setNeuroMonitoring(procedureNote.neuroMonitoring);
          setTypeOfFacility(procedureNote.typeOfFacility);
          setNameOfFacility(procedureNote.nameOfFacility);
          setAddressOfFacility(procedureNote.addressOfFacility);
          setCityStateZip(procedureNote.cityStateZip);
          setInterimHPI(procedureNote.interimHPI);
          setPerformedProcedure(procedureNote.performedProcedure);
          setComments(procedureNote.comments);
          setPatientID(procedureNote.patient_id);
          if (procedureNote.icd10codes) setIcd10codes(procedureNote.icd10codes);

          secureApi(window.localStorage.getItem("alex-med-token"))
            .get(`/patient/${procedureNote.patient_id}`)
            .then((response) => {
              const { data: patient } = response;
              setPatient(patient);
            })
            .catch((e) => {
              console.log(e);
              if (e.response && e.response.status === 401) {
                toast.warn(
                  "Session has been expired. You need to login again!"
                );
                setLoggedIn(false);
                navigate("/login");
              } else {
                toast.error(e.response.data.message);
              }
            });
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else {
      toast.error("Something went wrong!");
    }
  }, [patient_id, procedure_note_id]);

  useEffect(() => {
    if (patient && patient.patientInfo) {
      let name = `${patient.patientInfo.first_name} ${patient.patientInfo.last_name}`;
      setPatientName(name);
      setDateOfBirth(patient.patientInfo.date_of_birth);
    }
  }, [patient]);

  const handleCancelModalOpen = () => {
    setCancelModalOpen(true);
  };
  const handleCancelModalClose = () => {
    setCancelModalOpen(false);
  };
  const handleCancel = () => {
    window.scrollTo(0, 0);
    navigate(`/patient/${patient_id || patientID}`);
  };
  const handleClear = () => {
    signPad.clear();
    setSigned(false);
  };
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      handleClear();
      setSignModalOpen(false);
    }
  };
  const handleSave = () => {
    let physicalSign = null;
    let status = "unsigned";
    if (signed) {
      physicalSign = signPad.getTrimmedCanvas().toDataURL("image/png");
      status = "signed";
      handleClose();
    }
    if (patient_id)
      secureApi(window.localStorage.getItem("alex-med-token"))
        .post(`/procedure-note`, {
          patient_id,
          patientName,
          dateOfBirth,
          dateOfProcedure: dateOfProcedureNote.format("MM/DD/YYYY"),
          surgeon,
          assistant,
          anesthesia,
          anesthesiologist,
          neuroMonitoring,
          typeOfFacility,
          nameOfFacility,
          addressOfFacility,
          cityStateZip,
          interimHPI,
          performedProcedure,
          comments,
          icd10codes,
          status,
          physicalSign,
        })
        .then((response) => {
          const { noteId } = response.data;
          navigate(`/procedure-note/${noteId}`);
          toast.success("New procedure note has been created successfully!");
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    else if (procedure_note_id)
      secureApi(window.localStorage.getItem("alex-med-token"))
        .put(`/procedure-note/${procedure_note_id}`, {
          patientName,
          dateOfBirth,
          dateOfProcedure: dateOfProcedureNote.format("MM/DD/YYYY"),
          surgeon,
          assistant,
          anesthesia,
          anesthesiologist,
          neuroMonitoring,
          typeOfFacility,
          nameOfFacility,
          addressOfFacility,
          cityStateZip,
          interimHPI,
          performedProcedure,
          comments,
          icd10codes,
          status,
          physicalSign,
        })
        .then((response) => {
          toast.success("Procedure note has been updated successfully!");
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    else toast.error("Something went wrong!");
  };

  return (
    <>
      <TopNav />
      <Stack direction="column" style={{ margin: "30px" }} spacing={2}>
        <Typography variant="h5" align="center" style={{ fontWeight: "bold" }}>
          Modal Medical Services PLLC
        </Typography>
        <Typography
          variant="h5"
          align="center"
          style={{ fontWeight: "bold", marginTop: "20px" }}
        >
          Pain Management Service
        </Typography>
        <Typography
          variant="h5"
          align="center"
          style={{ fontWeight: "bold", marginTop: "20px" }}
        >
          Procedure Note
        </Typography>
        <PatientInfo
          patientName={patientName}
          dateOfBirth={dateOfBirth}
          dateOfProcedureNote={dateOfProcedureNote}
          setDateOfProcedureNote={setDateOfProcedureNote}
          surgeon={surgeon}
          setSurgeon={setSurgeon}
          assistant={assistant}
          setAssistant={setAssistant}
          anesthesia={anesthesia}
          setAnesthesia={setAnesthesia}
          anesthesiologist={anesthesiologist}
          setAnesthesiologist={setAnesthesiologist}
          neuroMonitoring={neuroMonitoring}
          setNeuroMonitoring={setNeuroMonitoring}
          typeOfFacility={typeOfFacility}
          setTypeOfFacility={setTypeOfFacility}
          nameOfFacility={nameOfFacility}
          setNameOfFacility={setNameOfFacility}
          addressOfFacility={addressOfFacility}
          setAddressOfFacility={setAddressOfFacility}
          cityStateZip={cityStateZip}
          setCityStateZip={setCityStateZip}
        />
        <InterimHPI interimHPI={interimHPI} setInterimHPI={setInterimHPI} />
        <ProcedurePerformed
          custom={patient_id ? false : true}
          performedProcedure={performedProcedure}
          setPerformedProcedure={setPerformedProcedure}
        />
        <Divider style={{ margin: "10px 0px" }} />
        <ICD10Codes icd10codes={icd10codes} setIcd10codes={setIcd10codes} />
        <Divider style={{ margin: "10px 0px" }} />
        <Stack direction="row" spacing={1}>
          <Typography variant="h6" minWidth="250px">
            Comments
          </Typography>
          <Input
            fullWidth
            placeholder="Comments"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          ></Input>
        </Stack>
        <Divider style={{ margin: "10px 0px" }} />
        <Stack direction="row" spacing={1} style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleCancelModalOpen}
          >
            Close
          </Button>
          {(note === null || note.status === "unsigned") && (
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setSignModalOpen(true)}
          >
            Sign and Save
          </Button>
        </Stack>

        <Dialog
          fullScreen={fullScreen}
          open={cancelModalOpen}
          onClose={handleCancelModalClose}
        >
          <DialogTitle id="responsive-dialog-title">
            {"Are you sure to close the procedure note?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              If you agree, you will redirect to patient page.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancelModalClose}>
              Disagree
            </Button>
            <Button onClick={handleCancel} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={signModalOpen} onClose={handleClose} maxWidth="lg">
          <DialogTitle>Sign</DialogTitle>
          <Divider />
          <DialogContent style={{ width: "fit-content" }}>
            <div
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                width: "fit-content",
              }}
            >
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas",
                }}
                ref={(ref) => {
                  setSignPad(ref);
                }}
                onEnd={() => setSigned(true)}
              />
            </div>
          </DialogContent>
          <Divider style={{ margin: "5px 0px" }} />
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={handleClear} variant="contained" color="warning">
              Clear
            </Button>
            <Button onClick={handleSave} variant="contained" disabled={!signed}>
              Sign
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  );
};

export default ProcedureNote;
