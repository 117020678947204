import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  useMediaQuery
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { secureApi } from '../../config';
import { Context } from '../../context';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const DeleteModal = ({ open, userID, handleCancel }) => {
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const deleteUser = () => {
    secureApi(window.localStorage.getItem('alex-med-token')).delete(`/user/${userID}`)
      .then(() => {
        toast.success("User has been deleted successfully!");
        window.location.href = window.location.href;
      }).catch(e => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn('Session has been expired. You need to login again!');
          setLoggedIn(false);
          navigate('/login');
        } else {
          toast.error(e.response.data.message);
        }
      });
  }

  return (
    <BootstrapDialog fullScreen={fullScreen} open={open} fullWidth maxWidth='md'>
      <DialogTitle>
        Are you sure to delete the user?
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          If you agree, this user won't be able to use this app again!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteUser} autoFocus variant='contained'>
          Agree
        </Button>
        <Button onClick={handleCancel} variant='contained' color='error'>
          Disagree
        </Button>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default DeleteModal;