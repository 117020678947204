import React, { useContext } from 'react';
import { Paper, Input, Typography, Stack } from '@mui/material';
import { Context } from '../../context';
import { MEDICAL_BILLING_PERSON } from '../../config';

const PatientInfoEditInput = ({ name, value, updatePatientInfo, painConsultNoteSigned }) => {
  const { user } = useContext(Context);
  return <Input
    variant="filled"
    color="primary"
    size="medium"
    fullWidth
    value={value}
    name={name}
    onChange={(e) => updatePatientInfo(e)}
    readOnly={(user && user.role === MEDICAL_BILLING_PERSON) || painConsultNoteSigned}
  />
}

const InsuranceLawyerInfo = ({ insuranceLawyerInfo, setInsuranceLawyerInfo, painConsultNoteSigned }) => {
  const updateInsuranceLawyerInfo = event => {
    const name = event.target.name;
    const value = event.target.value;
    setInsuranceLawyerInfo(values => ({ ...values, [name]: value }));
  }

  return (
    <Paper>
      <Typography variant='h6' fontWeight='bold' marginBottom='10px' paddingTop='20px' paddingLeft='20px'>Insurance and Lawyer Information:</Typography>
        <Stack direction='row' spacing={3} style={{ width: '100%' }}>
          <Stack direction='column' spacing={1} style={{ width: '50%', padding: '20px', paddingTop: '0', boxSizing: 'border-box' }}>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Insurance Company</Typography>
              <PatientInfoEditInput name="insurance_company" value={insuranceLawyerInfo.insurance_company} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Insurance Address</Typography>
              <PatientInfoEditInput name="insurance_address" value={insuranceLawyerInfo.insurance_address} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Auto Policy Number</Typography>
              <PatientInfoEditInput name="auto_policy_number" value={insuranceLawyerInfo.auto_policy_number} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Claim #</Typography>
              <PatientInfoEditInput name="claim" value={insuranceLawyerInfo.claim} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Workers Compensation #</Typography>
              <PatientInfoEditInput name="workers_compensation" value={insuranceLawyerInfo.workers_compensation} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Carrier Case</Typography>
              <PatientInfoEditInput name="carrier_case" value={insuranceLawyerInfo.carrier_case} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>

          </Stack>
          <Stack direction='column' spacing={1} style={{ width: '50%', padding: '20px', paddingTop: '0', boxSizing: 'border-box' }}>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Claim Adjustor</Typography>
              <PatientInfoEditInput name="claim_adjustor" value={insuranceLawyerInfo.claim_adjustor} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Claim Adjustor Phone</Typography>
              <PatientInfoEditInput name="claim_adjustor_phone" value={insuranceLawyerInfo.claim_adjustor_phone} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Extension</Typography>
              <PatientInfoEditInput name="extension" value={insuranceLawyerInfo.extension} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Lawyer</Typography>
              <PatientInfoEditInput name="lawyer" value={insuranceLawyerInfo.lawyer} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Lawyer Phone Number</Typography>
              <PatientInfoEditInput name="lawyer_phone_number" value={insuranceLawyerInfo.lawyer_phone_number} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Lawyer Email Address</Typography>
              <PatientInfoEditInput name="lawyer_email_address" value={insuranceLawyerInfo.lawyer_email_address} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>
            <Stack direction='row'>
              <Typography variant='h6' width='50%'>Lawyer Address</Typography>
              <PatientInfoEditInput name="lawyer_address" value={insuranceLawyerInfo.lawyer_address} updatePatientInfo={updateInsuranceLawyerInfo} painConsultNoteSigned={painConsultNoteSigned} />
            </Stack>
          </Stack>
        </Stack>
    </Paper>
  );
};

export default InsuranceLawyerInfo;