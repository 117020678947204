import React, { useState, useEffect } from "react";
import {
  Stack,
  TextField,
  Autocomplete,
  Grid,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import {
  Diamond as CodeIcon,
  Close as CloseIcon,
  Add as AddIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  CPT_CODES,
  FOLLOW_UP_NOTE_TYPE,
  PAIN_CONSULT_NOTE_TYPE,
  TELEHEALTH_CONSULT_NOTE_TYPE,
} from "../config";

const CPT_CODE_MODAL_TYPE_ADD = "Add";
const CPT_CODE_MODAL_TYPE_UPDATE = "Update";

export const CPTcodes = ({ note_type, db_CPTcodes, cptCodes, setCptCodes }) => {
  const [options, setOptions] = useState([]);
  const [cptCode, setCptCode] = useState("");
  const [cptCodeIndex, setCptCodeIndex] = useState();
  const [cptCodeError, setCptCodeError] = useState(false);
  const [cptCodeModalOpen, setCptCodeModalOpen] = useState(false);
  const [cptCodeModalType, setCptCodeModalType] = useState("");

  useEffect(() => {
    if (db_CPTcodes) setCptCodes(db_CPTcodes);
  }, [db_CPTcodes]);

  useEffect(() => {
    if (note_type === PAIN_CONSULT_NOTE_TYPE) {
      setOptions([...CPT_CODES["New Patient"], ...CPT_CODES["New Consult"]]);
    } else if (note_type === FOLLOW_UP_NOTE_TYPE) {
      setOptions(CPT_CODES["Established Patient"]);
    } else if (note_type === TELEHEALTH_CONSULT_NOTE_TYPE) {
      setOptions(CPT_CODES["Telehealth Visit"]);
    }
  }, [note_type]);

  const removeCPTcode = (index) => {
    let newCPTcodes = [...cptCodes];
    newCPTcodes.splice(index, 1);
    setCptCodes(newCPTcodes);
  };

  const handleCptCodeModalOpen = (modalType, CPTcode, index) => {
    if (CPTcode) setCptCode(CPTcode);
    setCptCodeIndex(index);
    setCptCodeModalType(modalType);
    setCptCodeModalOpen(true);
  };

  const handleCptCodeModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setCptCode("");
      setCptCodeError(false);
      setCptCodeModalOpen(false);
    }
  };

  const handleCptCodeChange = (e) => {
    const { value: code } = e.target;
    setCptCode(code);
    if (code === "") setCptCodeError(true);
    else setCptCodeError(false);
  };

  const handleCptCodeAdd = () => {
    if (cptCode) {
      if (cptCodeModalType === CPT_CODE_MODAL_TYPE_ADD) {
        setCptCodes([...cptCodes, cptCode]);
        handleCptCodeModalClose();
      } else {
        let newCptCodes = [...cptCodes];
        newCptCodes[cptCodeIndex] = cptCode;
        setCptCodes(newCptCodes);
        handleCptCodeModalClose();
      }
    } else setCptCodeError(true);
  };

  return (
    <Stack direction="row" spacing={5}>
      <Autocomplete
        sx={{ width: "30%" }}
        options={options}
        autoHighlight
        getOptionLabel={(option) => `${option.code} - ${option.text}`}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <CodeIcon sx={{ color: "text.secondary" }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {`${option["code"]} - ${option["text"]}`}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            const codeLabel = `${newValue["code"].trim()} - ${newValue[
              "text"
            ].trim()}`;
            if (cptCodes.indexOf(codeLabel) === -1)
              setCptCodes([...cptCodes, codeLabel]);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a CPT code"
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
      <Stack direction="column" sx={{ width: "70%" }}>
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          Selected CPT Codes:
        </Typography>
        {cptCodes && cptCodes.length ? (
          cptCodes.map((CPTcode, idx) => (
            <Typography variant="h6" key={idx}>
              {CPTcode}
              <IconButton variant="outlined" onClick={() => removeCPTcode(idx)}>
                <CloseIcon />
              </IconButton>
              <IconButton
                variant="outlined"
                onClick={() =>
                  handleCptCodeModalOpen(
                    CPT_CODE_MODAL_TYPE_UPDATE,
                    CPTcode,
                    idx
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </Typography>
          ))
        ) : (
          <Typography variant="h6">None</Typography>
        )}
        {cptCodes &&
          cptCodes.length > 0 &&
          note_type === TELEHEALTH_CONSULT_NOTE_TYPE && (
            <ul style={{ fontSize: "20px" }}>
              <li>
                Modifier: 95 (Synchronous telemedicine service rendered via a
                real-time interactive audio and video telecommunications system)
              </li>
              <li>Place of Service (POS) Code: 02 (Telehealth)</li>
            </ul>
          )}
        <Button
          variant="contained"
          sx={{ width: "100px", minWidth: "100px" }}
          startIcon={<AddIcon />}
          onClick={() => handleCptCodeModalOpen(CPT_CODE_MODAL_TYPE_ADD)}
        >
          Add
        </Button>
      </Stack>
      <Dialog
        open={cptCodeModalOpen}
        onClose={handleCptCodeModalClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{`${cptCodeModalType} Code`}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Code"
            variant="standard"
            fullWidth
            value={cptCode}
            error={cptCodeError}
            onChange={handleCptCodeChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCptCodeModalClose}>Cancel</Button>
          <Button onClick={handleCptCodeAdd}>{`${cptCodeModalType}`}</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default CPTcodes;
