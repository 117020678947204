import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  Stack,
  Typography,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  TextField,
  Switch,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SignatureCanvas from "react-signature-canvas";
import { toast } from "react-toastify";
import PatientInfo from "../components/followup_note/patientInfo";
import FollowupHPI from "../components/followup_note/followupHpi";
import PMH from "../components/followup_note/pmh";
import PSH from "../components/followup_note/psh";
import Medications from "../components/followup_note/medications";
import Allergies from "../components/followup_note/allergies";
import VitalSigns from "../components/pain_consult_note_chronic/vitalSigns";
import HeadPhysicalExam from "../components/pain_consult_note_chronic/physicalExam/headPhysicalExam";
import NeckPhysicalExam from "../components/pain_consult_note_chronic/physicalExam/neckPhysicalExam";
import BackPhysicalExam from "../components/pain_consult_note_chronic/physicalExam/backPhysicalExam";
import ShoulderPhysicalExam from "../components/pain_consult_note_chronic/physicalExam/shoulderPhysicalExam";
import ElbowPhysicalExam from "../components/pain_consult_note_chronic/physicalExam/elbowPhysicalExam";
import WristhysicalExam from "../components/pain_consult_note_chronic/physicalExam/wristPhysicalExam";
import KneePhysicalExam from "../components/pain_consult_note_chronic/physicalExam/kneePhysicalExam";
import CervicalPhysicalExam from "../components/pain_consult_note_chronic/physicalExam/cervicalPhysicalExamp";
import ImagesReviewed from "../components/followup_note/imagesReviewed";
import Assessment from "../components/followup_note/assessment";
import Plan from "../components/followup_note/plan";
import Authorization from "../components/followup_note/authorization";
import ICD10Codes from "../components/icd10codes";
import CPTcodes from "../components/CPTcodes";
import TopNav from "../components/topnav";
import {
  secureApi,
  ADMIN,
  MEDICAL_BILLING_PERSON,
  MEDICAL_ASSISTANT,
  FOLLOW_UP_NOTE_TYPE,
} from "../config";
import { Context } from "../context";
import dayjs from "dayjs";

const now = new Date();
const year = now.getFullYear();
const month = now.getMonth() + 1;
const day = now.getDate();
const hour = now.getHours();
const minute = now.getMinutes();

const FollowUpNote = () => {
  const navigate = useNavigate();
  const { setLoggedIn, planText, setPlanText, user } = useContext(Context);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const patient_id = params.get("patient_id");
  const [patientID, setPatientID] = useState(null);
  const { followup_note_id } = useParams();

  const [patientName, setPatientName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfService, setDateOfService] = useState(
    dayjs(`${month}/${day}/${year}`)
  );
  const [timeOfService, setTimeOfService] = useState(
    dayjs(`${month}/${day}/${year} ${hour}:${minute}`)
  );
  const [note, setNote] = useState(null);
  const [provider, setProvider] = useState("Alexander Movshis, M.D");
  const [dateOfPainConsultNote, setDateOfPainConsultNote] = useState("");
  const [initialHPI, setInitialHPI] = useState("");
  const [pains, setPains] = useState([]);
  const [pastFollowupNotes, setPastFollowupNotes] = useState([]);
  const [followupHPI, setFollowupHPI] = useState("");
  const [pastMedicalHistory, setPastMedicalHistory] = useState([]);
  const [pastSurgicalHistory, setPastSurgicalHistory] = useState([]);
  const [medications, setMedications] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [imagesReviewed, setImagesReviewed] = useState([]);
  const [initialImagesReviewed, setInitialImagesReviewed] = useState([]);
  const [imagesReviewedInfo, setImagesReviewedInfo] = useState([]);
  const [assessment, setAssessment] = useState("");
  const [authorizationText, setAuthorizationText] = useState("");
  const [signPad, setSignPad] = useState({});
  const [signModalOpen, setSignModalOpen] = useState(false);
  const [signed, setSigned] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [selectedPainIndex, setSelectedPainIndex] = useState(0);
  const [selectedPainKey, setSelectedPainKey] = useState();
  const [physicalExams, setPhysicalExams] = useState([]);
  const [physicalExamStatus, setPhysicalExamStatus] = useState(false);
  const [icd10codes, setIcd10codes] = useState([]);
  const [cptCodes, setCptCodes] = useState([]);
  const [vitalSigns, setVitalSigns] = useState();

  useEffect(() => {
    if (user && user.role === MEDICAL_BILLING_PERSON) {
      toast.error("You don't have permission to access this page!");
      navigate("/");
    } else if (patient_id) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .get(`/followup-notes/${patient_id}`)
        .then((response) => {
          const { data: notes } = response;

          if (notes.initial_consult_note) {
            setDateOfPainConsultNote(notes.initial_consult_note.dateOfService);
            setInitialHPI(
              notes.initial_consult_note.pains
                .map((pain) => pain["hpi"])
                .join("\n")
            );
            if (
              notes.initial_consult_note.pains &&
              notes.initial_consult_note.pains.length
            )
              setSelectedPainKey(notes.initial_consult_note.pains[0]["key"]);
            setPastMedicalHistory(
              notes.initial_consult_note.pastMedicalHistory
            );
            setPastSurgicalHistory(
              notes.initial_consult_note.pastSurgicalHistory
            );
            setMedications(notes.initial_consult_note.medications);
            setAllergies(notes.initial_consult_note.allergies);
            setInitialImagesReviewed(notes.initial_consult_note.imagesReviewed);

            if (notes.followup_notes.length) {
              setPains(
                notes.followup_notes[notes.followup_notes.length - 1].pains
              );
              setPhysicalExams(
                notes.followup_notes[notes.followup_notes.length - 1]
                  .physicalExams
              );
              setPhysicalExamStatus(
                notes.followup_notes[notes.followup_notes.length - 1]
                  .physicalExamStatus
              );
              setVitalSigns(
                notes.followup_notes[notes.followup_notes.length - 1].vitalSigns
              );

              setFollowupHPI("");
              setAssessment(
                notes.followup_notes[notes.followup_notes.length - 1].assessment
              );
              setPlanText(
                notes.followup_notes[notes.followup_notes.length - 1].plans
              );
              setPastFollowupNotes(notes.followup_notes.reverse());
              const icd10codes =
                notes.followup_notes[notes.followup_notes.length - 1]
                  .icd10codes;
              if (icd10codes) setIcd10codes(icd10codes);
            } else {
              setPains(notes.initial_consult_note.pains);
              if (notes.initial_consult_note.physicalExams)
                setPhysicalExams(notes.initial_consult_note.physicalExams);
              setVitalSigns(notes.initial_consult_note.vitalSigns);
              setFollowupHPI("Patient returns for a follow up.");
              setAssessment(notes.initial_consult_note.assessment);
              setPlanText(notes.initial_consult_note.plans);
              const icd10codes = notes.initial_consult_note.icd10codes;
              if (icd10codes) setIcd10codes(icd10codes);
            }
          } else {
            toast.warn("Create initial consult note first!");
            navigate(`/patient/${patient_id}`);
          }

          secureApi(window.localStorage.getItem("alex-med-token"))
            .get(`/patient/${patient_id}`)
            .then((response) => {
              const { data: patient } = response;
              let name = `${patient.patientInfo.first_name} ${patient.patientInfo.last_name}`;
              setPatientName(name);
              setDateOfBirth(patient.patientInfo.date_of_birth);
              setGender(patient.patientInfo.gender);
            })
            .catch((e) => {
              console.log(e);
              if (e.response && e.response.status === 401) {
                toast.warn(
                  "Session has been expired. You need to login again!"
                );
                setLoggedIn(false);
                navigate("/login");
              } else {
                toast.error(e.response.data.message);
              }
            });
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else if (followup_note_id) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .get(`/followup-note/${followup_note_id}`)
        .then((response) => {
          const { data: notes } = response;
          if (
            !notes ||
            !notes.followup_notes ||
            notes.followup_notes.length === 0
          ) {
            toast.error("No such followup note!");
            navigate("/");
          }

          if (notes.initial_consult_note) {
            const followupNote =
              notes.followup_notes[notes.followup_notes.length - 1];

            if (
              user &&
              user.role === MEDICAL_ASSISTANT &&
              followupNote.status === "signed"
            ) {
              toast.error("You don't have permission to modify signed notes!");
              navigate("/");
              return;
            }

            setPains(followupNote.pains);
            setPhysicalExams(followupNote.physicalExams);
            setPhysicalExamStatus(followupNote.physicalExamStatus);
            setVitalSigns(followupNote.vitalSigns);
            if (followupNote.pains && followupNote.pains.length)
              setSelectedPainKey(followupNote.pains[0]["key"]);

            if (followupNote.icd10codes) setIcd10codes(followupNote.icd10codes);

            setDateOfPainConsultNote(notes.initial_consult_note.dateOfService);
            setInitialHPI(
              followupNote.initialHPI ? followupNote.initialHPI : ""
            );

            setPastMedicalHistory(followupNote.pastMedicalHistory);
            setPastSurgicalHistory(followupNote.pastSurgicalHistory);
            setMedications(followupNote.medications);
            setAllergies(followupNote.allergies);
            setInitialImagesReviewed(notes.initial_consult_note.imagesReviewed);
            setImagesReviewed(followupNote.imagesReviewed);
            setFollowupHPI(followupNote.followupHPI);
            setAssessment(followupNote.assessment);
            setPlanText(followupNote.plans);
            setAuthorizationText(followupNote.authorizations);
            setNote(followupNote);
            setDateOfService(dayjs(followupNote.dateOfService));
            setTimeOfService(
              dayjs(
                `${followupNote.dateOfService} ${followupNote.timeOfService}`
              )
            );
            setProvider(followupNote.provider);
            setPatientID(followupNote.patient_id);
            setPastFollowupNotes(notes.followup_notes.slice(0, -1).reverse());

            secureApi(window.localStorage.getItem("alex-med-token"))
              .get(`/patient/${followupNote.patient_id}`)
              .then((response) => {
                const { data: patient } = response;
                let name = `${patient.patientInfo.first_name} ${patient.patientInfo.last_name}`;
                setPatientName(name);
                setDateOfBirth(patient.patientInfo.date_of_birth);
                setGender(patient.patientInfo.gender);
              })
              .catch((e) => {
                console.log(e);
                if (e.response && e.response.status === 401) {
                  toast.warn(
                    "Session has been expired. You need to login again!"
                  );
                  setLoggedIn(false);
                  navigate("/login");
                } else {
                  toast.error(e.response.data.message);
                }
              });
          } else {
            toast.warn("Create an initial consult note first!");
            navigate(`/`);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else {
      toast.error("Something went wrong!");
    }
  }, [patient_id]);

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      handleClear();
      setSignModalOpen(false);
    }
  };

  const handleClear = () => {
    signPad.clear();
    setSigned(false);
  };

  const handleCancel = () => {
    window.scrollTo(0, 0);
    navigate(`/patient/${patient_id || patientID}`);
  };

  const handleCancelModalOpen = () => {
    setCancelModalOpen(true);
  };

  const handleCancelModalClose = () => {
    setCancelModalOpen(false);
  };

  const handlePhysicalExamChange = (data) => {
    const newPhysicalExams = [...physicalExams];
    newPhysicalExams[selectedPainIndex] = data;
    setPhysicalExams(newPhysicalExams);
  };

  const handleChangePhysicalExamStatus = (e) => {
    setPhysicalExamStatus(e.target.checked);
  };

  const handleSave = () => {
    let physicalSign = null;
    let status = "unsigned";
    if (signed) {
      physicalSign = signPad.getTrimmedCanvas().toDataURL("image/png");
      status = "signed";
      handleClose();
    }

    if (patient_id)
      secureApi(window.localStorage.getItem("alex-med-token"))
        .post(`/followup-note`, {
          patient_id,
          patientName,
          dateOfBirth,
          gender,
          provider,
          dateOfPainConsultNote,
          dateOfService: dateOfService.format("MM/DD/YYYY"),
          timeOfService: timeOfService.format("hh:mm A"),
          pains,
          followupHPI,
          initialHPI,
          pastMedicalHistory,
          pastSurgicalHistory,
          medications,
          vitalSigns,
          physicalExamStatus,
          physicalExams,
          allergies,
          imagesReviewed,
          imagesReviewedInfo,
          assessment,
          plans: planText,
          authorizations: authorizationText,
          icd10codes,
          cptCodes,
          status,
          physicalSign,
        })
        .then((response) => {
          const { noteId } = response.data;
          navigate(`/followup-note/${noteId}`);
          toast.success("Follow up note has been created successfully!");
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    else if (followup_note_id)
      secureApi(window.localStorage.getItem("alex-med-token"))
        .put(`/followup-note/${followup_note_id}`, {
          patientName,
          dateOfBirth,
          gender,
          provider,
          dateOfPainConsultNote,
          dateOfService: dateOfService.format("MM/DD/YYYY"),
          timeOfService: timeOfService.format("hh:mm A"),
          pains,
          followupHPI,
          initialHPI,
          pastMedicalHistory,
          pastSurgicalHistory,
          medications,
          vitalSigns,
          physicalExamStatus,
          physicalExams,
          allergies,
          imagesReviewed,
          imagesReviewedInfo,
          assessment,
          plans: planText,
          authorizations: authorizationText,
          icd10codes,
          cptCodes,
          status,
          physicalSign,
        })
        .then((response) => {
          toast.success("Follow up note has been updated successfully!");
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
  };

  return (
    <>
      <TopNav />
      <Stack direction="column" style={{ margin: "30px" }} spacing={3}>
        <PatientInfo
          patientName={patientName}
          dateOfBirth={dateOfBirth}
          gender={gender}
          provider={provider}
          setProvider={setProvider}
          dateOfService={dateOfService}
          setDateOfService={setDateOfService}
          timeOfService={timeOfService}
          setTimeOfService={setTimeOfService}
        />
        <Typography
          variant="h5"
          align="center"
          style={{ fontWeight: "bold", marginTop: "20px" }}
        >
          Modal Medical Services PLLC
        </Typography>
        <Typography
          variant="h5"
          align="center"
          style={{ fontWeight: "bold", marginTop: "20px" }}
        >
          Pain Management Service
        </Typography>
        <Typography
          variant="h5"
          align="center"
          style={{ fontWeight: "bold", marginTop: "20px" }}
        >
          Follow-Up Evaluation Form
        </Typography>
        <FollowupHPI
          dateOfPainConsultNote={dateOfPainConsultNote}
          followupHPI={followupHPI}
          pastFollowupNotes={pastFollowupNotes}
          setFollowupHPI={setFollowupHPI}
          initialHPI={initialHPI}
          pains={pains}
          setPains={setPains}
          selectedPainIndex={selectedPainIndex}
          setSelectedPainIndex={setSelectedPainIndex}
          selectedPainKey={selectedPainKey}
          setSelectedPainKey={setSelectedPainKey}
          physicalExams={physicalExams}
          setPhysicalExams={setPhysicalExams}
        />
        <PMH pastMedicalHistory={pastMedicalHistory} />
        <PSH pastSurgicalHistory={pastSurgicalHistory} />
        <Medications medications={medications} />
        <Allergies allergies={allergies} />
        {user && user.role === ADMIN && (
          <Stack direction="column">
            <Stack direction="row" style={{ marginTop: "30px" }}>
              <Typography variant="h5" fontWeight="bold">
                Activate Physical Exams:
              </Typography>
              <Switch
                checked={physicalExamStatus}
                onChange={handleChangePhysicalExamStatus}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
            {physicalExamStatus && (
              <>
                <VitalSigns
                  vitalSigns={vitalSigns}
                  setVitalSigns={setVitalSigns}
                />
                <>
                  {selectedPainKey === "headache" ? (
                    <HeadPhysicalExam
                      headacheInfo={physicalExams[selectedPainIndex]}
                      setHeadacheInfo={handlePhysicalExamChange}
                    />
                  ) : selectedPainKey === "neckPain" ? (
                    <NeckPhysicalExam
                      neckPainInfo={physicalExams[selectedPainIndex]}
                      setNeckPainInfo={handlePhysicalExamChange}
                    />
                  ) : selectedPainKey === "cervicalDystonia" ? (
                    <CervicalPhysicalExam
                      cervicalDystoniaInfo={physicalExams[selectedPainIndex]}
                      setCervicalDystoniaInfo={handlePhysicalExamChange}
                    />
                  ) : selectedPainKey === "backPain" ? (
                    <BackPhysicalExam
                      lowBackPainInfo={physicalExams[selectedPainIndex]}
                      setLowBackPainInfo={handlePhysicalExamChange}
                    />
                  ) : selectedPainKey === "shoulderPain" ? (
                    <ShoulderPhysicalExam
                      shoulderPainInfo={physicalExams[selectedPainIndex]}
                      setShoulderPainInfo={handlePhysicalExamChange}
                    />
                  ) : selectedPainKey === "elbowPain" ? (
                    <ElbowPhysicalExam
                      elbowPainInfo={physicalExams[selectedPainIndex]}
                      setElbowPainInfo={handlePhysicalExamChange}
                    />
                  ) : selectedPainKey === "wristPain" ? (
                    <WristhysicalExam
                      wristPainInfo={physicalExams[selectedPainIndex]}
                      setWristPainInfo={handlePhysicalExamChange}
                    />
                  ) : selectedPainKey === "kneePain" ? (
                    <KneePhysicalExam
                      kneePainInfo={physicalExams[selectedPainIndex]}
                      setKneePainInfo={handlePhysicalExamChange}
                    />
                  ) : (
                    <TextField
                      multiline
                      value={physicalExams[selectedPainIndex]}
                      onChange={(e) => handlePhysicalExamChange(e.target.value)}
                      fullWidth
                    ></TextField>
                  )}
                </>
              </>
            )}
          </Stack>
        )}
        <ImagesReviewed
          imagesReviewed={imagesReviewed}
          setImagesReviewed={setImagesReviewed}
          setImagesReviewedInfo={setImagesReviewedInfo}
          pastFollowupNotes={pastFollowupNotes}
          initialImagesReviewed={initialImagesReviewed}
          dateOfPainConsultNote={dateOfPainConsultNote}
        />
        <Assessment assessment={assessment} setAssessment={setAssessment} />
        <Plan db_plans={planText} />
        <Authorization
          authorization={authorizationText}
          setAuthorization={setAuthorizationText}
        />
        <ICD10Codes icd10codes={icd10codes} setIcd10codes={setIcd10codes} />
        <CPTcodes
          db_CPTcodes={note?.cptCodes}
          note_type={FOLLOW_UP_NOTE_TYPE}
          cptCodes={cptCodes}
          setCptCodes={setCptCodes}
        />
        <Divider style={{ margin: "10px 0px" }} />
        <Stack direction="row" spacing={1} style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleCancelModalOpen}
          >
            Close
          </Button>
          {(note === null || note.status === "unsigned") && (
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          )}
          {user && user.role === ADMIN && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSignModalOpen(true)}
            >
              Sign and Save
            </Button>
          )}
        </Stack>
        <Dialog
          fullScreen={fullScreen}
          open={cancelModalOpen}
          onClose={handleCancelModalClose}
        >
          <DialogTitle id="responsive-dialog-title">
            {"Are you sure to close the follow up note?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              If you agree, you will redirect to patient page.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCancelModalClose}>
              Disagree
            </Button>
            <Button onClick={handleCancel} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={signModalOpen} onClose={handleClose} maxWidth="lg">
          <DialogTitle>Sign</DialogTitle>
          <Divider />
          <DialogContent style={{ width: "fit-content" }}>
            <div
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                width: "fit-content",
              }}
            >
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas",
                }}
                ref={(ref) => {
                  setSignPad(ref);
                }}
                onEnd={() => setSigned(true)}
              />
            </div>
          </DialogContent>
          <Divider style={{ margin: "5px 0px" }} />
          <DialogActions style={{ justifyContent: "center" }}>
            <Button onClick={handleClear} variant="contained" color="warning">
              Clear
            </Button>
            <Button onClick={handleSave} variant="contained" disabled={!signed}>
              Sign
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </>
  );
};

export default FollowUpNote;
