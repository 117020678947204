import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Stack,
  Typography,
  Input,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Save as SaveIcon, SignLanguage } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";
import { MEDICAL_BILLING_PERSON, secureApi } from "../config";
import { Context } from "../context";
import TopNav from "../components/topnav";

const useStyles = makeStyles({
  centeredInput: {
    "& input": {
      textAlign: "center",
    },
  },
  boldText: {
    fontWeight: "bold",
  },
  underlineText: {
    textDecoration: "underline",
  },
  mt0: {
    marginTop: "0",
  },
});

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 1;
const date = today.getDate();

const HipaaForm = () => {
  const classes = useStyles();
  const { patient_id } = useParams();
  const navigate = useNavigate();
  const { setLoggedIn, user } = useContext(Context);

  if (user && user.role === MEDICAL_BILLING_PERSON) {
    toast.warn("You don't have permission to access this page.");
    navigate("/");
  }

  const [patient, setPatient] = useState({});
  const [section1, setSection1] = useState("Modal Medical Services PLLC");
  const [section2check1, setSection2check1] = useState(false);
  const [section2check2, setSection2check2] = useState(false);
  const [section2check3, setSection2check3] = useState(false);
  const [section3, setSection3] = useState(
    "Billing purposes, Attorney relevant information"
  );
  const [section4name, setSection4name] = useState("");
  const [section4organization, setSection4organization] = useState(
    "Modal Medical Services PLLC"
  );
  const [section4address, setSection4address] = useState(
    "369 Lexington Ave 25h Floor, New York, NY 10016"
  );
  const [section5check1, setSection5check1] = useState(false);
  const [section5from, setSection5from] = useState("");
  const [section5to, setSection5to] = useState("");
  const [section5check2, setSection5check2] = useState(false);
  const [section5check3, setSection5check3] = useState(false);
  const [section5, setSection5] = useState("");
  const [section5name, setSection5name] = useState("");
  const [section5organization, setSection5organization] = useState(
    "Modal Medical Services PLLC"
  );
  const [section5address, setSection5address] = useState(
    "369 Lexington Ave 25th Floor, New York, NY 10016"
  );
  const [patientSignature, setPatientSignature] = useState("");
  const [patientSignatureDate, setPatientSignatureDate] = useState("");

  const [signPad, setSignPad] = useState({});
  const [signed, setSigned] = useState(false);
  const [signModalOpen, setSignModalOpen] = useState(false);

  const handleClickPatientSign = () => {
    setSignModalOpen(true);
  };
  const handleClear = () => {
    signPad.clear();
    setSigned(false);
  };

  const handleSignModalClose = () => {
    handleClear();
    setSignModalOpen(false);
  };

  const handleSign = () => {
    const physicalSign = signPad.getTrimmedCanvas().toDataURL("image/png");
    setPatientSignature(physicalSign);
    if (patientSignatureDate.trim() === "")
      setPatientSignatureDate(`${month}/${date}/${year}`);
    handleSignModalClose();
  };

  const handleSave = () => {
    secureApi(localStorage.getItem("alex-med-token"))
      .post(`/hipaa-form/${patient_id}`, {
        patientName:
          patient &&
          patient.patientInfo &&
          `${patient.patientInfo.first_name} ${patient.patientInfo.last_name}`,
        section1,
        section2check1,
        section2check2,
        section2check3,
        section3,
        section4name,
        section4organization,
        section4address,
        section5check1,
        section5from,
        section5to,
        section5check2,
        section5check3,
        section5,
        section5name,
        section5organization,
        section5address,
        patientSignature,
        patientSignatureDate,
      })
      .then(() => {
        toast.success("HIPAA form has been saved successfully!");
        navigate(`/patient/${patient_id}`);
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          toast.error(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    if (patient_id) {
      secureApi(localStorage.getItem("alex-med-token"))
        .get(`patient/${patient_id}`)
        .then((response) => {
          const { data: patient } = response;
          setPatient(patient);
          if (patient && patient.hipaaForm) {
            setSection1(patient.hipaaForm.section1);
            setSection2check1(patient.hipaaForm.section2check1);
            setSection2check2(patient.hipaaForm.section2check2);
            setSection2check3(patient.hipaaForm.section2check3);
            setSection3(patient.hipaaForm.section3);
            setSection4name(patient.hipaaForm.section4name);
            setSection4organization(patient.hipaaForm.section4organization);
            setSection4address(patient.hipaaForm.section4address);
            setSection5check1(patient.hipaaForm.section5check1);
            setSection5from(patient.hipaaForm.section5from);
            setSection5to(patient.hipaaForm.section5to);
            setSection5check2(patient.hipaaForm.section5check2);
            setSection5check3(patient.hipaaForm.section5check3);
            setSection5(patient.hipaaForm.section5);
            setSection5name(patient.hipaaForm.section5name);
            setSection5organization(patient.hipaaForm.section5organization);
            setSection5address(patient.hipaaForm.section5address);
            setPatientSignature(patient.hipaaForm.patientSignature);
            setPatientSignatureDate(patient.hipaaForm.patientSignatureDate);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else {
      toast.warn("Invalid patient ID!");
      navigate("/");
    }
  }, [patient_id]);
  return (
    <>
      <TopNav />
      <Stack direction="column" padding="30px" spacing={2}>
        <Typography
          variant="h4"
          textAlign="center"
          marginBottom="0"
          fontWeight="bold"
        >
          HIPAA Release FORM
        </Typography>
        <p>
          Please complete all sections of this HIPAA release form. If any
          sections are left blank, this form will be invalid and it will not be
          possible for your health information to be shared as requested.
        </p>
        <Stack direction="column" spacing={1}>
          <p className={[classes.boldText, classes.mt0].join(" ")}>Section I</p>
          <div className={classes.mt0}>
            {`I, `}
            {patient &&
              patient.patientInfo &&
              `${patient.patientInfo.first_name} ${patient.patientInfo.last_name}`}
            {`, give my permission for `}
            <Input
              value={section1}
              onChange={(e) => setSection1(e.target.value)}
              style={{ minWidth: "250px" }}
            />
            to share the information listed in Section II of this document with
            the person(s) or organization(s) I have specified in Section IV of
            this document.
          </div>
        </Stack>
        <Stack direction="column" spacing={1}>
          <p className={[classes.boldText, classes.mt0].join(" ")}>
            Section II - Health Information
          </p>
          <p className={classes.mt0}>
            I would like to give the above healthcare organization permission
            to:
          </p>
          <p className={classes.mt0}>Tick as appropriate</p>
          <Stack direction="row" alignItems="center" spacing={2}>
            <FormControlLabel
              control={<Checkbox />}
              checked={section2check1}
              onChange={() => setSection2check1(!section2check1)}
              label="Disclose my complete health record including, but not limited to, diagnoses,
              lab test results, treatment, and billing records for all conditions."
            />
          </Stack>
          <Stack direction="column" spacing={1}>
            <p>Form of Disclosure:</p>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FormControlLabel
                control={<Checkbox />}
                checked={section2check2}
                onChange={() => setSection2check2(!section2check2)}
                label="Electronic copy or access via a web-based portal"
              />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <FormControlLabel
                control={<Checkbox />}
                checked={section2check3}
                onChange={() => setSection2check3(!section2check3)}
                label="Hard copy"
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="column" spacing={1}>
          <p className={[classes.boldText, classes.mt0].join(" ")}>
            Section III - Reason for Disclosure
          </p>
          <p className={classes.mt0}>
            Please detail the reasons why information is being shared. If you
            are initiating the request for sharing information and do not wish
            to list the reasons for sharing, write 'at my request'.
          </p>
          <Input
            multiline
            rows={5}
            value={section3}
            onChange={(e) => setSection3(e.target.value)}
          />
        </Stack>
        <Stack direction="column" spacing={1}>
          <p className={[classes.boldText, classes.mt0].join(" ")}>
            Section IV - Who Can Receive My Health Information
          </p>
          <p>
            I give authorization for the health information detailed in section
            II of this document to be shared with the following individual(s) or
            organization(s)
          </p>
          <Stack direction="row">
            <label style={{ width: "130px" }}>Name:</label>
            <Input
              fullWidth
              value={section4name}
              onChange={(e) => setSection4name(e.target.value)}
            />
          </Stack>
          <Stack direction="row">
            <label style={{ width: "130px" }}>Organization:</label>
            <Input
              value={section4organization}
              onChange={(e) => setSection4organization(e.target.value)}
              fullWidth
            />
          </Stack>
          <Stack direction="row">
            <label style={{ width: "130px" }}>Address:</label>
            <Input
              value={section4address}
              onChange={(e) => setSection4address(e.target.value)}
              fullWidth
            />
          </Stack>
          <p>
            I understand that the person(s)/organization(s) listed above may not
            be covered by state/federal rules governing privacy and security of
            data and may be permitted to further share the information that is
            provided to them.
          </p>
        </Stack>
        <Stack direction="column" spacing={1}>
          <p className={[classes.boldText].join(" ")}>
            Section V - Duration of Authorization
          </p>
          <p>This authorization to share my health information is valid:</p>
          <p>Tick as appropriate</p>
          <Stack
            direction="row"
            style={{ marginLeft: "50px", alignItems: "center" }}
            spacing={1}
          >
            <FormControlLabel
              control={<Checkbox />}
              label={
                <>
                  {`a) From `}
                  <Input
                    value={section5from}
                    onChange={(e) => setSection5from(e.target.value)}
                  />
                  {` to `}
                  <Input
                    value={section5to}
                    onChange={(e) => setSection5to(e.target.value)}
                  />
                </>
              }
              checked={section5check1}
              onChange={() => setSection5check1(!section5check1)}
            />
          </Stack>
          <p>Or</p>
          <Stack
            direction="row"
            style={{ marginLeft: "50px", alignItems: "center" }}
            spacing={1}
          >
            <FormControlLabel
              control={<Checkbox />}
              label="b) All past, present, and future periods"
              checked={section5check2}
              onChange={() => setSection5check2(!section5check2)}
            />
          </Stack>
          <p>Or</p>
          <Stack
            direction="row"
            style={{ marginLeft: "50px", alignItems: "center" }}
            spacing={1}
          >
            <FormControlLabel
              control={<Checkbox />}
              label="c) The date of the signature in section VI until the following event:"
              checked={section5check3}
              onChange={() => setSection5check3(!section5check3)}
            />
          </Stack>
          <Input
            value={section5}
            onChange={(e) => setSection5(e.target.value)}
          />
          <p>
            I understand that I am permitted to revoke this authorization to
            share my health data at any time and can do so by submitting a
            request in writing to:
          </p>
          <Stack direction="row">
            <label style={{ width: "130px" }}>Name:</label>
            <Input
              fullWidth
              value={section5name}
              onChange={(e) => setSection5name(e.target.value)}
            />
          </Stack>
          <Stack direction="row">
            <label style={{ width: "130px" }}>Organization:</label>
            <Input
              value={section5organization}
              onChange={(e) => setSection5organization(e.target.value)}
              fullWidth
            />
          </Stack>
          <Stack direction="row">
            <label style={{ width: "130px" }}>Address:</label>
            <Input
              value={section5address}
              onChange={(e) => setSection5address(e.target.value)}
              fullWidth
            />
          </Stack>
          <p>I understand that:</p>
          <ul>
            <li>
              In the event that my information has already been shared by the
              time my authorization is revoked, it may be too late to cancel
              permission to share my health data.
            </li>
            <li>
              I understand that I do not need to give any further permission for
              the information detailed in Section II to be shared with the
              person(s) or organization(s) listed in section IV.
            </li>
            <li>
              I understand that the failure to sign/submit this authorization or
              the cancellation of this authorization will not prevent me from
              receiving any treatment or benefits I am entitled to receive,
              provided this information is not required to determine if I am
              eligible to receive those treatments or benefits or to pay for the
              services I receive.
            </li>
          </ul>
        </Stack>
        <Stack direction="column">
          <p className={[classes.boldText].join(" ")}>Section VI - Signature</p>
          <Stack direction="row" spacing={2}>
            <Stack direction="row" spacing={1} style={{ width: "33%" }}>
              <label className={[classes.mt0].join(" ")}>Signature:</label>
              {patientSignature ? (
                <div
                  style={{
                    width: "100%",
                    borderBottom: "1px solid grey",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={patientSignature}
                    alt="patient signature"
                    style={{
                      height: "27px",
                      width: "fit-content",
                      maxWidth: "80%",
                    }}
                  />
                </div>
              ) : (
                <Input
                  className={classes.centeredInput}
                  value="Not signed yet"
                  readOnly
                  fullWidth
                ></Input>
              )}
            </Stack>
            <Stack direction="row" spacing={1} style={{ width: "33%" }}>
              <label className={[classes.mt0].join(" ")}>Date:</label>
              <Input
                className={classes.centeredInput}
                value={patientSignatureDate}
                onChange={(e) => setPatientSignatureDate(e.target.value)}
                fullWidth
              ></Input>
            </Stack>
            <Stack direction="row" spacing={1} style={{ width: "34%" }}>
              <label
                className={[classes.mt0].join(" ")}
                style={{ width: "150px" }}
              >
                Patient Name:
              </label>
              <Input
                className={classes.centeredInput}
                value={
                  patient && patient.patientInfo
                    ? `${patient && patient.patientInfo.first_name} ${
                        patient && patient.patientInfo.last_name
                      }`
                    : ""
                }
                readOnly
                fullWidth
              ></Input>
            </Stack>
          </Stack>
        </Stack>
        <Divider style={{ margin: "10px 0" }} />
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Button
            variant="contained"
            color="error"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<SignLanguage />}
            onClick={handleClickPatientSign}
          >
            Patient Sign
          </Button>
          <Button
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </Stack>
      <Dialog open={signModalOpen} onClose={handleSignModalClose} maxWidth="lg">
        <DialogTitle>Patient Sign</DialogTitle>
        <Divider />
        <DialogContent style={{ width: "fit-content" }}>
          <div
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              width: "fit-content",
            }}
          >
            <SignatureCanvas
              penColor="black"
              canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
              ref={(ref) => {
                setSignPad(ref);
              }}
              onEnd={() => setSigned(true)}
            />
          </div>
        </DialogContent>
        <Divider style={{ margin: "5px 0px" }} />
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClear} variant="contained" color="warning">
            Clear
          </Button>
          <Button onClick={handleSign} variant="contained" disabled={!signed}>
            Sign
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HipaaForm;
