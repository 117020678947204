import React from "react";
import {
  Stack,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const ShoulderPhysicalExam = ({ shoulderPainInfo, setShoulderPainInfo }) => {
  return (
    <Stack spacing={3}>
      {/* Laterality Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }}>
          <Typography variant="h6">Laterality</Typography>
          <RadioGroup
            value={shoulderPainInfo.laterality}
            onChange={(e) =>
              setShoulderPainInfo({
                ...shoulderPainInfo,
                laterality: e.target.value,
              })
            }
          >
            <FormControlLabel
              value="Right shoulder"
              control={<Radio />}
              label="Right"
            />
            <FormControlLabel
              value="Left shoulder"
              control={<Radio />}
              label="Left"
            />
            <FormControlLabel
              value="Bilateral shoulders"
              control={<Radio />}
              label="Bilateral"
            />
          </RadioGroup>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Laterality:
          </Typography>
          <Typography variant="h6">{shoulderPainInfo.laterality}</Typography>
        </Stack>
      </Stack>

      {/* Inspection Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Apperance</Typography>
            <RadioGroup
              value={shoulderPainInfo.appearance}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  appearance: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No visible abnormality"
                control={<Radio />}
                label="No visible abnormality"
              />
              <FormControlLabel
                value="Swelling"
                control={<Radio />}
                label="Swelling"
              />
              <FormControlLabel
                value="Deformity"
                control={<Radio />}
                label="Deformity"
              />
              <FormControlLabel
                value="Muscle atrophy"
                control={<Radio />}
                label="Muscle atrophy"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Skin</Typography>
            <RadioGroup
              value={shoulderPainInfo.skin}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  skin: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal"
                control={<Radio />}
                label="Normal"
              />
              <FormControlLabel
                value="Erythema"
                control={<Radio />}
                label="Erythema"
              />
              <FormControlLabel
                value="Ecchymosis"
                control={<Radio />}
                label="Ecchymosis"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Shoulder Inspection:
          </Typography>
          <Typography variant="h6">
            Appearance: {shoulderPainInfo.appearance}
          </Typography>
          <Typography variant="h6">Skin: {shoulderPainInfo.skin}</Typography>
        </Stack>
      </Stack>

      {/* Palpation section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Bony Landmarks</Typography>
            <RadioGroup
              value={shoulderPainInfo.bonyLandmarks}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  bonyLandmarks: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No tenderness"
                control={<Radio />}
                label="No tenderness"
              />
              <FormControlLabel
                value="Tenderness at acromioclavicular joint"
                control={<Radio />}
                label="Tenderness at acromioclavicular joint"
              />
              <FormControlLabel
                value="Tenderness at sternoclavicular joint"
                control={<Radio />}
                label="Tenderness at sternoclavicular joint"
              />
              <FormControlLabel
                value="Tenderness at greater tuberosity"
                control={<Radio />}
                label="Tenderness at greater tuberosity"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Soft Tissue</Typography>
            <RadioGroup
              value={shoulderPainInfo.softTissue}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  softTissue: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No tenderness"
                control={<Radio />}
                label="No tenderness"
              />
              <FormControlLabel
                value="Tenderness over deltoid muscle"
                control={<Radio />}
                label="Tenderness over deltoid muscle"
              />
              <FormControlLabel
                value="Tenderness in bicipital groove"
                control={<Radio />}
                label="Tenderness in bicipital groove"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Shoulder Palpation:
          </Typography>
          <Typography variant="h6">
            Bony landmarks: {shoulderPainInfo.bonyLandmarks}
          </Typography>
          <Typography variant="h6">
            Soft tissue: {shoulderPainInfo.softTissue}
          </Typography>
        </Stack>
      </Stack>

      {/* Range of Motion Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Active</Typography>
            <RadioGroup
              value={shoulderPainInfo.active}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  active: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Full range without pain"
                control={<Radio />}
                label="Full range without pain"
              />
              <FormControlLabel
                value="Pain with abduction"
                control={<Radio />}
                label="Pain with abduction"
              />
              <FormControlLabel
                value="Pain with flexion"
                control={<Radio />}
                label="Pain with flexion"
              />
              <FormControlLabel
                value="Pain with internal rotation"
                control={<Radio />}
                label="Pain with internal rotation"
              />
              <FormControlLabel
                value="Pain with external rotation"
                control={<Radio />}
                label="Pain with external rotation"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Passive</Typography>
            <RadioGroup
              value={shoulderPainInfo.passive}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  passive: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Full range without pain"
                control={<Radio />}
                label="Full range without pain"
              />
              <FormControlLabel
                value="Limited by pain"
                control={<Radio />}
                label="Limited by pain"
              />
              <FormControlLabel
                value="Crepitus"
                control={<Radio />}
                label="Crepitus"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Range of Motion:
          </Typography>
          <Typography variant="h6">
            Active: {shoulderPainInfo.active}
          </Typography>
          <Typography variant="h6">
            Passive: {shoulderPainInfo.passive}
          </Typography>
        </Stack>
      </Stack>

      {/* Strength Testing Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Deltoid Muscle</Typography>
            <RadioGroup
              value={shoulderPainInfo.deltoidMuscle}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  deltoidMuscle: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal strength"
                control={<Radio />}
                label="Normal strength"
              />
              <FormControlLabel
                value="Weakness"
                control={<Radio />}
                label="Weakness"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Rotator Cuff Muscles</Typography>
            <RadioGroup
              value={shoulderPainInfo.rotatorCuffMuscles}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  rotatorCuffMuscles: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal strength"
                control={<Radio />}
                label="Normal strength"
              />
              <FormControlLabel
                value="Supraspinatus weakness"
                control={<Radio />}
                label="Supraspinatus weakness (empty can test)"
              />
              <FormControlLabel
                value="Infraspinatus weakness"
                control={<Radio />}
                label="Infraspinatus weakness (external rotation)"
              />
              <FormControlLabel
                value="Subscapularis weakness"
                control={<Radio />}
                label="Subscapularis weakness (lift-off test)"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Strength Testing:
          </Typography>
          <Typography variant="h6">
            Deltoid Muscle: {shoulderPainInfo.deltoidMuscle}
          </Typography>
          <Typography variant="h6">
            Rotator Cuff Muscles: {shoulderPainInfo.rotatorCuffMuscles}
          </Typography>
        </Stack>
      </Stack>

      {/* Special Tests Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Impingement Signs</Typography>
            <RadioGroup
              value={shoulderPainInfo.impingementSigns}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  impingementSigns: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Positive Neer's sign"
                control={<Radio />}
                label="Positive Neer's sign"
              />
              <FormControlLabel
                value="Positive Hawkins-Kennedy sign"
                control={<Radio />}
                label="Positive Hawkins-Kennedy sign"
              />
              <FormControlLabel
                value="Negative"
                control={<Radio />}
                label="Negative"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Labral Tears</Typography>
            <RadioGroup
              value={shoulderPainInfo.labralTears}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  labralTears: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Positive O'Brien's test"
                control={<Radio />}
                label="Positive O'Brien's test"
              />
              <FormControlLabel
                value="Positive Speed's test"
                control={<Radio />}
                label="Positive Speed's test"
              />
              <FormControlLabel
                value="Negative"
                control={<Radio />}
                label="Negative"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Instability</Typography>
            <RadioGroup
              value={shoulderPainInfo.instability}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  instability: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Positive apprehension sign"
                control={<Radio />}
                label="Positive apprehension sign"
              />
              <FormControlLabel
                value="Positive relocation test"
                control={<Radio />}
                label="Positive relocation test"
              />
              <FormControlLabel
                value="Negative"
                control={<Radio />}
                label="Negative"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Special Tests:
          </Typography>
          <Typography variant="h6">
            Impingement Signs: {shoulderPainInfo.impingementSigns}
          </Typography>
          <Typography variant="h6">
            Labral Tears: {shoulderPainInfo.labralTears}
          </Typography>
          <Typography variant="h6">
            Instability: {shoulderPainInfo.instability}
          </Typography>
        </Stack>
      </Stack>

      {/* Neurovascular Assessment */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Sensation</Typography>
            <RadioGroup
              value={shoulderPainInfo.sensation}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  sensation: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Intact"
                control={<Radio />}
                label="Intact"
              />
              <FormControlLabel
                value="Decreased over deltoid area (axillary nerve)"
                control={<Radio />}
                label="Decreased over deltoid area (axillary nerve)"
              />
              <FormControlLabel
                value="Decreased over lateral arm (radial nerve)"
                control={<Radio />}
                label="Decreased over lateral arm (radial nerve)"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Pulses</Typography>
            <RadioGroup
              value={shoulderPainInfo.pulses}
              onChange={(e) =>
                setShoulderPainInfo({
                  ...shoulderPainInfo,
                  pulses: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Intact brachial pulse"
                control={<Radio />}
                label="Intact brachial pulse"
              />
              <FormControlLabel
                value="Intact radial pulse"
                control={<Radio />}
                label="Intact radial pulse"
              />
              <FormControlLabel
                value="Intact ulnar pulse"
                control={<Radio />}
                label="Intact ulnar pulse"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Neurovascular Assessment:
          </Typography>
          <Typography variant="h6">
            Sensation: {shoulderPainInfo.sensation}
          </Typography>
          <Typography variant="h6">
            Pulses: {shoulderPainInfo.pulses}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ShoulderPhysicalExam;
