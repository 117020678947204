import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  IconButton,
  TableRow,
  TableCell,
  ButtonGroup,
  Collapse,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from "@mui/icons-material";

const DateBillingTable = ({
  billing,
  billingID,
  handleEditModalOpen,
  handleDeleteModalOpen,
  dateOfServiceIdx,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
            background: theme.palette.action.hover,
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {billing.insurance}
        </TableCell>
        <TableCell align="right">{billing.status}</TableCell>
        <TableCell align="right">{billing.date_of_service}</TableCell>
        <TableCell align="right">{billing.cost_share_paid}</TableCell>
        <TableCell align="right">{billing.date_billed}</TableCell>
        <TableCell align="right">{billing.total_charges}</TableCell>
        <TableCell align="right">{billing.total_receipts_by_mail}</TableCell>
        <TableCell align="right">{billing.total_receipts_by_tebra}</TableCell>
        <TableCell align="right">{billing.receipt_date}</TableCell>
        <TableCell align="right">{billing.total_balance}</TableCell>
        <TableCell align="right">{billing.sent_to_patient}</TableCell>
        <TableCell align="right">{billing.collected_from_patient}</TableCell>
        <TableCell align="right">{billing.appeal_needed}</TableCell>
        <TableCell align="right">{billing.appeal_date}</TableCell>
        <TableCell align="right">{billing.comments}</TableCell>
        <TableCell align="right">
          <ButtonGroup size="small">
            <IconButton
              variant="contained"
              color="warning"
              onClick={() =>
                handleEditModalOpen(billing, billingID, dateOfServiceIdx, 0, 1)
              }
            >
              <EditIcon />
            </IconButton>
            <IconButton
              variant="contained"
              color="error"
              onClick={() =>
                handleDeleteModalOpen(billingID, dateOfServiceIdx, 0, 1)
              }
            >
              <DeleteIcon />
            </IconButton>
          </ButtonGroup>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={17}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Billing for a date of service
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={{ background: "#ceffda" }}>
                    <TableCell>Procedure</TableCell>
                    <TableCell>Mod</TableCell>
                    <TableCell align="right">Diag 1</TableCell>
                    <TableCell align="right">Diag 2</TableCell>
                    <TableCell align="right">Charges</TableCell>
                    <TableCell align="right">Receipts By Mail</TableCell>
                    <TableCell align="right">Receipts By Tebra</TableCell>
                    <TableCell align="right">Balance</TableCell>
                    <TableCell align="right">Comments</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {billing.details.length > 0 &&
                    billing.details.map((detail, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {detail.procedure}
                        </TableCell>
                        <TableCell>{detail.mod}</TableCell>
                        <TableCell align="right">{detail.diag1}</TableCell>
                        <TableCell align="right">{detail.diag2}</TableCell>
                        <TableCell align="right">{detail.charges}</TableCell>
                        <TableCell align="right">
                          {detail.receipts_by_mail}
                        </TableCell>
                        <TableCell align="right">
                          {detail.receipts_by_tebra}
                        </TableCell>
                        <TableCell align="right">{detail.balance}</TableCell>
                        <TableCell align="right">{detail.comments}</TableCell>
                        <TableCell align="right">
                          <ButtonGroup size="small">
                            <IconButton
                              variant="contained"
                              color="warning"
                              onClick={() =>
                                handleEditModalOpen(
                                  detail,
                                  billingID,
                                  dateOfServiceIdx,
                                  index,
                                  2
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              variant="contained"
                              color="error"
                              onClick={() =>
                                handleDeleteModalOpen(
                                  billingID,
                                  dateOfServiceIdx,
                                  index,
                                  2
                                )
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                  {billing.details.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={10} sx={{ textAlign: "center" }}>
                        No Billing Information
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DateBillingTable;
