import React, { useContext, useEffect, useState } from 'react';
import TopNav from '../components/topnav';
import {
  Stack,
  Paper,
  TextField,
  Button,
  Typography
} from '@mui/material';
import { secureApi } from '../config';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context';

const ChangePassword = () => {
  const { user, setLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
    if (e.target.value)
      setCurrentPasswordError(false);
    else
      setCurrentPasswordError(true);
  }

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value) {
      setNewPasswordError(false);
    }
    else
      setNewPasswordError(true);
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value)
      setConfirmPasswordError(false);
    else
      setConfirmPasswordError(true);
  }

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setNewPasswordError(true);
      setConfirmPasswordError(true);
    } else {
      setNewPasswordError(false);
      setConfirmPasswordError(false);
    }
  }, [newPassword, confirmPassword]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!currentPassword)
      setCurrentPasswordError(true);
    if (!newPassword)
      setNewPasswordError(true);
    if (!confirmPassword)
      setConfirmPasswordError(true);
    if (newPassword !== confirmPassword) {
      setNewPasswordError(true);
      setConfirmPasswordError(true);
    }
    if (currentPassword && newPassword && confirmPassword && newPassword === confirmPassword) {
      const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
      if (regex.test(newPassword))
        secureApi(window.localStorage.getItem('alex-med-token')).post('/auth/change-password', {
          email: user.email,
          currentPassword,
          newPassword
        }).then(() => {
          toast.success('Password has been changed successfully!');
          navigate('/');
        }).catch(e => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn('Session has been expired. You need to login again!');
            setLoggedIn(false);
            navigate('/login');
          } else {
            toast.error(e.response.data.message);
          }
        });
      else
        toast.warn("Password should include at least 8 characters with at least one number, uppercase letter and special character.")
    }
  }

  return (
    <>
      <TopNav />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper style={{ minWidth: '400px', maxWidth: '400px', padding: '50px', marginTop: '100px' }}>
          <form onSubmit={handleSubmit}>
            <Stack direction='column' spacing={2}>
              <Typography variant='h4' style={{ textAlign: 'center' }}>Change Password</Typography>
              <TextField label="Current Password" type="password" name="current_password" value={currentPassword} onChange={handleCurrentPasswordChange} error={currentPasswordError}></TextField>
              <TextField label="New Password" type="password" name="new_password" value={newPassword} onChange={handleNewPasswordChange} error={newPasswordError}></TextField>
              <TextField label="Confirm Password" type="password" name="confirm_password" value={confirmPassword} onChange={handleConfirmPasswordChange} error={confirmPasswordError}></TextField>
              <Button type="submit" variant='contained' color='primary'>Submit</Button>
            </Stack>
          </form>
        </Paper>
      </div>
    </>
  );
}

export default ChangePassword;