import React, { useContext, useState } from "react";
import {
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormGroup,
} from "@mui/material";
import { Context } from "../../context";
import { ADMIN } from "../../config";

const Assessment = ({ assessment, setAssessment }) => {
  const { user } = useContext(Context);
  const [content, setContent] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setContent(assessment);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleUpdate = () => {
    setAssessment(content);
    setOpen(false);
  };

  return (
    <Stack direction="column">
      <Typography
        variant="h5"
        style={{ fontWeight: "bold", textDecoration: "underline" }}
      >
        ASSESSMENT/ PLAN:{" "}
      </Typography>
      <Stack direction="row">
        {user && user.role === ADMIN && (
          <FormGroup
            style={{
              minWidth: "250px",
              marginRight: "10px",
              marginTop: "10px",
            }}
          >
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Edit
            </Button>
          </FormGroup>
        )}
        <Stack direction="column">
          {assessment.split("\n").map((item, index) => (
            <Typography variant="h6" key={index}>
              {item}
            </Typography>
          ))}
        </Stack>
      </Stack>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Edit assessment</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Content"
            multiline
            rows={7}
            fullWidth
            variant="standard"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdate}>OK</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Assessment;
