import React from "react";
import {
  Stack,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const WristPhysicalExam = ({ wristPainInfo, setWristPainInfo }) => {
  return (
    <Stack spacing={3}>
      {/* Laterality Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }}>
          <Typography variant="h6">Laterality</Typography>
          <RadioGroup
            value={wristPainInfo.laterality}
            onChange={(e) =>
              setWristPainInfo({
                ...wristPainInfo,
                laterality: e.target.value,
              })
            }
          >
            <FormControlLabel
              value="Right wrist"
              control={<Radio />}
              label="Right"
            />
            <FormControlLabel
              value="Left wrist"
              control={<Radio />}
              label="Left"
            />
            <FormControlLabel
              value="Bilateral wrist"
              control={<Radio />}
              label="Bilateral"
            />
          </RadioGroup>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Laterality:
          </Typography>
          <Typography variant="h6">{wristPainInfo.laterality}</Typography>
        </Stack>
      </Stack>

      {/* Inspection Section */}
      <Stack direction="row" spacing={5}>
        <Stack spacing={3} sx={{ width: "30%" }}>
          <Stack>
            <Typography variant="h6">Appearance</Typography>
            <RadioGroup
              value={wristPainInfo.appearance}
              onChange={(e) =>
                setWristPainInfo({
                  ...wristPainInfo,
                  appearance: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No visible abnormality"
                control={<Radio />}
                label="No visible abnormality"
              />
              <FormControlLabel
                value="Swelling"
                control={<Radio />}
                label="Swelling"
              />
              <FormControlLabel
                value="Deformity"
                control={<Radio />}
                label="Deformity"
              />
              <FormControlLabel
                value="Redness"
                control={<Radio />}
                label="Redness"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Skin</Typography>
            <RadioGroup
              value={wristPainInfo.skin}
              onChange={(e) =>
                setWristPainInfo({ ...wristPainInfo, skin: e.target.value })
              }
            >
              <FormControlLabel
                value="Normal"
                control={<Radio />}
                label="Normal"
              />
              <FormControlLabel value="Rash" control={<Radio />} label="Rash" />
              <FormControlLabel
                value="Bruising"
                control={<Radio />}
                label="Bruising"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Wrist Inspection:
          </Typography>
          <Typography variant="h6">
            Appearance: {wristPainInfo.appearance}
          </Typography>
          <Typography variant="h6">Skin: {wristPainInfo.skin}</Typography>
        </Stack>
      </Stack>

      {/* Palpation section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Bony Landmarks</Typography>
            <RadioGroup
              value={wristPainInfo.bonyLandmarks}
              onChange={(e) =>
                setWristPainInfo({
                  ...wristPainInfo,
                  bonyLandmarks: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No tenderness"
                control={<Radio />}
                label="No tenderness"
              />
              <FormControlLabel
                value="Tenderness at radial styloid"
                control={<Radio />}
                label="Tenderness at radial styloid"
              />
              <FormControlLabel
                value="Tenderness at ulnar styloid"
                control={<Radio />}
                label="Tenderness at ulnar styloid"
              />
              <FormControlLabel
                value="Tenderness at carpal bones"
                control={<Radio />}
                label="Tenderness at carpal bones"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Soft Tissue</Typography>
            <RadioGroup
              value={wristPainInfo.softTissue}
              onChange={(e) =>
                setWristPainInfo({
                  ...wristPainInfo,
                  softTissue: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No tenderness"
                control={<Radio />}
                label="No tenderness"
              />
              <FormControlLabel
                value="Tenderness over flexor tendons"
                control={<Radio />}
                label="Tenderness over flexor tendons"
              />
              <FormControlLabel
                value="Tenderness over extensor tendons"
                control={<Radio />}
                label="Tenderness over extensor tendons"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Wrist Palpation:
          </Typography>
          <Typography variant="h6">
            Bony landmarks: {wristPainInfo.bonyLandmarks}
          </Typography>
          <Typography variant="h6">
            Soft tissue: {wristPainInfo.softTissue}
          </Typography>
        </Stack>
      </Stack>

      {/* Range of Motion Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Active</Typography>
            <RadioGroup
              value={wristPainInfo.active}
              onChange={(e) =>
                setWristPainInfo({ ...wristPainInfo, active: e.target.value })
              }
            >
              <FormControlLabel
                value="Full range without pain"
                control={<Radio />}
                label="Full range without pain"
              />
              <FormControlLabel
                value="Pain with flexion"
                control={<Radio />}
                label="Pain with flexion"
              />
              <FormControlLabel
                value="Pain with extension"
                control={<Radio />}
                label="Pain with extension"
              />
              <FormControlLabel
                value="Pain with radial deviation"
                control={<Radio />}
                label="Pain with radial deviation"
              />
              <FormControlLabel
                value="Pain with ulnar deviation"
                control={<Radio />}
                label="Pain with ulnar deviation"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Passive</Typography>
            <RadioGroup
              value={wristPainInfo.passive}
              onChange={(e) =>
                setWristPainInfo({ ...wristPainInfo, passive: e.target.value })
              }
            >
              <FormControlLabel
                value="Full range without pain"
                control={<Radio />}
                label="Full range without pain"
              />
              <FormControlLabel
                value="Limited by pain"
                control={<Radio />}
                label="Limited by pain"
              />
              <FormControlLabel
                value="Crepitus"
                control={<Radio />}
                label="Crepitus"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Range of Motion:
          </Typography>
          <Typography variant="h6">Active: {wristPainInfo.active}</Typography>
          <Typography variant="h6">Passive: {wristPainInfo.passive}</Typography>
        </Stack>
      </Stack>

      {/* Strength Testing Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Grip Strength</Typography>
            <RadioGroup
              value={wristPainInfo.gripStrength}
              onChange={(e) =>
                setWristPainInfo({
                  ...wristPainInfo,
                  gripStrength: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal strength"
                control={<Radio />}
                label="Normal strength"
              />
              <FormControlLabel
                value="Weakness"
                control={<Radio />}
                label="Weakness"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Wrist Extensors</Typography>
            <RadioGroup
              value={wristPainInfo.wristExtensors}
              onChange={(e) =>
                setWristPainInfo({
                  ...wristPainInfo,
                  wristExtensors: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal strength"
                control={<Radio />}
                label="Normal strength"
              />
              <FormControlLabel
                value="Weakness"
                control={<Radio />}
                label="Weakness"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Wrist Flexors</Typography>
            <RadioGroup
              value={wristPainInfo.wristFlexors}
              onChange={(e) =>
                setWristPainInfo({
                  ...wristPainInfo,
                  wristFlexors: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal strength"
                control={<Radio />}
                label="Normal strength"
              />
              <FormControlLabel
                value="Weakness"
                control={<Radio />}
                label="Weakness"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Strength Testing:
          </Typography>
          <Typography variant="h6">
            Grip Strength: {wristPainInfo.gripStrength}
          </Typography>
          <Typography variant="h6">
            Wrist Extensors: {wristPainInfo.wristExtensors}
          </Typography>
          <Typography variant="h6">
            Wrist Flexors: {wristPainInfo.wristFlexors}
          </Typography>
        </Stack>
      </Stack>

      {/* Special Tests Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">De Quervain's Tenosynovitis</Typography>
            <RadioGroup
              value={wristPainInfo.deQuervainsTenosynovitis}
              onChange={(e) =>
                setWristPainInfo({
                  ...wristPainInfo,
                  deQuervainsTenosynovitis: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Positive Finkelstein's test"
                control={<Radio />}
                label="Positive Finkelstein's test"
              />
              <FormControlLabel
                value="Negative"
                control={<Radio />}
                label="Negative"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Carpal Tunnel Syndrome</Typography>
            <RadioGroup
              value={wristPainInfo.carpalTunnelSyndrome}
              onChange={(e) =>
                setWristPainInfo({
                  ...wristPainInfo,
                  carpalTunnelSyndrome: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Positive Tinel's sign at wrist"
                control={<Radio />}
                label="Positive Tinel's sign at wrist"
              />
              <FormControlLabel
                value="Positive Phalen's test"
                control={<Radio />}
                label="Positive Phalen's test"
              />
              <FormControlLabel
                value="Negative"
                control={<Radio />}
                label="Negative"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Special Tests:
          </Typography>
          <Typography variant="h6">
            De Quervain's Tenosynovitis:
            {wristPainInfo.deQuervainsTenosynovitis}
          </Typography>
          <Typography variant="h6">
            Carpal Tunnel Syndrome: {wristPainInfo.carpalTunnelSyndrome}
          </Typography>
        </Stack>
      </Stack>

      {/* Neurovascular Assessment */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Sensation</Typography>
            <RadioGroup
              value={wristPainInfo.sensation}
              onChange={(e) =>
                setWristPainInfo({
                  ...wristPainInfo,
                  sensation: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Intact"
                control={<Radio />}
                label="Intact"
              />
              <FormControlLabel
                value="Decreased in median nerve distribution"
                control={<Radio />}
                label="Decreased in median nerve distribution"
              />
              <FormControlLabel
                value="Decreased in ulnar nerve distribution"
                control={<Radio />}
                label="Decreased in ulnar nerve distribution"
              />
              <FormControlLabel
                value="Decreased in radial nerve distribution"
                control={<Radio />}
                label="Decreased in radial nerve distribution"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Pulses</Typography>
            <RadioGroup
              value={wristPainInfo.pulses}
              onChange={(e) =>
                setWristPainInfo({ ...wristPainInfo, pulses: e.target.value })
              }
            >
              <FormControlLabel
                value="Intact brachial pulse"
                control={<Radio />}
                label="Intact brachial pulse"
              />
              <FormControlLabel
                value="Intact radial pulse"
                control={<Radio />}
                label="Intact radial pulse"
              />
              <FormControlLabel
                value="Intact ulnar pulse"
                control={<Radio />}
                label="Intact ulnar pulse"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Neurovascular Assessment:
          </Typography>
          <Typography variant="h6">
            Sensation: {wristPainInfo.sensation}
          </Typography>
          <Typography variant="h6">Pulses: {wristPainInfo.pulses}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default WristPhysicalExam;
