import React, { useContext, useEffect, useState } from "react";
import {
  FormGroup,
  Stack,
  Typography,
  Switch,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { Context } from "../../context";

const Assessment = ({ patientName, dateOfBirth, gender, db_assessment }) => {
  const { muscleStrain, setMuscleStrain, assessment, setAssessment } =
    useContext(Context);
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState("");

  useEffect(() => {
    if (patientName && dateOfBirth && gender) {
      const birthday = new Date(dateOfBirth);
      const today = new Date();
      const age = today.getFullYear() - birthday.getFullYear();

      setAssessment(`${patientName} is a ${age}-year-old ${gender[0]}`);
    }
  }, [patientName, dateOfBirth, gender]);

  useEffect(() => {
    if (db_assessment) {
      setTimeout(() => {
        setAssessment(db_assessment);
      }, [200]);
    }
  }, [db_assessment]);

  const handleChange = (e) => {
    setMuscleStrain(e.target.checked);
    if (e.target.checked)
      setAssessment(
        `${assessment}\n\nAdditionally, the patient has myofascial pain syndrome with multiple active trigger points contributing to the pain profile.`
      );
  };

  const handleOpen = () => {
    setContent(assessment);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleUpdate = () => {
    setAssessment(content);
    setOpen(false);
  };

  return (
    <Stack direction="row">
      <FormGroup style={{ minWidth: "250px", marginRight: "10px" }}>
        <Typography variant="h6">Has muscle strain?</Typography>
        <Switch
          checked={muscleStrain}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Edit
        </Button>
      </FormGroup>
      <Stack direction="column">
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          ASSESSMENT/ PLAN:
        </Typography>
        {assessment.split("\n").map((item, index) => (
          <Typography variant="h6" key={index}>
            {item}
          </Typography>
        ))}
      </Stack>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Edit assessment</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Content"
            multiline
            rows={7}
            fullWidth
            variant="standard"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdate}>OK</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Assessment;
