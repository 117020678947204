import React, { useContext } from "react";
import { Paper, Input, Typography, Stack } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Context } from "../../context";
import { MEDICAL_BILLING_PERSON } from "../../config";

const PatientInfoEditInput = ({
  name,
  value,
  updatePatientInfo,
  painConsultNoteSigned,
}) => {
  const { user } = useContext(Context);
  return (
    <Input
      variant="filled"
      color="primary"
      size="medium"
      fullWidth
      value={value}
      name={name}
      onChange={(e) => updatePatientInfo(e)}
      readOnly={
        (user && user.role === MEDICAL_BILLING_PERSON) || painConsultNoteSigned
      }
    />
  );
};

const ConditionRelatedTo = ({
  conditionRelatedTo,
  setConditionRelatedTo,
  painConsultNoteSigned,
}) => {
  const { user } = useContext(Context);
  const updateConditionRelatedTo = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setConditionRelatedTo((values) => ({ ...values, [name]: value }));
  };

  const handleDateOfFirstVisitChange = (date) => {
    setConditionRelatedTo({
      ...conditionRelatedTo,
      date_of_first_visit: date,
    });
  };

  return (
    <Paper style={{ padding: "20px", boxSizing: "border-box", width: "50%" }}>
      <Typography variant="h6" fontWeight="bold" marginBottom="10px">
        Condition Related To:
      </Typography>
      <Stack direction="column" spacing={1}>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Accident Type
          </Typography>
          <PatientInfoEditInput
            name="accident_type"
            value={conditionRelatedTo.accident_type}
            updatePatientInfo={updateConditionRelatedTo}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
        <Stack direction="row">
          <Typography variant="h6" width="33%">
            Date of First Visit
          </Typography>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            style={{ width: "100%" }}
          >
            <DatePicker
              onChange={handleDateOfFirstVisitChange}
              value={conditionRelatedTo.date_of_first_visit}
              size="small"
              style={{ width: "100%" }}
              disabled={
                (user && user.role === MEDICAL_BILLING_PERSON) ||
                painConsultNoteSigned
              }
            />
          </LocalizationProvider>
        </Stack>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            NF3(1st Bill)
          </Typography>
          <PatientInfoEditInput
            name="nf3"
            value={conditionRelatedTo.nf3}
            updatePatientInfo={updateConditionRelatedTo}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            NF2
          </Typography>
          <PatientInfoEditInput
            name="nf2"
            value={conditionRelatedTo.nf2}
            updatePatientInfo={updateConditionRelatedTo}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ConditionRelatedTo;
