import React, { useEffect, useState } from 'react';
import {
  Stack,
  Paper,
  TextField,
  Button,
  Typography
} from '@mui/material';
import { api } from '../config';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';

const ResetPassword = ({ item }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const reset_token = searchParams.get('reset_token');
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (e.target.value) {
      setNewPasswordError(false);
    }
    else
      setNewPasswordError(true);
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value)
      setConfirmPasswordError(false);
    else
      setConfirmPasswordError(true);
  }

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      setNewPasswordError(true);
      setConfirmPasswordError(true);
    } else {
      setNewPasswordError(false);
      setConfirmPasswordError(false);
    }
  }, [newPassword, confirmPassword]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newPassword)
      setNewPasswordError(true);
    if (!confirmPassword)
      setConfirmPasswordError(true);
    if (newPassword !== confirmPassword) {
      setNewPasswordError(true);
      setConfirmPasswordError(true);
    }
    if (newPassword && confirmPassword && newPassword === confirmPassword) {
      const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
      if (regex.test(newPassword))
        api.post('/auth/reset-password', {
          email,
          reset_token,
          password: newPassword
        }).then(response => {
          window.localStorage.setItem('alex-med-token', response.data.token);
          if (item === 'reset')
            toast.success('Password has been reset successfully!');
          else
            toast.success('Congratulations! Your account has been activated successfully!');
          navigate('/login');
        }).catch(e => {
          console.log(e);
          toast.error(e.response.data.message);
        });
      else
        toast.warn("Password should include at least 8 characters with at least one number, uppercase letter and special character.")
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper style={{ minWidth: '400px', maxWidth: '400px', padding: '50px' }}>
        <form onSubmit={handleSubmit}>
          <Stack direction='column' spacing={2}>
            <Typography variant='h4' style={{ textAlign: 'center' }}>
              {
                item === 'reset' ? 'Reset Password' : 'Set your password'
              }
            </Typography>
            <TextField label="Password" type="password" name="new_password" value={newPassword} onChange={handleNewPasswordChange} error={newPasswordError}></TextField>
            <TextField label="Confirm Password" type="password" name="confirm_password" value={confirmPassword} onChange={handleConfirmPasswordChange} error={confirmPasswordError}></TextField>
            <Button type="submit" variant='contained' color='primary'>Submit</Button>
          </Stack>
        </form>
      </Paper>
    </div>
  );
}

export default ResetPassword;