import React from "react";
import { Stack, Typography } from "@mui/material";

const Medications = ({ medications }) => {
  return (
    <Stack direction="column">
      <Typography variant="h5" fontWeight="bold">
        Medications:
      </Typography>
      {medications.length > 0 ? (
        <Typography variant="h6">{medications.join(", ")}</Typography>
      ) : (
        <Typography variant="h6">None</Typography>
      )}
    </Stack>
  );
};

export default Medications;
