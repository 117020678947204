import React, { useContext, useEffect, useState } from "react";
import {
  Stack,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import { secureApi } from "../../config";
import { Context } from "../../context";
import { useNavigate } from "react-router-dom";

const Plan = ({ db_plans }) => {
  const navigate = useNavigate();
  const { plans, setPlans, planText, setPlanText, setLoggedIn } =
    useContext(Context);
  const [content, setContent] = useState("");
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [keyError, setKeyError] = useState(false);
  const [valueError, setValueError] = useState(false);
  const [currentPlanID, setCurrentPlanID] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editPlanModalOpen, setEditPlanModalOpen] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [deletePlanModalOpen, setDeletePlanModalOpen] = useState(false);
  const [filterStr, setFilterStr] = useState("");
  const [filteredPlans, setFilteredPlans] = useState([]);

  useEffect(() => {
    setPlanText(db_plans ? db_plans : "");
  }, [db_plans]);

  useEffect(() => {
    if (filterStr) {
      let newPlans = plans.filter((plan) =>
        plan.key.toLowerCase().includes(filterStr.toLowerCase())
      );
      setFilteredPlans(newPlans);
    } else setFilteredPlans(plans);
  }, [plans, filterStr]);

  const handleClickPlan = (event, index) => {
    if (planText) setPlanText(`${planText}\n${filteredPlans[index]["value"]}`);
    else setPlanText(`${filteredPlans[index]["value"]}`);
  };

  const handleEditModalOpen = () => {
    setContent(planText);
    setEditModalOpen(true);
  };

  const handleEditModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setEditModalOpen(false);
    }
  };

  const handleUpdatePlanText = () => {
    setPlanText(content);
    setEditModalOpen(false);
  };

  const handleAddModalOpen = () => {
    setAddModalOpen(true);
  };

  const handleAddModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setKey("");
      setValue("");
      setKeyError(false);
      setValueError(false);
      setAddModalOpen(false);
    }
  };

  const handleAddPlan = () => {
    setKeyError(key ? false : true);
    setValueError(value ? false : true);

    if (key && value) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .post(`/plan`, {
          key,
          value,
        })
        .then((response) => {
          setPlans([
            ...plans,
            {
              _id: response.data["plan_id"],
              key,
              value,
            },
          ]);
          toast.success("New plan has been created successfully!");
          handleAddModalClose();
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
  };

  const handleEditPlanModalOpen = (e, plan) => {
    setCurrentPlanID(plan["_id"]);
    setKey(plan["key"]);
    setValue(plan["value"]);
    setEditPlanModalOpen(true);
  };

  const handleEditPlanModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setKey("");
      setValue("");
      setKeyError(false);
      setValueError(false);
      setEditPlanModalOpen(false);
    }
  };

  const handleUpdatePlan = () => {
    if (currentPlanID) {
      if (key && value) {
        secureApi(window.localStorage.getItem("alex-med-token"))
          .put(`/plan/${currentPlanID}`, {
            key,
            value,
          })
          .then((response) => {
            let newPlans = [...plans];
            for (let i = 0; i < plans.length; i++) {
              if (plans[i]["_id"] === currentPlanID) {
                plans[i]["key"] = key;
                plans[i]["value"] = value;
                break;
              }
            }
            setPlans(newPlans);
            toast.success("Plan has been updated successfully!");
            handleEditPlanModalClose();
          })
          .catch((e) => {
            console.log(e);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else {
              toast.error(e.response.data.message);
            }
          });
      }
    } else {
      toast.error("Current plan is invalid!");
    }
  };

  const handleDeleteModalOpen = (e, plan) => {
    setCurrentPlanID(plan["_id"]);
    setDeletePlanModalOpen(true);
  };

  const handleDeletePlanModalClose = () => {
    setDeletePlanModalOpen(false);
  };

  const handleKeyChange = (event) => {
    setKeyError(event.target.value ? false : true);
    setKey(event.target.value);
  };

  const handleValueChange = (event) => {
    setValueError(event.target.value ? false : true);
    setValue(event.target.value);
  };

  const handleDeletePlan = () => {
    if (currentPlanID) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .delete(`/plan/${currentPlanID}`)
        .then((response) => {
          let newPlans = [...plans];
          for (let i = 0; i < plans.length; i++) {
            if (plans[i]["_id"] === currentPlanID) {
              newPlans.splice(i, 1);
              break;
            }
          }
          setPlans(newPlans);
          toast.success("Plan has been deleted successfully!");
          handleDeletePlanModalClose();
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else {
      toast.error("Current plan is invalid!");
    }
  };

  const handleFilterStrChange = (event) => {
    setFilterStr(event.target.value);
  };

  return (
    <Stack direction="row">
      <Stack
        direction="column"
        style={{ width: "250px", minWidth: "250px", marginRight: "10px" }}
        spacing={1}
      >
        <TextField
          variant="outlined"
          label="Search plans"
          color="primary"
          size="medium"
          onChange={handleFilterStrChange}
          fullWidth
        />
        <div style={{ maxHeight: "500px", overflow: "scroll" }}>
          {filteredPlans.map((plan, index) => (
            <Stack direction="row" spacing={1} key={index}>
              <Button
                key={plan["key"]}
                variant="outlined"
                style={{ width: "80%" }}
                onClick={(e) => handleClickPlan(e, index)}
              >
                {plan["key"]}
              </Button>
              {!plan.default && (
                <IconButton onClick={(e) => handleDeleteModalOpen(e, plan)}>
                  <DeleteIcon color="error" />
                </IconButton>
              )}
              <IconButton onClick={(e) => handleEditPlanModalOpen(e, plan)}>
                <EditIcon color="secondary" />
              </IconButton>
            </Stack>
          ))}
        </div>
        <Button
          variant="contained"
          style={{ marginTop: "10px" }}
          onClick={handleEditModalOpen}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          style={{ marginTop: "10px" }}
          onClick={handleAddModalOpen}
        >
          Add
        </Button>
      </Stack>
      <Stack direction="column">
        {planText &&
          planText.split("\n").map((item, index) => (
            <Typography variant="h6" key={index}>
              {item}
            </Typography>
          ))}
      </Stack>

      <Dialog
        open={editModalOpen}
        onClose={handleEditModalClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Edit plan</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Content"
            multiline
            rows={15}
            fullWidth
            variant="standard"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditModalClose}>Cancel</Button>
          <Button onClick={handleUpdatePlanText}>Update</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addModalOpen}
        onClose={handleAddModalClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Add plan</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Key"
            fullWidth
            variant="standard"
            value={key}
            error={keyError}
            onChange={handleKeyChange}
          />
          <TextField
            margin="dense"
            label="Value"
            fullWidth
            multiline
            variant="standard"
            value={value}
            error={valueError}
            onChange={handleValueChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddModalClose}>Cancel</Button>
          <Button onClick={handleAddPlan}>Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editPlanModalOpen}
        onClose={handleEditPlanModalClose}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Edit plan</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Key"
            fullWidth
            variant="standard"
            value={key}
            error={keyError}
            onChange={handleKeyChange}
          />
          <TextField
            margin="dense"
            label="Value"
            fullWidth
            multiline
            variant="standard"
            value={value}
            error={valueError}
            onChange={handleValueChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditPlanModalClose}>Cancel</Button>
          <Button onClick={handleUpdatePlan}>Update</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deletePlanModalOpen}
        onClose={handleDeletePlanModalClose}
        fullWidth
      >
        <DialogTitle>Delete plan</DialogTitle>
        <DialogContent>Are you sure to delete the plan?</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            autoFocus
            onClick={handleDeletePlanModalClose}
          >
            Cancel
          </Button>
          <Button variant="outlined" color="error" onClick={handleDeletePlan}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Plan;
