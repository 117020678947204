import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Add as AddIcon, Edit as EditIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import { secureApi } from "../../config";
import { Context } from "../../context";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles(() => ({
  mt30: {
    marginTop: "30px",
  },
}));

const ProcedurePerformed = ({
  custom,
  performedProcedure,
  setPerformedProcedure,
}) => {
  const navigate = useNavigate();
  const { setLoggedIn } = useContext(Context);
  const classes = useStyles();
  const [currentProcedureIndex, setCurrentProcedureIndex] = useState(-1);
  const [procedures, setProcedures] = useState([]);
  const [key, setKey] = useState("");
  const [title, setTitle] = useState("");
  const [procedure, setProcedure] = useState("");
  const [medicationsInjected, setMedicationsInjected] = useState("");
  const [localAnestheticInjected, setLocalAnestheticInjected] = useState("");
  const [complications, setComplications] = useState("");
  const [technique, setTechnique] = useState("");
  const [CPTcode, setCPTcode] = useState("");
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [keyError, setKeyError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    console.log(performedProcedure);
  }, [performedProcedure]);

  useEffect(() => {
    secureApi(window.localStorage.getItem("alex-med-token"))
      .get(`/procedures`)
      .then((response) => {
        const { procedures } = response.data;
        if (procedures) {
          setProcedures(procedures);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          toast.error(e.response.data.message);
        }
      });
  }, []);

  useEffect(() => {
    if (
      procedures.length > currentProcedureIndex &&
      currentProcedureIndex > -1
    ) {
      setPerformedProcedure(procedures[currentProcedureIndex]);
    }
  }, [procedures, currentProcedureIndex]);

  useEffect(() => {
    if (!custom) {
      setCurrentProcedureIndex(0);
    }
  }, [custom]);

  const handleProcedureChange = (e) => {
    setCurrentProcedureIndex(e.target.value);
    setPerformedProcedure(procedures[e.target.value]);
  };

  const handleKeyChange = (e) => {
    const { value: key } = e.target;
    if (key.trim()) setKeyError(false);
    else setKeyError(true);
    setKey(key);
  };

  const handleTitleChange = (e) => {
    const { value: title } = e.target;
    if (title.trim()) setTitleError(false);
    else setTitleError(true);
    setTitle(title);
  };

  // flag true -> template edit, false -> text edit
  const handleEditModalOpen = (event, flag) => {
    setFlag(flag);
    if (flag) {
      setKey(procedures[currentProcedureIndex]["key"]);
      setTitle(procedures[currentProcedureIndex]["title"]);
      setProcedure(procedures[currentProcedureIndex]["procedure"].join("\n"));
      setMedicationsInjected(
        procedures[currentProcedureIndex]["medicationsInjected"]
      );
      setLocalAnestheticInjected(
        procedures[currentProcedureIndex]["localAnestheticInjected"]
      );
      setComplications(procedures[currentProcedureIndex]["complications"]);
      setTechnique(procedures[currentProcedureIndex]["technique"]);
      setCPTcode(procedures[currentProcedureIndex]["CPTcode"]);
    } else {
      setKey(performedProcedure["key"]);
      setTitle(performedProcedure["title"]);
      setProcedure(performedProcedure["procedure"].join("\n"));
      setMedicationsInjected(performedProcedure["medicationsInjected"]);
      setLocalAnestheticInjected(performedProcedure["localAnestheticInjected"]);
      setComplications(performedProcedure["complications"]);
      setTechnique(performedProcedure["technique"]);
      setCPTcode(performedProcedure["CPTcode"]);
    }
    setOpenEditModal(true);
  };

  const handleEditModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setKeyError(false);
      setTitleError(false);
      setOpenEditModal(false);
    }
  };

  const handleUpdate = () => {
    const procedureData = {
      key,
      title,
      procedure: procedure.split("\n"),
      medicationsInjected,
      localAnestheticInjected,
      complications,
      technique,
      CPTcode,
    };
    if (flag) {
      const procedureID = procedures[currentProcedureIndex]["_id"];
      secureApi(window.localStorage.getItem("alex-med-token"))
        .put(`/procedure/${procedureID}`, procedureData)
        .then((response) => {
          let newProcedures = [...procedures];
          for (let i = 0; i < procedures.length; i++) {
            if (i === currentProcedureIndex) {
              newProcedures[i] = {
                ...newProcedures[i],
                ...procedureData,
              };
              break;
            }
          }
          setProcedures(newProcedures);
          setPerformedProcedure(procedureData);
          setOpenEditModal(false);
          toast.success("Procedure has been updated successfully!");
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else {
      setPerformedProcedure(procedureData);
      setOpenEditModal(false);
    }
  };

  const handleAddModalOpen = () => {
    setKey("");
    setTitle("");
    setProcedure("");
    setMedicationsInjected("");
    setLocalAnestheticInjected("");
    setComplications("");
    setTechnique("");
    setCPTcode("");
    setOpenAddModal(true);
  };

  const handleAddModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setKeyError(false);
      setTitleError(false);
      setOpenAddModal(false);
    }
  };

  const handleAdd = () => {
    if (key.trim() === "") setKeyError(true);
    if (title.trim() === "") setTitleError(true);

    if (key.trim() && title.trim()) {
      const procedureData = {
        key,
        title,
        procedure: procedure.split("\n"),
        medicationsInjected,
        localAnestheticInjected,
        complications,
        technique,
        CPTcode,
      };
      secureApi(window.localStorage.getItem("alex-med-token"))
        .post(`/procedure`, procedureData)
        .then((response) => {
          let newProcedures = [
            ...procedures,
            {
              _id: response.data.procedure_id,
              ...procedureData,
            },
          ];
          setProcedures(newProcedures);
          setOpenAddModal(false);
          toast.success("New procedure has been added successfully!");
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
      setOpenAddModal(false);
    }
  };

  return (
    <Stack direction="row" className={classes.mt30}>
      <Stack
        direction="column"
        style={{ width: "250px", minWidth: "250px", marginRight: "10px" }}
        spacing={1}
      >
        <FormControl className={classes.mt30}>
          <InputLabel>Procedure</InputLabel>
          <Select
            label="Procedure"
            value={currentProcedureIndex}
            onChange={handleProcedureChange}
          >
            <MenuItem value={-1} sx={{ display: "none" }}></MenuItem>
            {procedures &&
              procedures.map((item, index) => (
                <MenuItem value={index} key={index}>
                  {item["key"]}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {procedures[currentProcedureIndex] && (
          <Button
            variant="contained"
            onClick={(e) => handleEditModalOpen(e, true)}
            startIcon={<EditIcon />}
          >
            Edit Template
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleAddModalOpen}
          startIcon={<AddIcon />}
        >
          Add Template
        </Button>
        <Button
          variant="contained"
          onClick={(e) => handleEditModalOpen(e, false)}
          startIcon={<EditIcon />}
          color="secondary"
        >
          Edit Text
        </Button>
      </Stack>
      <Stack direction="column">
        <Typography variant="h6" align="left" fontWeight="bold">
          Procedure Performed
        </Typography>
        {performedProcedure && (
          <Stack direction="column">
            <Stack direction="row">
              <Typography variant="h6" fontWeight="bold">
                Title:&nbsp;
              </Typography>
              <Stack direction="column">
                <Typography variant="h6">
                  {performedProcedure["title"]}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction="row">
              <Typography variant="h6" fontWeight="bold">
                Procedure:&nbsp;
              </Typography>
              <Stack direction="column">
                {performedProcedure["procedure"] &&
                  performedProcedure["procedure"].map((item1, index1) => (
                    <Typography variant="h6" key={index1}>{`${
                      index1 + 1
                    }) ${item1}`}</Typography>
                  ))}
              </Stack>
            </Stack>
            <Typography variant="h6">
              <span style={{ fontWeight: "bold" }}>Medications Injected:</span>
              {performedProcedure["medicationsInjected"]}
            </Typography>
            <Typography variant="h6">
              <span style={{ fontWeight: "bold" }}>
                Local Anesthetic Injected:&nbsp;
              </span>
              {performedProcedure["localAnestheticInjected"]}
            </Typography>
            <Typography variant="h6">
              <span style={{ fontWeight: "bold" }}>Complications:&nbsp;</span>
              {performedProcedure["complications"]}
            </Typography>
            <Typography variant="h6">
              <span style={{ fontWeight: "bold" }}>Technique:&nbsp;</span>
              {performedProcedure["technique"]}
            </Typography>
            <Typography variant="h6">
              <span style={{ fontWeight: "bold" }}>CPT Code:&nbsp;</span>
              {performedProcedure["CPTcode"]}
            </Typography>
          </Stack>
        )}
      </Stack>

      <Dialog
        open={openEditModal}
        onClose={handleEditModalClose}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle>Edit Details of Procedure</DialogTitle>
        <DialogContent>
          {flag && (
            <TextField
              margin="dense"
              label="Key"
              fullWidth
              variant="standard"
              value={key}
              onChange={handleKeyChange}
              error={keyError}
            />
          )}
          <TextField
            margin="dense"
            label="Title"
            fullWidth
            variant="standard"
            value={title}
            onChange={handleTitleChange}
            error={titleError}
          />
          <TextField
            margin="dense"
            label="Procedure"
            fullWidth
            variant="standard"
            value={procedure}
            onChange={(e) => setProcedure(e.target.value)}
            multiline
            rows={3}
          />
          <TextField
            margin="dense"
            label="Medications Injected"
            fullWidth
            variant="standard"
            value={medicationsInjected}
            onChange={(e) => setMedicationsInjected(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Local Anesthetic Injected"
            fullWidth
            variant="standard"
            value={localAnestheticInjected}
            onChange={(e) => setLocalAnestheticInjected(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Complications"
            fullWidth
            variant="standard"
            value={complications}
            onChange={(e) => setComplications(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Technique"
            fullWidth
            multiline
            rows={3}
            variant="standard"
            value={technique}
            onChange={(e) => setTechnique(e.target.value)}
          />
          <TextField
            margin="dense"
            label="CPT Code"
            fullWidth
            variant="standard"
            value={CPTcode}
            onChange={(e) => setCPTcode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditModalClose}>Cancel</Button>
          <Button onClick={handleUpdate}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openAddModal}
        onClose={handleAddModalClose}
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogTitle>Add Details of Procedure</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Key"
            fullWidth
            variant="standard"
            value={key}
            onChange={handleKeyChange}
            error={keyError}
          />
          <TextField
            margin="dense"
            label="Title"
            fullWidth
            variant="standard"
            value={title}
            onChange={handleTitleChange}
            error={titleError}
          />
          <TextField
            margin="dense"
            label="Procedure"
            fullWidth
            variant="standard"
            value={procedure}
            onChange={(e) => setProcedure(e.target.value)}
            multiline
            rows={3}
          />
          <TextField
            margin="dense"
            label="Medications Injected"
            fullWidth
            variant="standard"
            value={medicationsInjected}
            onChange={(e) => setMedicationsInjected(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Local Anesthetic Injected"
            fullWidth
            variant="standard"
            value={localAnestheticInjected}
            onChange={(e) => setLocalAnestheticInjected(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Complications"
            fullWidth
            variant="standard"
            value={complications}
            onChange={(e) => setComplications(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Technique"
            fullWidth
            multiline
            rows={3}
            variant="standard"
            value={technique}
            onChange={(e) => setTechnique(e.target.value)}
          />
          <TextField
            margin="dense"
            label="CPT Code"
            fullWidth
            variant="standard"
            value={CPTcode}
            onChange={(e) => setCPTcode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddModalClose}>Cancel</Button>
          <Button onClick={handleAdd}>OK</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default ProcedurePerformed;
