import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Input,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";

const PatientInfoField = ({ value = "" }) => {
  return (
    <Input
      variant="filled"
      color="primary"
      size="medium"
      value={value}
      readOnly={true}
    />
  );
};

const Demographics = ({
  patientName,
  dateOfBirth,
  gender,
  provider,
  setProvider,
  dateOfService,
  timeOfService,
  setDateOfService,
  setTimeOfService,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow key="1">
            <TableCell style={{ fontWeight: "bold" }}>Patient Name: </TableCell>
            <TableCell>
              <PatientInfoField value={`${patientName}`} />
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              Date of Service:
            </TableCell>
            <TableCell>
              {/* <PatientInfoField value={dateOfService} /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  size="medium"
                  value={dateOfService}
                  onChange={(e) => setDateOfService(e)}
                />
              </LocalizationProvider>
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>SEX: </TableCell>
            <TableCell>
              <PatientInfoField value={gender} />
            </TableCell>
          </TableRow>

          <TableRow key="2">
            <TableCell style={{ fontWeight: "bold" }}>Provider: </TableCell>
            <TableCell>
              <Input
                value={provider}
                onChange={(e) => setProvider(e.target.value)}
              />
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>
              Time of Service:
            </TableCell>
            <TableCell>
              {/* <PatientInfoField value={timeOfService} /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  size="medium"
                  value={timeOfService}
                  onChange={(e) => setTimeOfService(e)}
                />
              </LocalizationProvider>
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }}>DOB: </TableCell>
            <TableCell>
              <PatientInfoField value={dateOfBirth} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Demographics;
