import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Context } from "../context";
import { ADMIN } from "../config";

const TopNav = ({ item }) => {
  const { setLoggedIn, user } = useContext(Context);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const gotoPatients = () => {
    navigate("/");
  };

  const gotoAppointments = () => {
    navigate("/appointments");
  };

  const gotoUsers = () => {
    navigate("/users");
  };

  const gotoBilling = () => {
    navigate("/billing");
  };

  const handleLogout = () => {
    window.localStorage.removeItem("alex-med-token");
    setLoggedIn(false);
    navigate("/login");
  };

  const handleChangePassword = () => {
    navigate("/change-password");
  };

  const navButtonStyle = {
    height: "65px",
    fontSize: "20px",
    borderRadius: "0",
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ padding: "0 30px" }}>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Button
            color="inherit"
            sx={{
              ...navButtonStyle,
              background: item === "patients" ? "#1565c0" : "unset",
            }}
            onClick={gotoPatients}
          >
            Patients
          </Button>
          <Button
            color="inherit"
            sx={{
              ...navButtonStyle,
              background: item === "appointments" ? "#1565c0" : "unset",
            }}
            onClick={gotoAppointments}
          >
            Appointments
          </Button>
          {user.role === ADMIN && (
            <Button
              color="inherit"
              sx={{
                ...navButtonStyle,
                background: item === "users" ? "#1565c0" : "unset",
              }}
              onClick={gotoUsers}
            >
              Users
            </Button>
          )}
          <Button
            color="inherit"
            sx={{
              ...navButtonStyle,
              background: item === "billing" ? "#1565c0" : "unset",
            }}
            onClick={gotoBilling}
          >
            Billing
          </Button>
        </Box>

        <Box sx={{ flexGrow: 0 }}>
          <Button
            style={{ color: "white", textTransform: "none" }}
            onClick={handleOpenUserMenu}
          >
            {user.email}
            <br />
            {user.role}
          </Button>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key="change-password" onClick={handleChangePassword}>
              <Typography textAlign="center">Change Password</Typography>
            </MenuItem>
            <MenuItem key="logout" onClick={handleLogout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default TopNav;
