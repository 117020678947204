import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import {
  secureApi,
  MEDICAL_ASSISTANT,
  MEDICAL_BILLING_PERSON,
} from "../../config";
import { toast } from "react-toastify";
import { Context } from "../../context";

const UserModal = ({ open, handleCancel, userID }) => {
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(MEDICAL_ASSISTANT);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangeName = (e) => {
    setName(e.target.value);
    if (e.target.value) setNameError(false);
    else setNameError(true);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value) setEmailError(false);
    else setEmailError(true);
  };

  const handleOK = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const isValidEmail = emailRegex.test(email);
    setEmailError(!isValidEmail);
    if (!name) setNameError(true);
    if (name && isValidEmail) {
      if (userID) {
        secureApi(localStorage.getItem("alex-med-token"))
          .put(`/user/${userID}`, {
            name,
            email,
            role,
          })
          .then(() => {
            toast.success("User has been updated successfully!");
            window.location.href = window.location.href;
          })
          .catch((e) => {
            console.log(e);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else {
              toast.error(e.response.data.message);
            }
          });
      } else {
        setLoading(true);
        secureApi(localStorage.getItem("alex-med-token"))
          .post(`/user`, {
            name,
            email,
            role,
          })
          .then(() => {
            setLoading(false);
            toast.success("New user has been added successfully!");
            window.location.href = window.location.href;
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else {
              toast.error(e.response.data.message);
            }
          });
      }
    }
  };

  const handleCancelClick = () => {
    handleCancel();
    setName("");
    setEmail("");
    setRole(MEDICAL_ASSISTANT);
    setNameError(false);
    setEmailError(false);
  };

  useEffect(() => {
    if (userID) {
      secureApi(localStorage.getItem("alex-med-token"))
        .get(`/user/${userID}`)
        .then((response) => {
          console.log(response.data);
          const { name, email, role } = response.data;
          setName(name);
          setEmail(email);
          setRole(role);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
  }, [userID]);

  return (
    <Dialog fullScreen={fullScreen} open={open} fullWidth maxWidth="md">
      <DialogTitle>{userID ? "Edit User" : "Add User"}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancelClick}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={1}>
          <TextField
            variant="outlined"
            label="name"
            value={name}
            onChange={handleChangeName}
            error={nameError}
          />
          <TextField
            variant="outlined"
            label="email"
            value={email}
            onChange={handleChangeEmail}
            error={emailError}
          />
          <FormControl>
            <InputLabel>Role</InputLabel>
            <Select
              variant="outlined"
              label="Role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              <MenuItem value={MEDICAL_ASSISTANT}>{MEDICAL_ASSISTANT}</MenuItem>
              <MenuItem value={MEDICAL_BILLING_PERSON}>
                {MEDICAL_BILLING_PERSON}
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions style={{ padding: "0 24px 20px" }}>
        <LoadingButton onClick={handleOK} loading={loading} variant="contained">
          OK
        </LoadingButton>
        <Button onClick={handleCancelClick} variant="contained" color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserModal;
