import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import { BackupTable as ExportIcon } from "@mui/icons-material";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DateBillingTable from "../billing/DateBillingTable";
import EditModal from "../billing/EditModal";
import DeleteModal from "../billing/DeleteModal";
import { secureApi } from "../../config";

const tableHeads = [
  { headerName: "Insurance", field: "insurance" },
  { headerName: "Status", field: "status" },
  { headerName: "Service Date", field: "date_of_service" },
  { headerName: "Cost Share Paid", field: "cost_share_paid" },
  { headerName: "Date Billed", field: "date_billed" },
  { headerName: "Total Charges", field: "total_charges" },
  { headerName: "Total receipts by mail", field: "total_receipts_by_mail" },
  { headerName: "Total receipts by Tebra", field: "total_receipts_by_tebra" },
  { headerName: "Receipt Date", field: "receipt_date" },
  { headerName: "Total balance", field: "total_balance" },
  { headerName: "Sent to patient?", field: "sent_to_patient" },
  { headerName: "Collected from patient?", field: "collected_from_patient" },
  { headerName: "Appeal needed?", field: "appeal_needed" },
  { headerName: "Appeal Date", field: "appeal_date" },
  { headerName: "Comments", field: "comments" },
];

const Billing = ({ patient_id }) => {
  const [billingInformation, setBillingInformation] = useState([]);
  const [filteredBilling, setFilteredBilling] = useState([]);
  const [billingID, setBillingID] = useState();
  const [billing, setBilling] = useState({});
  const [dateOfServiceIdx, setDateOfServiceIdx] = useState(0);
  const [procedureIdx, setProcedureIdx] = useState(0);
  const [billingLevel, setBillingLevel] = useState(0);
  const [searchStr, setSearchStr] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleEditModalOpen = (
    billing,
    billingID,
    dateOfServiceIdx,
    procedureIdx,
    billingLevel
  ) => {
    setBilling(billing);
    setBillingID(billingID);
    setDateOfServiceIdx(dateOfServiceIdx);
    setProcedureIdx(procedureIdx);
    setBillingLevel(billingLevel);
    setEditModalOpen(true);
  };

  const handleDeleteModalOpen = (
    billingID,
    dateOfServiceIdx,
    procedureIdx,
    billingLevel
  ) => {
    setBillingID(billingID);
    setDateOfServiceIdx(dateOfServiceIdx);
    setProcedureIdx(procedureIdx);
    setBillingLevel(billingLevel);
    setDeleteModalOpen(true);
  };

  const handleEditModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setEditModalOpen(false);
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteBilling = (
    billingID,
    dateOfServiceIdx,
    procedureIdx,
    billingLevel
  ) => {
    let newBillingInformation = [...billingInformation];
    if (newBillingInformation.length > dateOfServiceIdx) {
      if (billingLevel === 1) {
        newBillingInformation.splice(dateOfServiceIdx, 1);
        setBillingInformation(newBillingInformation);
      } else if (
        billingLevel === 2 &&
        newBillingInformation[dateOfServiceIdx]["details"].length > procedureIdx
      ) {
        newBillingInformation[dateOfServiceIdx]["details"].splice(
          procedureIdx,
          1
        );
        setBillingInformation(newBillingInformation);
      }
    }
  };

  const handleUpdateBilling = (billingID, dateOfServiceIdx, procedureIdx) => {
    let newBillingInformation = [...billingInformation];

    if (newBillingInformation.length > dateOfServiceIdx) {
      if (billingLevel === 1) {
        newBillingInformation[dateOfServiceIdx] = billing;
        setBillingInformation(newBillingInformation);
      } else if (
        billingLevel === 2 &&
        newBillingInformation[dateOfServiceIdx]["details"].length > procedureIdx
      ) {
        newBillingInformation[dateOfServiceIdx]["details"][procedureIdx] =
          billing;
        setBillingInformation(newBillingInformation);
      }
    }
  };

  const exportExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Convert data with headers to sheet
    const dataWithHeaders = billingInformation.map((billing) => {
      const obj = {};
      tableHeads
        .filter((column) => column.headerName)
        .forEach((column) => {
          if (column.headerName) obj[column.headerName] = billing[column.field];
        });
      return obj;
    });
    // Convert data with headers to sheet
    const ws = XLSX.utils.json_to_sheet(dataWithHeaders);

    // Set background color for the first 2 column headers
    const firstTwoHeaders = tableHeads
      .filter((column) => column.headerName)
      .slice(0, 2)
      .map((column) => column.headerName);

    firstTwoHeaders.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: index });
      if (!ws[cellAddress]) ws[cellAddress] = { t: "s", v: header };
      ws[cellAddress].s = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { rgb: "FFFF00" }, // Yellow background color
        },
        font: {
          bold: true,
        },
      };
    });

    // Create workbook and add sheet with data
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");

    // Write workbook to buffer in xlsx format
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert buffer to Blob with specified MIME type
    const data = new Blob([excelBuffer], { type: fileType });

    // Save data as a file with specified filename
    FileSaver.saveAs(data, `billing` + fileExtension);
  };

  useEffect(() => {
    if (patient_id) {
      secureApi(window.localStorage.getItem("alex-med-token"))
        .get(`/billings/${patient_id}`)
        .then((response) => {
          const { billings } = response.data;
          if (billings) {
            setBillingInformation(billings["history"]);
            setBillingID(billings["_id"]);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
  }, [patient_id]);

  useEffect(() => {
    if (searchStr) {
      const filteredBilling = billingInformation.filter(
        (billing) =>
          billing.status.toLowerCase().includes(searchStr) ||
          billing.date_of_service.toLowerCase().includes(searchStr) ||
          billing.date_billed.toLowerCase().includes(searchStr) ||
          billing.sent_to_patient.toLowerCase().includes(searchStr) ||
          billing.collected_from_patient.toLowerCase().includes(searchStr) ||
          billing.appeal_needed.toLowerCase().includes(searchStr) ||
          billing.appeal_date.toLowerCase().includes(searchStr) ||
          billing.details.some(
            (detailItem) =>
              detailItem.procedure.toLowerCase().includes(searchStr) ||
              detailItem.mod.toLowerCase().includes(searchStr)
          )
      );
      setFilteredBilling(filteredBilling);
    } else {
      setFilteredBilling(billingInformation);
    }
  }, [searchStr, billingInformation]);

  return (
    <Box sx={{ margin: 1 }}>
      <div style={{ width: "100%" }}>
        <Stack
          direction="row"
          sx={{ float: "right", marginBottom: "10px" }}
          gap={1}
        >
          <Button
            variant="contained"
            style={{ width: "fit-content" }}
            startIcon={<ExportIcon />}
            onClick={exportExcel}
          >
            Export Excel
          </Button>
          <TextField
            size="small"
            label="Search"
            variant="outlined"
            style={{ float: "right" }}
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
          />
        </Stack>
      </div>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="purchases">
          <TableHead>
            <TableRow sx={{ background: "#fce5cd" }}>
              <TableCell />
              <TableCell align="right">Insurance</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Service Date</TableCell>
              <TableCell align="right">Cost share paid</TableCell>
              <TableCell align="right">Date billed</TableCell>
              <TableCell align="right">Total charges</TableCell>
              <TableCell align="right">Total receipts by mail</TableCell>
              <TableCell align="right">Total receipts by Tebra</TableCell>
              <TableCell align="right">Receipt Date</TableCell>
              <TableCell align="right">Total balance</TableCell>
              <TableCell align="right">Sent to patient?</TableCell>
              <TableCell align="right">Collected from patient?</TableCell>
              <TableCell align="right">Appeal needed?</TableCell>
              <TableCell align="right">Appeal Date</TableCell>
              <TableCell align="right">Comments</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBilling.length > 0 &&
              filteredBilling.map((historyRow, index) => (
                <DateBillingTable
                  billing={historyRow}
                  key={index}
                  billingID={billingID}
                  dateOfServiceIdx={index}
                  handleEditModalOpen={handleEditModalOpen}
                  handleDeleteModalOpen={handleDeleteModalOpen}
                />
              ))}
            {filteredBilling.length === 0 && (
              <TableRow>
                <TableCell colSpan={17} sx={{ textAlign: "center" }}>
                  <Typography variant="h6">No Billing Information</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        billingID={billingID}
        dateOfServiceIdx={dateOfServiceIdx}
        procedureIdx={procedureIdx}
        billingLevel={billingLevel}
        handleDeleteBilling={handleDeleteBilling}
      />
      <EditModal
        billing={billing}
        billingID={billingID}
        dateOfServiceIdx={dateOfServiceIdx}
        procedureIdx={procedureIdx}
        billingLevel={billingLevel}
        setBilling={setBilling}
        open={editModalOpen}
        handleClose={handleEditModalClose}
        handleUpdateBilling={handleUpdateBilling}
      />
    </Box>
  );
};

export default Billing;
