import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Input,
  Select,
  MenuItem,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Context } from "../../context";
import {
  MEDICAL_BILLING_PERSON,
  PERSONAL_INJURY_PATIENT,
  CHRONIC_PAIN_PATIENT,
} from "../../config";

const PatientInfoEditInput = ({
  name,
  value,
  updatePatientInfo,
  painConsultNoteSigned,
}) => {
  const { user } = useContext(Context);
  return (
    <Input
      variant="filled"
      color="primary"
      size="medium"
      fullWidth
      value={value}
      name={name}
      onChange={(e) => updatePatientInfo(e)}
      readOnly={
        name === "first_name" ||
        name === "middle_name" ||
        name === "last_name" ||
        name === "date_of_birth" ||
        name === "suffix" ||
        name === "ssn"
          ? (user && user.role === MEDICAL_BILLING_PERSON) ||
            painConsultNoteSigned
          : user && user.role === MEDICAL_BILLING_PERSON
      }
    />
  );
};

const PatientInfo = ({ patient, setPatient, painConsultNoteSigned }) => {
  const { user } = useContext(Context);

  const updatePatientInfo = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setPatient((values) => ({ ...values, [name]: value }));
  };

  const handleDateOfAccidentChange = (date) => {
    setPatient({
      ...patient,
      date_of_accident: date,
    });
  };

  const handleDateOfBirthChange = (date) => {
    setPatient({
      ...patient,
      date_of_birth: date,
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableBody>
          <TableRow key="1">
            <TableCell style={{ fontWeight: "bold" }} width="12%">
              Office
              <span style={{ color: "red", fontWeight: "bolder" }}>*</span>
            </TableCell>
            <TableCell width="38%">
              <PatientInfoEditInput
                name="clinic"
                value={patient.clinic}
                updatePatientInfo={updatePatientInfo}
                painConsultNoteSigned={painConsultNoteSigned}
              />
            </TableCell>

            <TableCell style={{ fontWeight: "bold" }} width="17%">
              Prefix
            </TableCell>
            <TableCell width="33%">
              <PatientInfoEditInput
                name="prefix"
                value={patient.prefix}
                updatePatientInfo={updatePatientInfo}
                painConsultNoteSigned={painConsultNoteSigned}
              />
            </TableCell>
          </TableRow>
          <TableRow key="2">
            <TableCell style={{ fontWeight: "bold" }}>
              First Name
              <span style={{ color: "red", fontWeight: "bolder" }}>*</span>
            </TableCell>
            <TableCell>
              <PatientInfoEditInput
                name="first_name"
                value={patient.first_name}
                updatePatientInfo={updatePatientInfo}
                painConsultNoteSigned={painConsultNoteSigned}
              />
            </TableCell>

            <TableCell style={{ fontWeight: "bold" }}>
              Gender
              <span style={{ color: "red", fontWeight: "bolder" }}>*</span>
            </TableCell>
            <TableCell>
              <PatientInfoEditInput
                name="gender"
                value={patient.gender}
                updatePatientInfo={updatePatientInfo}
                painConsultNoteSigned={painConsultNoteSigned}
              />
            </TableCell>
          </TableRow>
          <TableRow key="3">
            <TableCell style={{ fontWeight: "bold" }}>Middle Name</TableCell>
            <TableCell>
              <PatientInfoEditInput
                name="middle_name"
                value={patient.middle_name}
                updatePatientInfo={updatePatientInfo}
                painConsultNoteSigned={painConsultNoteSigned}
              />
            </TableCell>

            <TableCell style={{ fontWeight: "bold" }}>
              Date of Birth
              <span style={{ color: "red", fontWeight: "bolder" }}>*</span>
            </TableCell>
            <TableCell>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                style={{ width: "100%" }}
              >
                <DatePicker
                  onChange={handleDateOfBirthChange}
                  value={patient.date_of_birth}
                  size="small"
                  style={{ width: "100%" }}
                  disabled={
                    (user && user.role === MEDICAL_BILLING_PERSON) ||
                    painConsultNoteSigned
                  }
                />
              </LocalizationProvider>
            </TableCell>
          </TableRow>
          <TableRow key="4">
            <TableCell style={{ fontWeight: "bold" }}>
              Last Name
              <span style={{ color: "red", fontWeight: "bolder" }}>*</span>
            </TableCell>
            <TableCell>
              <PatientInfoEditInput
                name="last_name"
                value={patient.last_name}
                updatePatientInfo={updatePatientInfo}
                painConsultNoteSigned={painConsultNoteSigned}
              />
            </TableCell>

            <TableCell style={{ fontWeight: "bold" }}>Marital Status</TableCell>
            <TableCell>
              <PatientInfoEditInput
                name="marital_status"
                value={patient.marital_status}
                updatePatientInfo={updatePatientInfo}
                painConsultNoteSigned={painConsultNoteSigned}
              />
            </TableCell>
          </TableRow>
          <TableRow key="5">
            <TableCell style={{ fontWeight: "bold" }}>Suffix</TableCell>
            <TableCell>
              <PatientInfoEditInput
                name="suffix"
                value={patient.suffix}
                updatePatientInfo={updatePatientInfo}
                painConsultNoteSigned={painConsultNoteSigned}
              />
            </TableCell>

            <TableCell style={{ fontWeight: "bold" }}>
              Social Security Number
            </TableCell>
            <TableCell>
              <PatientInfoEditInput
                name="ssn"
                value={patient.ssn}
                updatePatientInfo={updatePatientInfo}
                painConsultNoteSigned={painConsultNoteSigned}
              />
            </TableCell>
          </TableRow>
          {patient.type === PERSONAL_INJURY_PATIENT && (
            <TableRow key="6">
              <TableCell style={{ fontWeight: "bold" }}>Time / Place</TableCell>
              <TableCell>
                <PatientInfoEditInput
                  name="time_place"
                  value={patient.time_place}
                  updatePatientInfo={updatePatientInfo}
                  painConsultNoteSigned={painConsultNoteSigned}
                />
              </TableCell>

              <TableCell style={{ fontWeight: "bold" }} width="17%">
                Date of Accident
                <span style={{ color: "red", fontWeight: "bolder" }}>*</span>
              </TableCell>
              <TableCell>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  style={{ width: "100%" }}
                >
                  <DatePicker
                    onChange={handleDateOfAccidentChange}
                    value={patient.date_of_accident}
                    size="small"
                    style={{ width: "100%" }}
                    disabled={
                      (user && user.role === MEDICAL_BILLING_PERSON) ||
                      painConsultNoteSigned
                    }
                  />
                </LocalizationProvider>
              </TableCell>
            </TableRow>
          )}
          <TableRow key="7">
            <TableCell style={{ fontWeight: "bold" }}>
              Type<span style={{ color: "red", fontWeight: "bolder" }}>*</span>
            </TableCell>
            <TableCell>
              <Select
                variant="standard"
                color="primary"
                size="medium"
                fullWidth
                name="type"
                value={patient.type}
                readOnly={true}
                // readOnly={
                //   (user && user.role === MEDICAL_BILLING_PERSON) ||
                //   painConsultNoteSigned
                // }
                onChange={updatePatientInfo}
              >
                <MenuItem value={CHRONIC_PAIN_PATIENT}>
                  {CHRONIC_PAIN_PATIENT}
                </MenuItem>
                <MenuItem value={PERSONAL_INJURY_PATIENT}>
                  {PERSONAL_INJURY_PATIENT}
                </MenuItem>
              </Select>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PatientInfo;
