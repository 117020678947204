import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  Button,
  IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const ConfirmModal = ({ openModal, state, handleCancel, handleDrop, handleResize }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <BootstrapDialog fullScreen={fullScreen} open={openModal} fullWidth maxWidth='md'>
      <DialogTitle>
        {
          state === "drop" ? "Drop Appointment" : "Resize Appointment"
        }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          Do you want to change this appointment?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {
          state === 'drop' &&
          <Button autoFocus onClick={(e) => handleDrop(e)} variant='contained'>
            OK
          </Button>
        }
        {
          state === 'resize' &&
          <Button autoFocus onClick={(e) => handleResize(e)} variant='contained' color='error'>
            OK
          </Button>
        }
        <Button onClick={handleCancel} autoFocus variant='contained' style={{ background: 'grey' }}>
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default ConfirmModal;