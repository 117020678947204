import React, { useContext } from "react";
import { Paper, Input, Typography, Stack } from "@mui/material";
import { Context } from "../../context";
import { MEDICAL_BILLING_PERSON } from "../../config";

const PatientInfoEditInput = ({
  name,
  value,
  updatePatientInfo,
  painConsultNoteSigned,
}) => {
  const { user } = useContext(Context);
  return (
    <Input
      variant="filled"
      color="primary"
      size="medium"
      fullWidth
      value={value}
      name={name}
      onChange={(e) => updatePatientInfo(e)}
      readOnly={user && user.role === MEDICAL_BILLING_PERSON}
    />
  );
};

const ContactInfo = ({
  contactInfo,
  setContactInfo,
  painConsultNoteSigned,
}) => {
  const updateContactInfo = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setContactInfo((values) => ({ ...values, [name]: value }));
  };

  return (
    <Paper style={{ padding: "20px", boxSizing: "border-box", width: "50%" }}>
      <Typography variant="h6" fontWeight="bold" marginBottom="10px">
        Contact Information:
      </Typography>
      <Stack spacing={1}>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Preferred Contact
          </Typography>
          <PatientInfoEditInput
            name="preferred_contact"
            value={contactInfo.preferred_contact}
            updatePatientInfo={updateContactInfo}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Contact Number
          </Typography>
          <PatientInfoEditInput
            name="contact_number"
            value={contactInfo.contact_number}
            updatePatientInfo={updateContactInfo}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Home Phone
          </Typography>
          <PatientInfoEditInput
            name="home_phone"
            value={contactInfo.home_phone}
            updatePatientInfo={updateContactInfo}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Email Address
          </Typography>
          <PatientInfoEditInput
            name="email_address"
            value={contactInfo.email_address}
            updatePatientInfo={updateContactInfo}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Primary Address
          </Typography>
          <PatientInfoEditInput
            name="primary_address"
            value={contactInfo.primary_address}
            updatePatientInfo={updateContactInfo}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ContactInfo;
