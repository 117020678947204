import React from "react";
import {
  Stack,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const HeadPhysicalExam = ({ headacheInfo, setHeadacheInfo }) => {
  return (
    <Stack spacing={3}>
      {/* Head Inspection Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Scalp Tenderness</Typography>
            <RadioGroup
              value={headacheInfo.scalpTenderness}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  scalpTenderness: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Absent"
                control={<Radio />}
                label="Absent"
              />
              <FormControlLabel
                value="Frontal regions"
                control={<Radio />}
                label="Frontal regions"
              />
              <FormControlLabel
                value="Parietal regions"
                control={<Radio />}
                label="Parietal regions"
              />
              <FormControlLabel
                value="Occipital regions"
                control={<Radio />}
                label="Occipital regions"
              />
              <FormControlLabel
                value="Temporal regions"
                control={<Radio />}
                label="Temporal regions"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Sinus Tenderness</Typography>
            <RadioGroup
              value={headacheInfo.sinusTenderness}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  sinusTenderness: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Absent"
                control={<Radio />}
                label="Absent"
              />
              <FormControlLabel
                value="Frontal sinuses"
                control={<Radio />}
                label="Frontal sinuses"
              />
              <FormControlLabel
                value="Maxillary sinuses"
                control={<Radio />}
                label="Maxillary sinuses"
              />
              <FormControlLabel
                value="Ethmoid sinuses"
                control={<Radio />}
                label="Ethmoid sinuses"
              />
              <FormControlLabel
                value="Sphenoid sinuses"
                control={<Radio />}
                label="Sphenoid sinuses"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Temporal Artery Tenderness</Typography>
            <RadioGroup
              value={headacheInfo.temporalArteryTenderness}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  temporalArteryTenderness: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Absent"
                control={<Radio />}
                label="Absent"
              />
              <FormControlLabel
                value="Present on the left"
                control={<Radio />}
                label="Left"
              />
              <FormControlLabel
                value="Present on the right"
                control={<Radio />}
                label="Right"
              />
              <FormControlLabel
                value="Present bilaterally"
                control={<Radio />}
                label="Bilateral"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Head Inspection:
          </Typography>
          <Typography variant="h6">
            Scap Tenderness: {headacheInfo.scalpTenderness}
          </Typography>
          <Typography variant="h6">
            Sinus Tenderness: {headacheInfo.sinusTenderness}
          </Typography>
          <Typography variant="h6">
            Temporal Artery Tenderness:
            {headacheInfo.temporalArteryTenderness}
          </Typography>
        </Stack>
      </Stack>

      {/* Eye Examination Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Pupillary Response</Typography>
            <RadioGroup
              value={headacheInfo.pupillaryResponse}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  pupillaryResponse: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="PERRLA"
                control={<Radio />}
                label="PERRLA (Pupils Equal, Round, Reactive to Light and Accommodation)"
              />
              <FormControlLabel
                value="Anisocoria"
                control={<Radio />}
                label="Anisocoria (unequal pupil sizes)"
              />
              <FormControlLabel
                value="Sluggish reaction"
                control={<Radio />}
                label="Sluggish reaction"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Extraocular Movements</Typography>
            <RadioGroup
              value={headacheInfo.extraocularMovements}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  extraocularMovements: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Intact"
                control={<Radio />}
                label="Intact"
              />
              <FormControlLabel
                value="Nystagmus"
                control={<Radio />}
                label="Nystagmus"
              />
              <FormControlLabel
                value="Ophthalmoplegia on the left"
                control={<Radio />}
                label="Ophthalmoplegia on the left"
              />
              <FormControlLabel
                value="Ophthalmoplegia on the right"
                control={<Radio />}
                label="Ophthalmoplegia on the right"
              />
              <FormControlLabel
                value="Ophthalmoplegia on bilaterally"
                control={<Radio />}
                label="Ophthalmoplegia on bilaterally"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Eye Examination:
          </Typography>
          <Typography variant="h6">
            Pupillary Response: {headacheInfo.pupillaryResponse}
          </Typography>
          <Typography variant="h6">
            Extraocular Movements: {headacheInfo.extraocularMovements}
          </Typography>
        </Stack>
      </Stack>

      {/* Palpation Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Temporomandibular Joint</Typography>
            <RadioGroup
              value={headacheInfo.temporomandibularJoint}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  temporomandibularJoint: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No tenderness"
                control={<Radio />}
                label="No tenderness"
              />
              <FormControlLabel
                value="Tenderness on the right"
                control={<Radio />}
                label="Tenderness on the right"
              />
              <FormControlLabel
                value="Tenderness on the left"
                control={<Radio />}
                label="Tenderness on the left"
              />
              <FormControlLabel
                value="Tenderness bilaterally"
                control={<Radio />}
                label="Tenderness bilaterally"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Neck Muscles</Typography>
            <RadioGroup
              value={headacheInfo.neckMuscles}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  neckMuscles: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No tenderness"
                control={<Radio />}
                label="No tenderness"
              />
              <FormControlLabel
                value="Tenderness on the right"
                control={<Radio />}
                label="Tenderness on the right"
              />
              <FormControlLabel
                value="Tenderness on the left"
                control={<Radio />}
                label="Tenderness on the left"
              />
              <FormControlLabel
                value="Tenderness bilaterally"
                control={<Radio />}
                label="Tenderness bilaterally"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Palpation:
          </Typography>
          <Typography variant="h6">
            Temporomandibular Joint: {headacheInfo.temporomandibularJoint}
          </Typography>
          <Typography variant="h6">
            Neck Muscles: {headacheInfo.neckMuscles}
          </Typography>
        </Stack>
      </Stack>

      {/* Neurological Examination Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Mental Status</Typography>
            <RadioGroup
              value={headacheInfo.mentalStatus}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  mentalStatus: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Alert"
                control={<Radio />}
                label="Alert"
              />
              <FormControlLabel
                value="Confused"
                control={<Radio />}
                label="Confused"
              />
              <FormControlLabel
                value="Agitated"
                control={<Radio />}
                label="Agitated"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Cranial Nerves</Typography>
            <RadioGroup
              value={headacheInfo.cranialNerves}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  cranialNerves: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="All cranial nerves intact"
                control={<Radio />}
                label="All cranial nerves intact"
              />
              <FormControlLabel
                value="Deficit in cranial nerve I"
                control={<Radio />}
                label="Deficit in cranial nerve I"
              />
              <FormControlLabel
                value="Deficit in cranial nerve II"
                control={<Radio />}
                label="Deficit in cranial nerve II"
              />
              <FormControlLabel
                value="Deficit in cranial nerve III"
                control={<Radio />}
                label="Deficit in cranial nerve III"
              />
              <FormControlLabel
                value="Deficit in cranial nerve IV"
                control={<Radio />}
                label="Deficit in cranial nerve IV"
              />
              <FormControlLabel
                value="Deficit in cranial nerve V"
                control={<Radio />}
                label="Deficit in cranial nerve V"
              />
              <FormControlLabel
                value="Deficit in cranial nerve VI"
                control={<Radio />}
                label="Deficit in cranial nerve VI"
              />
              <FormControlLabel
                value="Deficit in cranial nerve VII"
                control={<Radio />}
                label="Deficit in cranial nerve VII"
              />
              <FormControlLabel
                value="Deficit in cranial nerve VIII"
                control={<Radio />}
                label="Deficit in cranial nerve VIII"
              />
              <FormControlLabel
                value="Deficit in cranial nerve IX"
                control={<Radio />}
                label="Deficit in cranial nerve IX"
              />
              <FormControlLabel
                value="Deficit in cranial nerve X"
                control={<Radio />}
                label="Deficit in cranial nerve X"
              />
              <FormControlLabel
                value="Deficit in cranial nerve XI"
                control={<Radio />}
                label="Deficit in cranial nerve XI"
              />
              <FormControlLabel
                value="Deficit in cranial nerve XII"
                control={<Radio />}
                label="Deficit in cranial nerve XII"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Motor Examination</Typography>
            <RadioGroup
              value={headacheInfo.motorExamination}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  motorExamination: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No focal weakness"
                control={<Radio />}
                label="No focal weakness"
              />
              <FormControlLabel
                value="Focal weakness in upper extremities"
                control={<Radio />}
                label="Focal weakness in upper extremities"
              />
              <FormControlLabel
                value="Focal weakness in lower extremities"
                control={<Radio />}
                label="Focal weakness in lower extremities"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Sensory Examination</Typography>
            <RadioGroup
              value={headacheInfo.sensoryExamination}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  sensoryExamination: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal sensation"
                control={<Radio />}
                label="Normal sensation"
              />
              <FormControlLabel
                value="Sensory deficit in dermatomal distribution (nerve root pattern)"
                control={<Radio />}
                label="Sensory deficit in Dermatome"
              />
              <FormControlLabel
                value="Sensory deficit in peripheral nerve distribution"
                control={<Radio />}
                label="Sensory deficit in peripheral nerve distribution"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Reflexes</Typography>
            <RadioGroup
              value={headacheInfo.reflexes}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  reflexes: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal reflexes"
                control={<Radio />}
                label="Normal reflexes"
              />
              <FormControlLabel
                value="Hyperreflexia"
                control={<Radio />}
                label="Hyperreflexia"
              />
              <FormControlLabel
                value="Hyporeflexia"
                control={<Radio />}
                label="Hyporeflexia"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Coordination</Typography>
            <RadioGroup
              value={headacheInfo.coordination}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  coordination: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal coordination"
                control={<Radio />}
                label="Normal coordination"
              />
              <FormControlLabel
                value="Ataxia"
                control={<Radio />}
                label="Ataxia"
              />
              <FormControlLabel
                value="Dysmetria"
                control={<Radio />}
                label="Dysmetria"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Neurological Examination:
          </Typography>
          <Typography variant="h6">
            Mental Status: {headacheInfo.mentalStatus}
          </Typography>
          <Typography variant="h6">
            Cranial Nerves: {headacheInfo.cranialNerves}
          </Typography>
          <Typography variant="h6">
            Motor Examination: {headacheInfo.motorExamination}
          </Typography>
          <Typography variant="h6">
            Sensory Examination: {headacheInfo.sensoryExamination}
          </Typography>
          <Typography variant="h6">
            Reflexes: {headacheInfo.reflexes}
          </Typography>
          <Typography variant="h6">
            Coordination: {headacheInfo.coordination}
          </Typography>
        </Stack>
      </Stack>

      {/* Neck Examination Section */}
      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <Stack>
            <Typography variant="h6">Range of Motion</Typography>
            <RadioGroup
              value={headacheInfo.rangeOfMotion}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  rangeOfMotion: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="FROM"
                control={<Radio />}
                label="Full range of motion"
              />
              <FormControlLabel
                value="Limited by pain"
                control={<Radio />}
                label="Limited by pain"
              />
              <FormControlLabel
                value="Limited by stiffness"
                control={<Radio />}
                label="Limited by stiffness"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Meningeal Signs</Typography>
            <RadioGroup
              value={headacheInfo.meningealSigns}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  meningealSigns: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Negative"
                control={<Radio />}
                label="Negative"
              />
              <FormControlLabel
                value="Positive Brudzinski's sign"
                control={<Radio />}
                label="Positive Brudzinski's sign"
              />
              <FormControlLabel
                value="Positive Kernig's sign"
                control={<Radio />}
                label="Positive Kernig's sign"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Cervical Spine Tenderness</Typography>
            <RadioGroup
              value={headacheInfo.cervicalSpineTenderness}
              onChange={(e) =>
                setHeadacheInfo({
                  ...headacheInfo,
                  cervicalSpineTenderness: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No tenderness"
                control={<Radio />}
                label="No tenderness"
              />
              <FormControlLabel
                value="Tenderness on the right"
                control={<Radio />}
                label="Right"
              />
              <FormControlLabel
                value="Tenderness on the left"
                control={<Radio />}
                label="Left"
              />
              <FormControlLabel
                value="Tenderness bilaterally"
                control={<Radio />}
                label="Both sides"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Neck Examination:
          </Typography>
          <Typography variant="h6">
            Range of Motion: {headacheInfo.rangeOfMotion}
          </Typography>
          <Typography variant="h6">
            Meningeal Signs: {headacheInfo.meningealSigns}
          </Typography>
          <Typography variant="h6">
            Cervical Spine Tenderness: {headacheInfo.cervicalSpineTenderness}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HeadPhysicalExam;
