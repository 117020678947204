import React, { useContext, useEffect, useState } from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  IconButton,
  Typography,
  TextField,
  Input,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Close as CloseIcon, Edit as EditIcon } from "@mui/icons-material";
import { Context } from "../../context";
import { secureApi } from "../../config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PMH = ({ pmh }) => {
  const navigate = useNavigate();
  const { pastMedicalHistory, setPastMedicalHistory, setLoggedIn } =
    useContext(Context);
  const [medicalProblems, setMedicalProblems] = useState([
    "Obesity",
    "Hypertension",
    "Type 2 Diabetes",
    "CAD",
    "Asthma",
    "COPD",
    "Major Depressive Disorder",
    "Generalized Anxiety Disorder",
    "Hepatitis C",
    "Hyperlipidemia",
    "Chronic Kidney Disease",
    "GERD",
    "Hypothyroidism",
  ]);

  const [pmhInfoStatus, setPmhInfoStatus] = useState(null);
  const [inputStatus, setInputStatus] = useState(true);
  const [editStatus, setEditStatus] = useState([]);

  useEffect(() => {
    if (pmh) {
      setPastMedicalHistory(pmh);
      let customMedicalProb = [];
      for (let i = 0; i < pmh.length; i++) {
        if (!medicalProblems.includes(pmh[i])) customMedicalProb.push(pmh[i]);
      }
      setMedicalProblems([...medicalProblems, ...customMedicalProb]);
    }
  }, [pmh]);

  const handleSelectProblem = (event) => {
    const { value: medicalProb, checked } = event.target;

    if (pastMedicalHistory.indexOf(medicalProb) >= 0 && checked === false) {
      let newMedicalHistory = [...pastMedicalHistory];
      newMedicalHistory.splice(pastMedicalHistory.indexOf(medicalProb), 1);
      setPastMedicalHistory(newMedicalHistory);
    } else if (
      pastMedicalHistory.indexOf(medicalProb) < 0 &&
      checked === true
    ) {
      let newMedicalHistory = [...pastMedicalHistory, medicalProb];
      setPastMedicalHistory(newMedicalHistory);
    }
  };

  const handleKeyDown = (event) => {
    const medicalProb = event.target.value.trim();

    if (event.keyCode === 13 && medicalProb) {
      setInputStatus(false);
      secureApi(window.localStorage.getItem("alex-med-token"))
        .post(`/get_pmh`, { medicalProb })
        .then((response) => {
          setInputStatus(true);
          const diagnosis = response.data;
          if (diagnosis === "no") setPmhInfoStatus(3);
          else {
            if (medicalProblems.indexOf(diagnosis) > 0) setPmhInfoStatus(2);
            else {
              setPmhInfoStatus(1);
              setMedicalProblems([...medicalProblems, diagnosis]);
            }
          }
        })
        .catch((e) => {
          setInputStatus(true);
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
  };

  const handleKeyDownForProb = (event, index) => {
    if (event.keyCode === 13) {
      const medicalProb = event.target.value.trim();

      if (pastMedicalHistory.indexOf(medicalProblems[index]) >= 0) {
        let newPastMedicalHistory = [...pastMedicalHistory];
        newPastMedicalHistory[
          pastMedicalHistory.indexOf(medicalProblems[index])
        ] = medicalProb;
        setPastMedicalHistory(newPastMedicalHistory);
      }

      let newMedicalProbs = [...medicalProblems];
      newMedicalProbs[index] = medicalProb;
      setMedicalProblems(newMedicalProbs);

      let newEditStatus = [...editStatus];
      newEditStatus[index] = false;
      setEditStatus(newEditStatus);
    }
  };

  const removeMedicalProb = (prob) => {
    let newMedicalProbs = [...medicalProblems];
    newMedicalProbs.splice(medicalProblems.indexOf(prob), 1);
    setMedicalProblems(newMedicalProbs);

    const index = pastMedicalHistory.indexOf(prob);
    if (index >= 0) {
      let newPastMedicalHistory = [...pastMedicalHistory];
      newPastMedicalHistory.splice(index, 1);
      setPastMedicalHistory(newPastMedicalHistory);
    }
  };

  const editMedicalProb = (index) => {
    let newEditStatus = [...editStatus];
    newEditStatus[index] = true;
    setEditStatus(newEditStatus);
  };

  return (
    <Stack direction="row" spacing={5}>
      <FormGroup sx={{ width: "30%" }}>
        {medicalProblems &&
          medicalProblems.map((prob, index) => (
            <React.Fragment key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleSelectProblem(e)}
                    value={prob}
                    checked={pastMedicalHistory.includes(prob)}
                  />
                }
                key={index}
                label={
                  <>
                    {prob}
                    {index > 12 && (
                      <>
                        <IconButton
                          variant="outlined"
                          style={{ marginLeft: "20px" }}
                          onClick={() => removeMedicalProb(prob)}
                        >
                          <CloseIcon />
                        </IconButton>
                        <IconButton
                          variant="outlined"
                          style={{ marginLeft: "20px" }}
                          onClick={() => editMedicalProb(index)}
                        >
                          <EditIcon />
                        </IconButton>
                      </>
                    )}
                  </>
                }
              />
              {index > 12 && editStatus[index] && (
                <Input
                  defaultValue={prob}
                  style={{ marginBottom: "10px" }}
                  onKeyDown={(e) => handleKeyDownForProb(e, index)}
                ></Input>
              )}
            </React.Fragment>
          ))}

        <TextField
          variant="outlined"
          label="Medical Problem"
          color="primary"
          size="medium"
          fullWidth
          onKeyDown={handleKeyDown}
          disabled={!inputStatus}
        />
        {pmhInfoStatus === 1 && (
          <Alert
            severity="success"
            onClose={() => {
              setPmhInfoStatus(null);
            }}
          >
            <AlertTitle>Success</AlertTitle>
            New medical jargon has been added!
          </Alert>
        )}
        {pmhInfoStatus === 2 && (
          <Alert
            severity="warning"
            onClose={() => {
              setPmhInfoStatus(null);
            }}
          >
            <AlertTitle>Warning</AlertTitle>
            That diagnosis already exists!
          </Alert>
        )}
        {pmhInfoStatus === 3 && (
          <Alert
            severity="error"
            onClose={() => {
              setPmhInfoStatus(null);
            }}
          >
            <AlertTitle>Error</AlertTitle>
            Input is invalid for a medical jargon!
          </Alert>
        )}
      </FormGroup>
      <Stack direction="column" sx={{ width: "70%" }}>
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          PAST MEDICAL HISTORY:
        </Typography>
        {pastMedicalHistory &&
          pastMedicalHistory.map((prob, idx) => (
            <Typography variant="h6" key={idx}>
              &nbsp;{prob}
            </Typography>
          ))}
      </Stack>
    </Stack>
  );
};

export default PMH;
