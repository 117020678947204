import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TablePagination,
  Paper,
  Stack,
  Chip,
  Button,
  TextField,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { debounce } from "@mui/material/utils";
import { toast } from "react-toastify";
import TopNav from "../components/topnav";
import { secureApi, ADMIN } from "../config";
import { Context } from "../context";
import { useNavigate } from "react-router-dom";
import UserModal from "../components/users/userModal";
import DeleteModal from "../components/users/deleteModal";

function createData(id, name, email, role, status) {
  return {
    id,
    name,
    email,
    role,
    status,
  };
}

const headCells = [
  {
    id: "no",
    label: "No",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "role",
    label: "Role",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "operation",
    label: "Operation",
  },
];

export default function Users() {
  const { setLoggedIn, user } = useContext(Context);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(10);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userID, setUserID] = useState();
  const [searchStr, setSearchStr] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddButtonClick = () => {
    setUserID(null);
    setUserModalOpen(true);
  };

  const handleCancel = () => {
    setUserModalOpen(false);
    setUserID(null);
  };

  const handleEditButtonClick = (event, user_id) => {
    setUserID(user_id);
    setUserModalOpen(true);
  };

  const handleDeleteButtonClick = (event, user_id) => {
    setUserID(user_id);
    setDeleteModalOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
  };

  useEffect(() => {
    secureApi(window.localStorage.getItem("alex-med-token"))
      .post(`/users`, {
        page,
        rowsPerPage,
        searchStr,
      })
      .then((response) => {
        let { users, total_users } = response.data;
        users = users.map((user) =>
          createData(
            user["_id"],
            user["name"],
            user["email"],
            user["role"],
            user["status"]
          )
        );
        setUsers(users);
        setTotalUsers(total_users);
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          toast.error(e.response.data.message);
        }
      });
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!user || user.role !== ADMIN) {
      toast.warn("You don't have permission to access this page!");
      navigate("/");
    }
  }, [user]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalUsers) : 0;

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        secureApi(window.localStorage.getItem("alex-med-token"))
          .post(`/users`, {
            page: 0,
            rowsPerPage,
            searchStr: request.searchStr,
          })
          .then((response) => {
            callback(response.data);
          })
          .catch((e) => {
            console.log(e);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else {
              toast.error(e.response.data.message);
            }
          });
      }, 400),
    []
  );

  useEffect(() => {
    let active = true;

    fetch({ searchStr: searchStr }, (results) => {
      if (active) {
        let { users, total_users } = results;
        users = users.map((user) =>
          createData(
            user["_id"],
            user["name"],
            user["email"],
            user["role"],
            user["status"]
          )
        );
        setUsers(users);
        setTotalUsers(total_users);
        setPage(0);
      }
    });

    return () => {
      active = false;
    };
  }, [searchStr, fetch]);

  return (
    <>
      <TopNav item="users" />
      <Box margin="30px">
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%" }}>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                style={{ marginBottom: "10px" }}
                onClick={handleAddButtonClick}
              >
                Add New User
              </Button>
            </Stack>
          </div>
          <TextField
            size="small"
            label="Search"
            variant="outlined"
            style={{ float: "right" }}
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
          />
        </div>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"medium"}
            >
              <TableHead>
                <TableRow style={{ background: "grey" }}>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      padding={"normal"}
                      style={{ color: "white" }}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.role}</TableCell>
                      <TableCell>
                        {row.status === 1 ? (
                          <Chip label="active" color="success" />
                        ) : (
                          <Chip label="inactive" color="warning" />
                        )}
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={1}>
                          <Button
                            color="secondary"
                            variant="contained"
                            endIcon={<EditIcon />}
                            onClick={(e) => handleEditButtonClick(e, row["id"])}
                          >
                            Edit
                          </Button>
                          <Button
                            color="error"
                            variant="contained"
                            endIcon={<DeleteIcon />}
                            onClick={(e) =>
                              handleDeleteButtonClick(e, row["id"])
                            }
                          >
                            Delete
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {totalUsers < 1 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell
                      colSpan={9}
                      style={{ textAlign: "center", fontWeight: "bold" }}
                    >
                      No users yet
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalUsers}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <UserModal
          open={userModalOpen}
          handleCancel={handleCancel}
          userID={userID}
        />
        <DeleteModal
          open={deleteModalOpen}
          handleCancel={handleDeleteCancel}
          userID={userID}
        />
      </Box>
    </>
  );
}
