import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const DeleteNoteModal = ({ open, handleCancel, deleteNote }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog fullScreen={fullScreen} open={open} fullWidth maxWidth='md'>
      <DialogTitle>
        Are you sure to delete the note?
      </DialogTitle>
      <IconButton aria-label="close" onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <DialogContentText>
          If you agree, the note will be deleted permanently!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={deleteNote} autoFocus variant='contained'>
          Agree
        </Button>
        <Button onClick={handleCancel} variant='contained' color='error'>
          Disagree
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteNoteModal;