import React, { useState } from "react";
import {
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormGroup,
  FormControl,
  RadioGroup,
  Chip,
  Input,
} from "@mui/material";
import { toast } from "react-toastify";
import { CHRONIC_PAINS } from "../../config";

const neckPainGeneralDefault = {
  generalAppearance: "NAD",
  deepTouch: "intact bilaterally",
  fromNerveDistribution: "C4",
  toNerveDistribution: "T1",
  bilateralBR_Bicep: "2+",
  hoffman: "negative",
  shoulder: "Full",
  hawkinsSpecialTest: "negative bilaterally",
  emptyCanSpecialTest: "negative bilaterally",
  neersSpecialTest: "negative bilaterally",
  tinnelsSpecialTest: "negative bilaterally",
  cervicalParaspinals:
    "tender bilaterally with taut muscle bellies reproducing referred pain",
  levatorScapula:
    "tender bilaterally with taut muscle bellies reproducing referred pain",
  trapezius:
    "tender bilaterally with taut muscle bellies reproducing referred pain",
  bicipitalTendon: "non-tender bilaterally",
  shoulderAbductionLeft: 5,
  shoulderAbductionRight: 5,
  elbowFlexionLeft: 5,
  elbowFlexionRight: 5,
  wristExtensionLeft: 5,
  wristExtensionRight: 5,
  elbowExtensionLeft: 5,
  elbowExtensionRight: 5,
  handIntrinsicsLeft: 5,
  handIntrinsicsRight: 5,
  firstDorsalInterosseusLeft: 5,
  firstDorsalInterosseusRight: 5,
  abductorPollicusBrevisLeft: 5,
  abductorPollicusBrevisRight: 5,
};

const FollowupHPI = ({
  dateOfPainConsultNote,
  followupHPI,
  pastFollowupNotes,
  setFollowupHPI,
  initialHPI,
  pains,
  setPains,
  selectedPainIndex,
  setSelectedPainIndex,
  selectedPainKey,
  setSelectedPainKey,
  physicalExams,
  setPhysicalExams,
}) => {
  const [content, setContent] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setContent(followupHPI);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleUpdate = () => {
    setFollowupHPI(content);
    setOpen(false);
  };

  const addPain = (painKey) => {
    // Not custom pain and the painKey already exists
    if (
      painKey !== "customPain" &&
      pains.map((pain) => pain["key"]).indexOf(painKey) !== -1
    ) {
      toast.warn("That pain already selected!");
    } else {
      setSelectedPainKey(painKey);
      setSelectedPainIndex(pains.length);
      setPains([
        ...pains,
        {
          key: painKey,
          name: CHRONIC_PAINS[painKey],
          hpi: "",
        },
      ]);

      switch (painKey) {
        case "headache":
          setPhysicalExams([
            ...physicalExams,
            {
              scalpTenderness: "Absent",
              sinusTenderness: "Absent",
              temporalArteryTenderness: "Absent",
              pupillaryResponse: "PERRLA",
              extraocularMovements: "Intact",
              temporomandibularJoint: "No tenderness",
              neckMuscles: "No tenderness",
              mentalStatus: "Alert",
              cranialNerves: "All cranial nerves intact",
              motorExamination: "No focal weakness",
              sensoryExamination: "Normal sensation",
              reflexes: "Normal reflexes",
              coordination: "Normal coordination",
              rangeOfMotion: "FROM",
              meningealSigns: "Negative",
              cervicalSpineTenderness: "No tenderness",
            },
          ]);
          break;
        case "neckPain":
          setPhysicalExams([
            ...physicalExams,
            {
              ...neckPainGeneralDefault,
              lightTouch: "intact bilaterally",
              leftTricep: "2+",
              rightTricep: "2+",
              cervical: {
                full: true,
                limitedFlexion: false,
                limitedExtension: false,
                limitedRotation: false,
                limitedLateralBending: false,
              },
              kempsSpecialTest: "negative bilaterally",
              spurlingsSpecialTest: "negative bilaterally",
              shoulderExternalRotationLeft: 4,
              shoulderExternalRotationRight: 4,
            },
          ]);
          break;
        case "cervicalDystonia":
          setPhysicalExams([
            ...physicalExams,
            {
              generalAppearance: "NAD",
              lightTouch: "intact bilaterally",
              deepTouch: "intact bilaterally",
              fromNerveDistribution: "C4",
              toNerveDistribution: "T1",
              leftTricep: "2+",
              rightTricep: "2+",
              bilateralBR_Bicep: "2+",
              hoffman: "negative",
              cervical: {
                full: true,
                limitedFlexion: false,
                limitedExtension: false,
                limitedRotation: false,
                limitedLateralBending: false,
              },
              shoulder: "Full",
              headPosturing: {
                normal: true,
                torticollis: false,
                laterocollis: false,
                anterocollis: false,
                retrocollis: false,
                sagittalShift: false,
                lateralShift: false,
              },
              kempsSpecialTest: "negative bilaterally",
              hawkinsSpecialTest: "negative bilaterally",
              emptyCanSpecialTest: "negative bilaterally",
              neersSpecialTest: "negative bilaterally",
              tinnelsSpecialTest: "negative bilaterally",
              spurlingsSpecialTest: "negative bilaterally",
              cervicalParaspinals:
                "tender bilaterally with taut muscle bellies reproducing referred pain",
              levatorScapula:
                "tender bilaterally  with taut muscle bellies reproducing referred pain",
              trapezius:
                "tender bilaterally with taut muscle bellies reproducing referred pain",
              bicipitalTendon: "non-tender bilaterally",
              shoulderAbductionLeft: 5,
              shoulderAbductionRight: 5,
              elbowFlexionLeft: 5,
              elbowFlexionRight: 5,
              wristExtensionLeft: 5,
              wristExtensionRight: 5,
              elbowExtensionLeft: 5,
              elbowExtensionRight: 5,
              handIntrinsicsLeft: 5,
              handIntrinsicsRight: 5,
              firstDorsalInterosseusLeft: 5,
              firstDorsalInterosseusRight: 5,
              abductorPollicusBrevisLeft: 5,
              abductorPollicusBrevisRight: 5,
              shoulderExternalRotationLeft: 4,
              shoulderExternalRotationRight: 4,
            },
          ]);
          break;
        case "backPain":
          setPhysicalExams([
            ...physicalExams,
            {
              generalAppearance: "NAD",
              gait: "antalgic",
              lightTouch: "intact bilaterally",
              deepTouch: "intact bilaterally",
              fromNerveDistribution: "L2",
              toNerveDistribution: "S1",
              leftPatella: "2+",
              rightPatella: "2+",
              achilles: "2+",
              babinskiSign: "Negative bilaterally",
              lumbar: {
                full: false,
                limitedFlexion: true,
                limitedExtension: false,
                limitedRotation: false,
                limitedLateralBending: false,
              },
              slrSpecialTest: "negative bilaterally",
              kempsSpecialTest: "negative bilaterally",
              faberSpecialTest: "negative bilaterally",
              fadirSpecialTest: "negative bilaterally",
              erectorSpinae:
                "tender bilaterally with taut muscle bellies reproducing referred pain.",
              iliopsoas:
                "tender bilaterally with taut muscle bellies reproducing referred pain.",
              quadratusLumborum:
                "tender bilaterally with taut muscle bellies reproducing referred pain.",
              greaterTrochanters: "non-tender bilaterally",
              sacroiliacJoint: "non-tender bilaterally",
              hipFlexionLeft: 5,
              hipFlexionRight: 5,
              kneeExtensionLeft: 5,
              kneeExtensionRight: 5,
              ankleDorsiflexionLeft: 5,
              ankleDorsiflexionRight: 5,
              greatToeExtensionLeft: 5,
              greatToeExtensionRight: 5,
              inversionLeft: 5,
              inversionRight: 5,
              eversionLeft: 5,
              eversionRight: 5,
              hipAbductionLeft: 4,
              hipAbductionRight: 4,
              hipExtensionLeft: 4,
              hipExtensionRight: 4,
              plantarFlexionLeft: 5,
              plantarFlexionRight: 5,
            },
          ]);
          break;
        case "shoulderPain":
          setPhysicalExams([
            ...physicalExams,
            {
              laterality: "Right shoulder",
              appearance: "No visible abnormality",
              skin: "Normal",
              bonyLandmarks: "No tenderness",
              softTissue: "No tenderness",
              active: "Full range without pain",
              passive: "Full range without pain",
              deltoidMuscle: "Normal strength",
              rotatorCuffMuscles: "Normal strength",
              impingementSigns: "Positive Neer's sign",
              labralTears: "Positive O'Brien's test",
              instability: "Positive apprehension sign",
              sensation: "Intact",
              pulses: "Intact brachial pulse",
            },
          ]);
          break;
        case "elbowPain":
          setPhysicalExams([
            ...physicalExams,
            {
              laterality: "Right elbow",
              appearance: "No visible abnormality",
              skin: "Normal",
              bonyLandmarks: "No tenderness",
              softTissue: "No tenderness",
              active: "Full range without pain",
              passive: "Full range without pain",
              bicepsMuscle: "Normal strength",
              tricepsMuscle: "Normal strength",
              wristExtensors: "Normal strength",
              epicondylitis: "Positive Cozen's test",
              nerveEntrapment: "Positive Tinel's sign at elbow",
              sensation: "Intact",
              pulses: "Intact brachial pulse",
            },
          ]);
          break;
        case "wristPain":
          setPhysicalExams([
            ...physicalExams,
            {
              laterality: "Right wrist",
              appearance: "No visible abnormality",
              skin: "Normal",
              bonyLandmarks: "No tenderness",
              softTissue: "No tenderness",
              active: "Full range without pain",
              passive: "Full range without pain",
              gripStrength: "Normal strength",
              wristFlexors: "Normal strength",
              wristExtensors: "Normal strength",
              deQuervainsTenosynovitis: "Positive Finkelstein's test",
              carpalTunnelSyndrome: "Positive Tinel's sign at wrist",
              sensation: "Intact",
              pulses: "Intact brachial pulse",
            },
          ]);
          break;
        case "kneePain":
          setPhysicalExams([
            ...physicalExams,
            {
              laterality: "Right knee",
              appearance: "No visible abnormality",
              skin: "Normal",
              bonyLandmarks: "No tenderness",
              softTissue: "No tenderness",
              active: "Full range without pain",
              passive: "Full range without pain",
              quadricepsMuscle: "Normal strength",
              hamstringsMuscle: "Normal strength",
              meniscalInjury: "Positive McMurray's test",
              ligamentousInjury: "Positive Lachman test",
              sensation: "Intact",
              pulses: "Intact popliteal pulse",
            },
          ]);
          break;
        default:
          setPhysicalExams([...physicalExams, ""]);
      }
    }
  };

  const selectPain = (painIndex) => {
    setSelectedPainIndex(painIndex);
    setSelectedPainKey(pains[painIndex]["key"]);
  };

  const removePain = (index) => {
    const newPains = [...pains];
    newPains.splice(index, 1);
    setPains(newPains);
    setSelectedPainIndex(0);
    if (newPains.length) {
      setSelectedPainKey(newPains[0]["key"]);
    } else {
      setSelectedPainKey(null);
    }

    const newPhysicalExams = [...physicalExams];
    newPhysicalExams.splice(index, 1);
    setPhysicalExams(newPhysicalExams);
  };

  const handleCustomPainNameChange = (newName) => {
    const newPains = [...pains];
    newPains[selectedPainIndex] = {
      ...pains[selectedPainIndex],
      name: newName,
    };
    setPains(newPains);
  };

  return (
    <Stack direction="column">
      <Stack direction="row">
        <FormGroup
          style={{ width: "200px", minWidth: "200px", marginRight: "10px" }}
        >
          <FormControl>
            <RadioGroup>
              {Object.keys(CHRONIC_PAINS).map((pain, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  onClick={() => addPain(pain)}
                >
                  {CHRONIC_PAINS[pain]}
                </Button>
              ))}
            </RadioGroup>
            {selectedPainKey === "customPain" && (
              <Input
                placeholder="Pain"
                variant="outlined"
                style={{ margin: "10px 0" }}
                value={pains[selectedPainIndex]["name"]}
                onChange={(e) => handleCustomPainNameChange(e.target.value)}
              />
            )}
          </FormControl>
        </FormGroup>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={1}>
            <Typography variant="h5" fontWeight="bold">
              Chief Complaint:
            </Typography>
            <Stack direction="column" spacing={1}>
              <Typography variant="h6">
                {pains.map((pain) => pain["name"]).join(", ")}
              </Typography>
              <Stack direction="row" spacing={1}>
                {pains &&
                  pains.map((pain, index) => (
                    <Chip
                      color={
                        selectedPainIndex === index ? "success" : "primary"
                      }
                      key={index}
                      label={pain["name"]}
                      onDelete={() => removePain(index)}
                      onClick={() => {
                        selectPain(index);
                      }}
                    />
                  ))}
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="column" style={{ marginTop: "20px" }}>
            <Typography variant="h5" fontWeight="bold">
              HPI:
            </Typography>
            <Stack direction="column">
              <Typography variant="h6" fontWeight="bold">
                Follow-up HPI:
              </Typography>
              <Stack direction="row">
                <FormGroup
                  style={{
                    minWidth: "250px",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  <Button variant="contained" onClick={handleOpen}>
                    Edit
                  </Button>
                </FormGroup>
                <Stack direction="column">
                  {followupHPI.split("\n").map((item, index) => (
                    <Typography variant="h6" key={index}>
                      {item}
                    </Typography>
                  ))}
                </Stack>
              </Stack>
            </Stack>
            {pastFollowupNotes &&
              pastFollowupNotes.map((note) => (
                <Stack
                  direction="column"
                  style={{ marginTop: "20px" }}
                  key={note["_id"]}
                >
                  <Typography variant="h6" fontWeight="bold">
                    Followup HPI ({note["dateOfService"]}):
                  </Typography>
                  {note["followupHPI"].split("\n").map((item, index) => (
                    <Typography variant="h6" key={index}>
                      {item}
                    </Typography>
                  ))}
                </Stack>
              ))}
            <Stack direction="column" style={{ marginTop: "20px" }}>
              <Typography variant="h6" fontWeight="bold">
                Initial HPI ({dateOfPainConsultNote}):
              </Typography>
              {initialHPI &&
                initialHPI.split("\n").map((item, index) => (
                  <Typography variant="h6" key={index}>
                    {item}
                  </Typography>
                ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
        <DialogTitle>Edit Follow Up HPI</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Content"
            multiline
            rows={15}
            fullWidth
            variant="standard"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdate}>OK</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default FollowupHPI;
