import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Context } from "../context";
import {
  MEDICAL_ASSISTANT,
  MEDICAL_BILLING_PERSON,
  PAIN_CONSULT_NOTE_TYPE,
  TELEHEALTH_CONSULT_NOTE_TYPE,
  secureApi,
} from "../config";
import InitialConsultNote from "./initial_consult_note";
import dayjs from "dayjs";

const now = new Date();
const year = now.getFullYear();
const month = now.getMonth() + 1;
const day = now.getDate();
const hour = now.getHours();
const minute = now.getMinutes();

const PainConsultNote = ({ is_telehealth_consult_note }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const patient_id = params.get("patient_id");

  const { setLoggedIn, user } = useContext(Context);

  const navigate = useNavigate();

  if (user && user.role === MEDICAL_BILLING_PERSON) {
    toast.error("You don't have permission to access this page!");
    navigate("/");
  }

  const [patientName, setPatientName] = useState("");
  const [gender, setGender] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfAccident, setDateOfAccident] = useState("");
  const [dateOfFirstVisit, setDateOfFirstVisit] = useState("");
  const [dateOfService, setDateOfService] = useState(
    dayjs(`${month}/${day}/${year}`)
  );
  const [timeOfService, setTimeOfService] = useState(
    dayjs(`${month}/${day}/${year} ${hour}:${minute}`)
  );
  const [note, setNote] = useState(null);
  const [provider, setProvider] = useState("Alexander Movshis, M.D");
  const [imagesReviewedInfo, setImagesReviewedInfo] = useState({});

  useEffect(() => {
    if (patient_id) {
      const initial_note_type = is_telehealth_consult_note
        ? TELEHEALTH_CONSULT_NOTE_TYPE
        : PAIN_CONSULT_NOTE_TYPE;

      secureApi(window.localStorage.getItem("alex-med-token"))
        .get(`/initial-consult-note/${patient_id}/${initial_note_type}`)
        .then((response) => {
          const {
            patient_name,
            date_of_birth,
            date_of_accident,
            date_of_first_visit,
            gender,
            note,
          } = response.data;
          setPatientName(patient_name);
          setDateOfBirth(date_of_birth);
          setDateOfAccident(date_of_accident);
          setDateOfFirstVisit(date_of_first_visit);
          setGender(gender);
          if (note) {
            if (
              user &&
              user.role === MEDICAL_ASSISTANT &&
              note.status === "signed"
            ) {
              toast.error("You don't have permission to modify signed notes");
              navigate("/");
            } else {
              setProvider(note.provider);
              setDateOfService(dayjs(note.dateOfService));
              setTimeOfService(
                dayjs(`${note.dateOfService} ${note.timeOfService}`)
              );
              setNote(note);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else {
      toast.error("Patient ID is incorrect");
    }
  }, [patient_id]);

  return (
    <InitialConsultNote
      patient_id={patient_id}
      patientName={patientName}
      gender={gender}
      dateOfBirth={dateOfBirth}
      dateOfAccident={dateOfAccident}
      dateOfFirstVisit={dateOfFirstVisit}
      dateOfService={dateOfService}
      timeOfService={timeOfService}
      setDateOfService={setDateOfService}
      setTimeOfService={setTimeOfService}
      note={note}
      provider={provider}
      setProvider={setProvider}
      imagesReviewedInfo={imagesReviewedInfo}
      setImagesReviewedInfo={setImagesReviewedInfo}
      is_telehealth_consult_note={is_telehealth_consult_note}
    />
  );
};

export default PainConsultNote;
