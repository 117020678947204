import React, { useState, useContext, useEffect } from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  IconButton,
  Typography,
  Input,
  TextField,
  Alert,
  AlertTitle,
} from "@mui/material";
import { Close as CloseIcon, Edit as EditIcon } from "@mui/icons-material";
import { Context } from "../../context";
import { secureApi } from "../../config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const PSH = ({ psh }) => {
  const navigate = useNavigate();
  const { pastSurgicalHistory, setPastSurgicalHistory, setLoggedIn } =
    useContext(Context);
  const [surgicalProblems, setSurgicalProblems] = useState([
    "Caesarean Section",
    "Knee Replacement",
    "Hip Replacement",
    "Appendectomy",
    "Cholecystectomy",
    "Hernia Repair",
    "Coronary Stent",
    "Lumbar Spinal Fusion",
    "Carpal Tunnel Release",
    "Breast Augmentation",
    "Rhinoplasty",
    "Bariatric Surgery",
    "ACL Repair",
    "Tonsillectomy",
    "Knee Arthroscopy",
    "Shoulder Arthroscopy",
  ]);
  const [pshInfoStatus, setPshInfoStatus] = useState(null);
  const [inputStatus, setInputStatus] = useState(true);
  const [editStatus, setEditStatus] = useState([]);

  useEffect(() => {
    if (psh) {
      setPastSurgicalHistory(psh);
    }
  }, [psh]);

  useEffect(() => {
    if (psh) {
      setPastSurgicalHistory(psh);
      let customSurgicalProb = [];
      for (let i = 0; i < psh.length; i++) {
        if (!surgicalProblems.includes(psh[i])) customSurgicalProb.push(psh[i]);
      }
      setSurgicalProblems([...surgicalProblems, ...customSurgicalProb]);
    }
  }, [psh]);

  const handleSelectProblem = (event) => {
    const { value: surgicalProb, checked } = event.target;

    if (pastSurgicalHistory.indexOf(surgicalProb) >= 0 && checked === false) {
      let newSurgicalHistory = [...pastSurgicalHistory];
      newSurgicalHistory.splice(pastSurgicalHistory.indexOf(surgicalProb), 1);
      setPastSurgicalHistory(newSurgicalHistory);
    } else if (
      pastSurgicalHistory.indexOf(surgicalProb) < 0 &&
      checked === true
    ) {
      let newSurgicalHistory = [...pastSurgicalHistory, surgicalProb];
      setPastSurgicalHistory(newSurgicalHistory);
    }
  };

  const handleKeyDown = (event) => {
    const surgicalProb = event.target.value.trim();

    if (event.keyCode === 13 && surgicalProb) {
      setInputStatus(false);
      secureApi(window.localStorage.getItem("alex-med-token"))
        .post(`/get_psh`, { surgicalProb })
        .then((response) => {
          setInputStatus(true);
          const diagnosis = response.data;
          if (diagnosis === "no") setPshInfoStatus(3);
          else {
            if (surgicalProblems.indexOf(diagnosis) > 0) setPshInfoStatus(2);
            else {
              setPshInfoStatus(1);
              setSurgicalProblems([...surgicalProblems, diagnosis]);
            }
          }
        })
        .catch((e) => {
          setInputStatus(true);
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
  };

  const handleKeyDownForProb = (event, index) => {
    if (event.keyCode === 13) {
      const surgicalProb = event.target.value.trim();

      if (pastSurgicalHistory.indexOf(surgicalProblems[index]) >= 0) {
        let newPastSurgicalHistory = [...pastSurgicalHistory];
        newPastSurgicalHistory[
          pastSurgicalHistory.indexOf(surgicalProblems[index])
        ] = surgicalProb;
        setPastSurgicalHistory(newPastSurgicalHistory);
      }

      let newSurgicalProbs = [...surgicalProblems];
      newSurgicalProbs[index] = surgicalProb;
      setSurgicalProblems(newSurgicalProbs);

      let newEditStatus = [...editStatus];
      newEditStatus[index] = false;
      setEditStatus(newEditStatus);
    }
  };

  const removeSurgicalProb = (prob) => {
    let newSurgicalProbs = [...surgicalProblems];
    newSurgicalProbs.splice(surgicalProblems.indexOf(prob), 1);
    setSurgicalProblems(newSurgicalProbs);

    const index = pastSurgicalHistory.indexOf(prob);
    if (index >= 0) {
      let newPastSurgicalHistory = [...pastSurgicalHistory];
      newPastSurgicalHistory.splice(index, 1);
      setPastSurgicalHistory(newPastSurgicalHistory);
    }
  };

  const editSurgicalProb = (index) => {
    let newEditStatus = [...editStatus];
    newEditStatus[index] = true;
    setEditStatus(newEditStatus);
  };

  return (
    <Stack direction="row" spacing={5}>
      <FormGroup sx={{ width: "30%" }}>
        {surgicalProblems &&
          surgicalProblems.map((prob, index) => (
            <React.Fragment key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => handleSelectProblem(e)}
                    value={prob}
                  />
                }
                key={index}
                checked={pastSurgicalHistory.includes(prob)}
                label={
                  <>
                    {prob}
                    {index > 15 && (
                      <>
                        <IconButton
                          variant="outlined"
                          style={{ marginLeft: "20px" }}
                          onClick={() => removeSurgicalProb(prob)}
                        >
                          <CloseIcon />
                        </IconButton>
                        <IconButton
                          variant="outlined"
                          style={{ marginLeft: "20px" }}
                          onClick={() => editSurgicalProb(index)}
                        >
                          <EditIcon />
                        </IconButton>
                      </>
                    )}
                  </>
                }
              />
              {index > 15 && editStatus[index] && (
                <Input
                  defaultValue={prob}
                  style={{ marginBottom: "10px" }}
                  onKeyDown={(e) => handleKeyDownForProb(e, index)}
                ></Input>
              )}
            </React.Fragment>
          ))}

        <TextField
          variant="outlined"
          label="Surgical Problem"
          color="primary"
          size="medium"
          fullWidth
          onKeyDown={handleKeyDown}
          disabled={!inputStatus}
        />
        {pshInfoStatus === 1 && (
          <Alert
            severity="success"
            onClose={() => {
              setPshInfoStatus(null);
            }}
          >
            <AlertTitle>Success</AlertTitle>
            New medical jargon has been added!
          </Alert>
        )}
        {pshInfoStatus === 2 && (
          <Alert
            severity="warning"
            onClose={() => {
              setPshInfoStatus(null);
            }}
          >
            <AlertTitle>Warning</AlertTitle>
            That diagnosis already exists!
          </Alert>
        )}
        {pshInfoStatus === 3 && (
          <Alert
            severity="error"
            onClose={() => {
              setPshInfoStatus(null);
            }}
          >
            <AlertTitle>Error</AlertTitle>
            Input is invalid for a medical jargon!
          </Alert>
        )}
      </FormGroup>
      <Stack direction="column" sx={{ width: "70%" }}>
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", textDecoration: "underline" }}
        >
          PAST SURGICAL HISTORY:
        </Typography>
        {pastSurgicalHistory &&
          pastSurgicalHistory.map((prob, idx) => (
            <Typography variant="h6" key={idx}>
              &nbsp;{prob}
            </Typography>
          ))}
      </Stack>
    </Stack>
  );
};

export default PSH;
