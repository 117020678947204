import React, { useState } from "react";
import {
  Typography,
  Stack,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

const InterimHPI = ({ interimHPI, setInterimHPI }) => {
  const [content, setContent] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setContent(interimHPI);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleUpdate = () => {
    setInterimHPI(content);
    handleClose();
  };

  return (
    <Stack direction="row" style={{ marginTop: "30px" }}>
      <Stack>
        <Button
          style={{ width: "250px", marginRight: "10px", marginTop: "30px" }}
          variant="contained"
          onClick={handleOpen}
        >
          Edit
        </Button>
      </Stack>
      <Stack direction="column">
        <Typography variant="h6" align="left" style={{ fontWeight: "bold" }}>
          Interim HPI
        </Typography>
        {interimHPI.split("\n").map((item, index) => (
          <Typography key={index}>{item}</Typography>
        ))}
      </Stack>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth={true}>
        <DialogTitle>Edit Interim HPI</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Content"
            multiline
            rows={7}
            fullWidth
            variant="standard"
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUpdate}>OK</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default InterimHPI;
