import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  useMediaQuery,
  TextField,
  InputLabel,
  Select,
  Grid,
  MenuItem,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import { secureApi } from "../../config";
import { Context } from "../../context";

const EditModal = ({
  billing,
  open,
  billingID,
  dateOfServiceIdx,
  procedureIdx,
  billingLevel,
  handleClose,
  handleUpdateBilling,
  setBilling,
}) => {
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const updateBilling = () => {
    secureApi(window.localStorage.getItem("alex-med-token"))
      .put(
        `/billing/${billingID}/${dateOfServiceIdx}/${procedureIdx}/${billingLevel}`,
        {
          ...billing,
        }
      )
      .then(() => {
        toast.success("Billing information has been updated successfully!");
        handleUpdateBilling(billingID, dateOfServiceIdx, procedureIdx);
        handleClose();
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          toast.error(e.response.data.message);
        }
      });
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} fullWidth maxWidth="md">
      <DialogTitle>Edit billing</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {billingLevel === 1 && (
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <TextField
                label="Insurance"
                value={billing.insurance}
                onChange={(e) =>
                  setBilling({ ...billing, insurance: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={billing.status}
                  onChange={(e) =>
                    setBilling({ ...billing, status: e.target.value })
                  }
                  variant="outlined"
                  label="Status"
                >
                  <MenuItem value="Not started">Not started</MenuItem>
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Closed">Closed</MenuItem>
                  <MenuItem value="Missing documentation">
                    Missing documentation
                  </MenuItem>
                  <MenuItem value="Appealed">Appealed</MenuItem>
                  <MenuItem value="Resubmitted">Resubmitted</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Service Date"
                value={billing.date_of_service}
                onChange={(e) =>
                  setBilling({ ...billing, date_of_service: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Cost share paid"
                value={billing.cost_share_paid}
                onChange={(e) =>
                  setBilling({ ...billing, cost_share_paid: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Date billed"
                value={billing.date_billed}
                onChange={(e) =>
                  setBilling({ ...billing, date_billed: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Total charges"
                value={billing.total_charges}
                onChange={(e) =>
                  setBilling({ ...billing, total_charges: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Total receipts(mail)"
                value={billing.total_receipts_by_mail}
                onChange={(e) =>
                  setBilling({
                    ...billing,
                    total_receipts_by_mail: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Total receipts(Tebra)"
                value={billing.total_receipts_by_tebra}
                onChange={(e) =>
                  setBilling({
                    ...billing,
                    total_receipts_by_tebra: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Receipt Date"
                value={billing.receipt_date}
                onChange={(e) =>
                  setBilling({ ...billing, receipt_date: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Total Balance"
                value={billing.total_balance}
                onChange={(e) =>
                  setBilling({ ...billing, total_balance: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Sent to Patient?</InputLabel>
                <Select
                  variant="outlined"
                  value={billing.sent_to_patient}
                  onChange={(e) =>
                    setBilling({ ...billing, sent_to_patient: e.target.value })
                  }
                  label="Sent to Patient?"
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Collected from Patient?</InputLabel>
                <Select
                  value={billing.collected_from_patient}
                  onChange={(e) =>
                    setBilling({
                      ...billing,
                      collected_from_patient: e.target.value,
                    })
                  }
                  label="Collected from Patient?"
                  variant="outlined"
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Appeal Needed?</InputLabel>
                <Select
                  value={billing.appeal_needed}
                  onChange={(e) =>
                    setBilling({ ...billing, appeal_needed: e.target.value })
                  }
                  variant="outlined"
                  label="Appeal Needed?"
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={billing.appeal_date}
                onChange={(e) =>
                  setBilling({ ...billing, appeal_date: e.target.value })
                }
                label="Appeal Date"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={billing.comments}
                onChange={(e) =>
                  setBilling({ ...billing, comments: e.target.value })
                }
                label="Comments"
                fullWidth
              />
            </Grid>
          </Grid>
        )}
        {billingLevel === 2 && (
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <TextField
                label="Procedure"
                value={billing.procedure}
                onChange={(e) =>
                  setBilling({ ...billing, procedure: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Mod"
                value={billing.mod}
                onChange={(e) =>
                  setBilling({ ...billing, mod: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Diag1"
                value={billing.diag1}
                onChange={(e) =>
                  setBilling({ ...billing, diag1: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Diag2"
                value={billing.diag2}
                onChange={(e) =>
                  setBilling({ ...billing, diag2: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Charges"
                value={billing.charges}
                onChange={(e) =>
                  setBilling({ ...billing, charges: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Receipts By Mail"
                value={billing.receipts_by_mail}
                onChange={(e) =>
                  setBilling({ ...billing, receipts_by_mail: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Receipts By Tebra"
                value={billing.receipts_by_tebra}
                onChange={(e) =>
                  setBilling({
                    ...billing,
                    receipts_by_tebra: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Balance"
                value={billing.balance}
                onChange={(e) =>
                  setBilling({
                    ...billing,
                    balance: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Comments"
                value={billing.comments}
                onChange={(e) =>
                  setBilling({ ...billing, comments: e.target.value })
                }
                fullWidth
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={updateBilling} autoFocus variant="contained">
          Update
        </Button>
        <Button onClick={handleClose} variant="contained" color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
