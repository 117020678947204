import React, { useEffect } from "react";
import {
  FormGroup,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Input,
} from "@mui/material";

const CervicalPhysicalExam = ({
  cervicalDystoniaInfo,
  setCervicalDystoniaInfo,
}) => {
  useEffect(() => {
    if (cervicalDystoniaInfo.deepTouch === "intact bilaterally") {
      setCervicalDystoniaInfo({
        ...cervicalDystoniaInfo,
        fromNerveDistribution: "C4",
        toNerveDistribution: "T1",
      });
    }
  }, [cervicalDystoniaInfo]);

  const handleCervicalChange = (event) => {
    const { name, checked } = event.target;
    if (name === "full" && checked)
      setCervicalDystoniaInfo({
        ...cervicalDystoniaInfo,
        cervical: {
          full: true,
          limitedFlexion: false,
          limitedExtension: false,
          limitedRotation: false,
          limitedLateralBending: false,
        },
      });
    else
      setCervicalDystoniaInfo({
        ...cervicalDystoniaInfo,
        cervical: {
          ...cervicalDystoniaInfo.cervical,
          full: false,
          [name]: checked,
        },
      });
  };

  const handleHeadPosturingChange = (event) => {
    const { name, checked } = event.target;
    if (name === "normal" && checked)
      setCervicalDystoniaInfo({
        ...cervicalDystoniaInfo,
        headPosturing: {
          normal: true,
          torticollis: false,
          laterocollis: false,
          anterocollis: false,
          retrocollis: false,
          sagittalShift: false,
          lateralShift: false,
        },
      });
    else
      setCervicalDystoniaInfo({
        ...cervicalDystoniaInfo,
        headPosturing: {
          ...cervicalDystoniaInfo.headPosturing,
          normal: false,
          [name]: checked,
        },
      });
  };

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={5}>
        <RadioGroup
          sx={{ width: "30%" }}
          value={cervicalDystoniaInfo.generalAppearance}
          onChange={(e) =>
            setCervicalDystoniaInfo({
              ...cervicalDystoniaInfo,
              generalAppearance: e.target.value,
            })
          }
        >
          <FormControlLabel value="NAD" control={<Radio />} label="NAD" />
          <FormControlLabel
            value="In acute distress"
            control={<Radio />}
            label="In Acute Distress"
          />
        </RadioGroup>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            General Appearance:
          </Typography>
          <Typography variant="h6">
            {cervicalDystoniaInfo.generalAppearance}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack direction="column" sx={{ width: "30%" }}>
          <FormControl>
            <Typography variant="h6">Light touch </Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.lightTouch}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  lightTouch: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="decreased on the left"
                control={<Radio />}
                label="Decreased on the left"
              />
              <FormControlLabel
                value="decreased on the right"
                control={<Radio />}
                label="Decreased on the right"
              />
              <FormControlLabel
                value="decreased bilaterally"
                control={<Radio />}
                label="Decreased bilaterally"
              />
              <FormControlLabel
                value="intact bilaterally"
                control={<Radio />}
                label="Intact bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <Stack direction="column">
            <FormControl>
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                Deep touch
              </Typography>
              <RadioGroup
                value={cervicalDystoniaInfo.deepTouch}
                onChange={(e) =>
                  setCervicalDystoniaInfo({
                    ...cervicalDystoniaInfo,
                    deepTouch: e.target.value,
                  })
                }
              >
                <FormControlLabel
                  value="decreased on the left"
                  control={<Radio />}
                  label="Decreased on the left"
                />
                <FormControlLabel
                  value="decreased on the right"
                  control={<Radio />}
                  label="Decreased on the right"
                />
                <FormControlLabel
                  value="decreased bilaterally"
                  control={<Radio />}
                  label="Decreased bilaterally"
                />
                <FormControlLabel
                  value="intact bilaterally"
                  control={<Radio />}
                  label="Intact bilaterally"
                />
              </RadioGroup>
            </FormControl>
            <Stack direction="row">
              <Select
                variant="standard"
                color="primary"
                size="medium"
                value={cervicalDystoniaInfo.fromNerveDistribution}
                onChange={(e) =>
                  setCervicalDystoniaInfo({
                    ...cervicalDystoniaInfo,
                    fromNerveDistribution: e.target.value,
                  })
                }
                disabled={
                  cervicalDystoniaInfo.deepTouch === "intact bilaterally"
                }
              >
                <MenuItem value="C4">C4</MenuItem>
                <MenuItem value="C5">C5</MenuItem>
                <MenuItem value="C6">C6</MenuItem>
                <MenuItem value="C7">C7</MenuItem>
                <MenuItem value="C8">C8</MenuItem>
                <MenuItem value="T1">T1</MenuItem>
              </Select>
              <Select
                variant="standard"
                color="primary"
                size="medium"
                value={cervicalDystoniaInfo.toNerveDistribution}
                onChange={(e) =>
                  setCervicalDystoniaInfo({
                    ...cervicalDystoniaInfo,
                    toNerveDistribution: e.target.value,
                  })
                }
                disabled={
                  cervicalDystoniaInfo.deepTouch === "intact bilaterally"
                }
                style={{ marginLeft: "20px" }}
              >
                <MenuItem value="C4">C4</MenuItem>
                <MenuItem value="C5">C5</MenuItem>
                <MenuItem value="C6">C6</MenuItem>
                <MenuItem value="C7">C7</MenuItem>
                <MenuItem value="C8">C8</MenuItem>
                <MenuItem value="T1">T1</MenuItem>
              </Select>
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Sensation:
          </Typography>
          <Typography variant="h6">
            Light touch:{` ${cervicalDystoniaInfo.lightTouch}`}
          </Typography>
          <Typography variant="h6">
            Deep touch:
            {` ${cervicalDystoniaInfo.deepTouch} in ${cervicalDystoniaInfo.fromNerveDistribution}-${cervicalDystoniaInfo.toNerveDistribution}`}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack direction="column" sx={{ width: "30%" }}>
          <FormControl>
            <Typography variant="h6">Left tricep</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.leftTricep}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  leftTricep: e.target.value,
                })
              }
              row
            >
              <FormControlLabel
                value="1+"
                control={<Radio size="small" />}
                label="1+"
              />
              <FormControlLabel
                value="2+"
                control={<Radio size="small" />}
                label="2+"
              />
              <FormControlLabel
                value="3+"
                control={<Radio size="small" />}
                label="3+"
              />
              <FormControlLabel
                value="4+"
                control={<Radio size="small" />}
                label="4+"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Right tricep</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.rightTricep}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  rightTricep: e.target.value,
                })
              }
              row
            >
              <FormControlLabel
                value="1+"
                control={<Radio size="small" />}
                label="1+"
              />
              <FormControlLabel
                value="2+"
                control={<Radio size="small" />}
                label="2+"
              />
              <FormControlLabel
                value="3+"
                control={<Radio size="small" />}
                label="3+"
              />
              <FormControlLabel
                value="4+"
                control={<Radio size="small" />}
                label="4+"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Bilateral BR, Bicep</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.bilateralBR_Bicep}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  bilateralBR_Bicep: e.target.value,
                })
              }
              row
            >
              <FormControlLabel
                value="1+"
                control={<Radio size="small" />}
                label="1+"
              />
              <FormControlLabel
                value="2+"
                control={<Radio size="small" />}
                label="2+"
              />
              <FormControlLabel
                value="3+"
                control={<Radio size="small" />}
                label="3+"
              />
              <FormControlLabel
                value="4+"
                control={<Radio size="small" />}
                label="4+"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Hoffman</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.hoffman}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  hoffman: e.target.value,
                })
              }
              row
            >
              <FormControlLabel
                value="negative"
                control={<Radio />}
                label="negative"
              />
              <FormControlLabel
                value="positive"
                control={<Radio />}
                label="positive"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Reflexes:
          </Typography>
          <Typography variant="h6">
            Left tricep: {cervicalDystoniaInfo.leftTricep}
          </Typography>
          <Typography variant="h6">
            Right tricep: {cervicalDystoniaInfo.rightTricep}
          </Typography>
          <Typography variant="h6">
            Bilateral BR, Bicep: {cervicalDystoniaInfo.bilateralBR_Bicep}
          </Typography>
          <Typography variant="h6">
            Hoffman: {cervicalDystoniaInfo.hoffman}{" "}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack direction="column" sx={{ width: "30%" }} spacing={3}>
          <FormControl>
            <Typography variant="h6">Cervical</Typography>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cervicalDystoniaInfo.cervical.full}
                      name="full"
                      onChange={handleCervicalChange}
                    />
                  }
                  label="Full"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cervicalDystoniaInfo.cervical.limitedFlexion}
                      name="limitedFlexion"
                      onChange={handleCervicalChange}
                    />
                  }
                  label="Limited Flexion"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cervicalDystoniaInfo.cervical.limitedExtension}
                      name="limitedExtension"
                      onChange={handleCervicalChange}
                    />
                  }
                  label="Limited Extension"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cervicalDystoniaInfo.cervical.limitedRotation}
                      name="limitedRotation"
                      onChange={handleCervicalChange}
                    />
                  }
                  label="Limited Rotation"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        cervicalDystoniaInfo.cervical.limitedLateralBending
                      }
                      name="limitedLateralBending"
                      onChange={handleCervicalChange}
                    />
                  }
                  label="Limited Lateral Bending"
                />
              </FormGroup>
            </FormControl>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Shoulder</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.shoulder}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  shoulder: e.target.value,
                })
              }
              row
            >
              <FormControlLabel value="Full" control={<Radio />} label="Full" />
              <FormControlLabel
                value="Limited"
                control={<Radio />}
                label="Limited"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Head Posturing</Typography>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cervicalDystoniaInfo.headPosturing.normal}
                      name="normal"
                      onChange={handleHeadPosturingChange}
                    />
                  }
                  label="Normal"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cervicalDystoniaInfo.headPosturing.torticollis}
                      name="torticollis"
                      onChange={handleHeadPosturingChange}
                    />
                  }
                  label="Torticollis"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cervicalDystoniaInfo.headPosturing.laterocollis}
                      name="laterocollis"
                      onChange={handleHeadPosturingChange}
                    />
                  }
                  label="Laterocollis"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cervicalDystoniaInfo.headPosturing.anterocollis}
                      name="anterocollis"
                      onChange={handleHeadPosturingChange}
                    />
                  }
                  label="Anterocollis"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cervicalDystoniaInfo.headPosturing.retrocollis}
                      name="retrocollis"
                      onChange={handleHeadPosturingChange}
                    />
                  }
                  label="Retrocollis"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cervicalDystoniaInfo.headPosturing.sagittalShift}
                      name="sagittalShift"
                      onChange={handleHeadPosturingChange}
                    />
                  }
                  label="Sagittal Shift"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={cervicalDystoniaInfo.headPosturing.lateralShift}
                      name="lateralShift"
                      onChange={handleHeadPosturingChange}
                    />
                  }
                  label="Lateral Shift"
                />
              </FormGroup>
            </FormControl>
          </FormControl>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Rom:
          </Typography>
          {cervicalDystoniaInfo.cervical["full"] && (
            <Typography variant="h6">Cervical: Full</Typography>
          )}
          {cervicalDystoniaInfo.cervical["limitedFlexion"] && (
            <Typography variant="h6">Cervical: Limited Flexion</Typography>
          )}
          {cervicalDystoniaInfo.cervical["limitedExtension"] && (
            <Typography variant="h6">Cervical: Limited Extension</Typography>
          )}
          {cervicalDystoniaInfo.cervical["limitedRotation"] && (
            <Typography variant="h6">Cervical: Limited Rotation</Typography>
          )}
          {cervicalDystoniaInfo.cervical["limitedLateralBending"] && (
            <Typography variant="h6">Cervical: Lateral Bending</Typography>
          )}
          <Typography variant="h6">
            Shoulder: {cervicalDystoniaInfo.shoulder}
          </Typography>
          {cervicalDystoniaInfo.headPosturing["normal"] && (
            <Typography variant="h6">Head Posturing: Normal</Typography>
          )}
          {cervicalDystoniaInfo.headPosturing["torticollis"] && (
            <Typography variant="h6">Head Posturing: Torticollis</Typography>
          )}
          {cervicalDystoniaInfo.headPosturing["laterocollis"] && (
            <Typography variant="h6">Head Posturing: Laterocollis</Typography>
          )}
          {cervicalDystoniaInfo.headPosturing["anterocollis"] && (
            <Typography variant="h6">Head Posturing: Anterocollis</Typography>
          )}
          {cervicalDystoniaInfo.headPosturing["retrocollis"] && (
            <Typography variant="h6">Head Posturing: Retrocollis</Typography>
          )}
          {cervicalDystoniaInfo.headPosturing["sagittalShift"] && (
            <Typography variant="h6">Head Posturing: Sagittal Shift</Typography>
          )}
          {cervicalDystoniaInfo.headPosturing["lateralShift"] && (
            <Typography variant="h6">Head Posturing: Lateral Shift</Typography>
          )}
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack direction="column" sx={{ width: "30%" }} spacing={3}>
          <FormControl>
            <Typography variant="h6">Kemp's</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.kempsSpecialTest}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  kempsSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Hawkins</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.hawkinsSpecialTest}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  hawkinsSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Empty Can</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.emptyCanSpecialTest}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  emptyCanSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Neers</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.neersSpecialTest}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  neersSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Tinnel's</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.tinnelsSpecialTest}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  tinnelsSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Spurling's</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.spurlingsSpecialTest}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  spurlingsSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Special Tests:
          </Typography>
          <Typography variant="h6">
            Kemp's: {cervicalDystoniaInfo.kempsSpecialTest}
          </Typography>
          <Typography variant="h6">
            Hawkins: {cervicalDystoniaInfo.hawkinsSpecialTest}
          </Typography>
          <Typography variant="h6">
            Empty Can: {cervicalDystoniaInfo.emptyCanSpecialTest}
          </Typography>
          <Typography variant="h6">
            Neers: {cervicalDystoniaInfo.neersSpecialTest}
          </Typography>
          <Typography variant="h6">
            Tinnel's: {cervicalDystoniaInfo.tinnelsSpecialTest}
          </Typography>
          <Typography variant="h6">
            Spurling's: {cervicalDystoniaInfo.spurlingsSpecialTest}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack direction="column" sx={{ width: "30%" }} spacing={3}>
          <FormControl>
            <Typography variant="h6">Cervical paraspinals:</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.cervicalParaspinals}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  cervicalParaspinals: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="tender on the left with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Levator scapula:</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.levatorScapula}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  levatorScapula: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="tender on the left with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Trapezius:</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.trapezius}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  trapezius: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="tender on the left with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Bicipital tendon:</Typography>
            <RadioGroup
              value={cervicalDystoniaInfo.bicipitalTendon}
              onChange={(e) =>
                setCervicalDystoniaInfo({
                  ...cervicalDystoniaInfo,
                  bicipitalTendon: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="tender on the left"
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right"
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally"
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Palpation:
          </Typography>
          <Typography variant="h6">
            Cervical paraspinals: {cervicalDystoniaInfo.cervicalParaspinals}
          </Typography>
          <Typography variant="h6">
            Levator Scapula: {cervicalDystoniaInfo.levatorScapula}
          </Typography>
          <Typography variant="h6">
            Trapezius: {cervicalDystoniaInfo.trapezius}
          </Typography>
          <Typography variant="h6">
            Bicipital tendon: {cervicalDystoniaInfo.bicipitalTendon}
          </Typography>
        </Stack>
      </Stack>

      <Stack style={{ marginLeft: "300px" }}>
        <Typography variant="h5" style={{ textDecoration: "underline" }}>
          Motor:
        </Typography>
        <TableContainer style={{ width: "500px" }}>
          <Table>
            <TableBody>
              <TableRow key="1">
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Right
                </TableCell>
                <TableCell
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Left
                </TableCell>
              </TableRow>
              <TableRow key="2">
                <TableCell style={{ fontWeight: "bold" }}>C4</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Shoulder Abduction
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.shoulderAbductionRight}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        shoulderAbductionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.shoulderAbductionLeft}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        shoulderAbductionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="3">
                <TableCell style={{ fontWeight: "bold" }}>C5</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Elbow Flexion
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.elbowFlexionRight}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        elbowFlexionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.elbowFlexionLeft}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        elbowFlexionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="4">
                <TableCell style={{ fontWeight: "bold" }}>C6</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Wrist Extension
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.wristExtensionRight}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        wristExtensionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.wristExtensionLeft}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        wristExtensionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="5">
                <TableCell style={{ fontWeight: "bold" }}>C7</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Elbow Extension
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.elbowExtensionRight}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        elbowExtensionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.elbowExtensionLeft}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        elbowExtensionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="6">
                <TableCell style={{ fontWeight: "bold" }}>C8/T1</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Hand Intrinsics
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.handIntrinsicsRight}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        handIntrinsicsRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.handIntrinsicsLeft}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        handIntrinsicsLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="7">
                <TableCell style={{ fontWeight: "bold" }}>C8</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  First Dorsal Interosseus
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.firstDorsalInterosseusRight}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        firstDorsalInterosseusRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.firstDorsalInterosseusLeft}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        firstDorsalInterosseusLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="8">
                <TableCell style={{ fontWeight: "bold" }}>C8</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Abductor Pollicus Brevis
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.abductorPollicusBrevisRight}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        abductorPollicusBrevisRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.abductorPollicusBrevisLeft}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        abductorPollicusBrevisLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="9">
                <TableCell style={{ fontWeight: "bold" }}>C5/6</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Shoulder External Rotation
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.shoulderExternalRotationRight}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        shoulderExternalRotationRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={cervicalDystoniaInfo.shoulderExternalRotationLeft}
                    onChange={(e) =>
                      setCervicalDystoniaInfo({
                        ...cervicalDystoniaInfo,
                        shoulderExternalRotationLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

export default CervicalPhysicalExam;
