import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  CloudUpload as CloudUploadIcon,
  BackupTable as ExportIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import TopNav from "../components/topnav";
import EditModal from "../components/billing/EditModal";
import DeleteModal from "../components/billing/DeleteModal";
import SummaryModal from "../components/billing/SummaryModal";
import PatientBillingTable from "../components/billing/PatientBillingTable";
import axios from "axios";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { secureApi, API_URL } from "../config";
import { Context } from "../context";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Billing = () => {
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const [reportFiles, setReportFiles] = useState([]);
  const [loadingBillingInfo, setLoadingBillingInfo] = useState(false);
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState({});
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [summaryModalOpen, setSummaryModalOpen] = useState(false);
  const [billingID, setBillingID] = useState();
  const [billing, setBilling] = useState({});
  const [dateOfServiceIdx, setDateOfServiceIdx] = useState(0);
  const [procedureIdx, setProcedureIdx] = useState(0);
  const [billingLevel, setBillingLevel] = useState(0);
  const [searchStr, setSearchStr] = useState("");

  const handleReportSelect = (event) => {
    const files = event.target.files;
    setReportFiles(files);
  };

  const handleReportUpload = () => {
    if (reportFiles.length) {
      setLoadingBillingInfo(true);
      // Create FormData object
      const formData = new FormData();
      for (let i = 0; i < reportFiles.length; i++) {
        formData.append("files", reportFiles[i]);
      }

      axios
        .post(`${API_URL}/upload_billing`, formData, {
          headers: {
            "Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${window.localStorage.getItem(
              "alex-med-token"
            )}`,
          },
        })
        .then((response) => {
          const { billings } = response.data;
          setBillingInformation(billings);
          setLoadingBillingInfo(false);
          toast.success("Billing information uploaded successfully!");
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
          setLoadingBillingInfo(false);
        });
    } else {
      toast.warn("Select a billing report file!");
    }
  };

  const handleEditModalOpen = (
    billing,
    billingID,
    dateOfServiceIdx,
    procedureIdx,
    billingLevel
  ) => {
    setBilling(billing);
    setBillingID(billingID);
    setDateOfServiceIdx(dateOfServiceIdx);
    setProcedureIdx(procedureIdx);
    setBillingLevel(billingLevel);
    setEditModalOpen(true);
  };

  const handleDeleteModalOpen = (
    billingID,
    dateOfServiceIdx,
    procedureIdx,
    billingLevel
  ) => {
    setBillingID(billingID);
    setDateOfServiceIdx(dateOfServiceIdx);
    setProcedureIdx(procedureIdx);
    setBillingLevel(billingLevel);
    setDeleteModalOpen(true);
  };

  const handleSummaryModalOpen = (patient) => {
    setPatient(patient);
    setSummaryModalOpen(true);
  };

  const handleEditModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setEditModalOpen(false);
    }
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleSummaryModalClose = () => {
    setSummaryModalOpen(false);
  };

  const handleDeleteBilling = (
    billingID,
    dateOfServiceIdx,
    procedureIdx,
    billingLevel
  ) => {
    for (let index = 0; index < patients.length; index++) {
      if (
        patients[index]["billing"] &&
        patients[index]["billing"]["_id"] === billingID
      ) {
        const newPatients = [...patients];

        if (billingLevel == 0) {
          delete newPatients[index].billing;
        } else if (
          billingLevel === 1 &&
          newPatients[index]["billing"]["history"].length > dateOfServiceIdx
        ) {
          newPatients[index]["billing"]["history"].splice(dateOfServiceIdx, 1);
          if (newPatients[index]["billing"]["history"].length === 0) {
            delete newPatients[index].billing;
            break;
          }
        } else if (
          billingLevel === 2 &&
          newPatients[index]["billing"]["history"][dateOfServiceIdx]["details"]
            .length > procedureIdx
        ) {
          newPatients[index]["billing"]["history"][dateOfServiceIdx][
            "details"
          ].splice(procedureIdx, 1);
          if (
            newPatients[index]["billing"]["history"][dateOfServiceIdx][
              "details"
            ].length === 0
          ) {
            newPatients[index]["billing"]["history"].splice(
              dateOfServiceIdx,
              1
            );
            if (newPatients[index]["billing"]["history"].length === 0) {
              delete newPatients[index].billing;
              break;
            }
          }
        }

        setPatients(newPatients);
        break;
      }
    }
  };

  const handleUpdateBilling = (billingID, dateOfServiceIdx, procedureIdx) => {
    for (let index = 0; index < patients.length; index++) {
      if (
        patients[index]["billing"] &&
        patients[index]["billing"]["_id"] === billingID
      ) {
        let newPatiens = [...patients];

        if (newPatiens[index]["billing"]["history"].length > dateOfServiceIdx) {
          if (billingLevel === 1) {
            newPatiens[index]["billing"]["history"][dateOfServiceIdx] = billing;
            setPatients(newPatiens);
          } else if (
            billingLevel === 2 &&
            newPatiens[index]["billing"]["history"][dateOfServiceIdx]["details"]
              .length > procedureIdx
          ) {
            newPatiens[index]["billing"]["history"][dateOfServiceIdx][
              "details"
            ][procedureIdx] = billing;
            setPatients(newPatiens);
          }
        }
        break;
      }
    }
  };

  const exportExcel = () => {};

  useEffect(() => {
    secureApi(window.localStorage.getItem("alex-med-token"))
      .get(`/patients_billings`)
      .then((response) => {
        const { patients } = response.data;
        setPatients(patients);
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          toast.error(e.response.data.message);
        }
      });
  }, []);

  useEffect(() => {
    if (searchStr) {
      const filteredPatients = patients.filter(
        (patient) =>
          patient.first_name.toLowerCase().includes(searchStr.trim()) ||
          patient.last_name.toLowerCase().includes(searchStr.trim()) ||
          patient.notes.some(
            (note) =>
              note["dateOfService"]?.includes(searchStr.trim()) ||
              note["dateOfProcedure"]?.includes(searchStr.trim())
          ) ||
          patient.billing?.history.some(
            (historyItem) =>
              historyItem.insurance.toLowerCase().includes(searchStr.trim()) ||
              historyItem.status.toLowerCase().includes(searchStr.trim()) ||
              historyItem.date_of_service
                .toLowerCase()
                .includes(searchStr.trim()) ||
              historyItem.cost_share_paid
                .toLowerCase()
                .includes(searchStr.trim()) ||
              historyItem.date_billed
                .toLowerCase()
                .includes(searchStr.trim()) ||
              historyItem.receipt_date
                .toLowerCase()
                .includes(searchStr.trim()) ||
              historyItem.total_balance
                .toLowerCase()
                .includes(searchStr.trim()) ||
              historyItem.sent_to_patient
                .toLowerCase()
                .includes(searchStr.trim()) ||
              historyItem.collected_from_patient
                .toLowerCase()
                .includes(searchStr.trim()) ||
              historyItem.appeal_needed
                .toLowerCase()
                .includes(searchStr.trim()) ||
              historyItem.appeal_date.toLowerCase().includes(searchStr.trim())
          )
      );
      setFilteredPatients(filteredPatients);
    } else {
      setFilteredPatients(patients);
    }
  }, [searchStr, patients]);

  return (
    <>
      <TopNav item="billing" />
      <Box margin="30px">
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", marginBottom: "10px" }}>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                component="label"
                startIcon={<CloudUploadIcon />}
              >
                Select File
                <VisuallyHiddenInput
                  type="file"
                  accept="image/*, application/pdf"
                  onChange={handleReportSelect}
                  multiple
                />
              </Button>
              <LoadingButton
                size="small"
                onClick={handleReportUpload}
                loading={loadingBillingInfo}
                disabled={!reportFiles.length}
                endIcon={<SendIcon />}
                loadingPosition="end"
                variant="contained"
              >
                Upload Report
              </LoadingButton>
            </Stack>
          </div>
          <div style={{ width: "100%", marginBottom: "10px" }}>
            <Stack direction="row" style={{ float: "right" }} gap={1}>
              <Button
                variant="contained"
                style={{ width: "fit-content" }}
                startIcon={<ExportIcon />}
                onClick={exportExcel}
              >
                Export Excel
              </Button>
              <TextField
                size="small"
                label="Search"
                variant="outlined"
                style={{ float: "right" }}
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
              />
            </Stack>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow sx={{ background: "#c4f6ff" }}>
                <TableCell />
                <TableCell>Chart #</TableCell>
                <TableCell>Patient</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPatients.length > 0 &&
                filteredPatients.map((patient, index) => (
                  <PatientBillingTable
                    patient={patient}
                    key={index}
                    handleDeleteModalOpen={handleDeleteModalOpen}
                    handleEditModalOpen={handleEditModalOpen}
                    handleSummaryModalOpen={handleSummaryModalOpen}
                  />
                ))}
              {filteredPatients.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                    No Patient Information
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        billingID={billingID}
        dateOfServiceIdx={dateOfServiceIdx}
        procedureIdx={procedureIdx}
        billingLevel={billingLevel}
        handleDeleteBilling={handleDeleteBilling}
      />
      <EditModal
        billing={billing}
        billingID={billingID}
        dateOfServiceIdx={dateOfServiceIdx}
        procedureIdx={procedureIdx}
        billingLevel={billingLevel}
        setBilling={setBilling}
        open={editModalOpen}
        handleClose={handleEditModalClose}
        handleUpdateBilling={handleUpdateBilling}
      />
      <SummaryModal
        open={summaryModalOpen}
        patient={patient}
        handleClose={handleSummaryModalClose}
      />
    </>
  );
};

export default Billing;
