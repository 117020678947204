import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  useMediaQuery,
  Button,
  IconButton,
  CircularProgress,
  Typography,
  Grid,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import { ContactEmergency as ContactEmergencyIcon, Close as CloseIcon } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { secureApi, ADMIN, MEDICAL_ASSISTANT, MEDICAL_BILLING_PERSON } from '../../config';
import { Context } from '../../context';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const AppointmentModal = ({ openModal, handleCancel, handleAdd, handleUpdate, handleDelete, state, appointment, setAppointment }) => {
  const { setLoggedIn, user } = useContext(Context);
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [patients, setPatients] = useState([]);
  const loading = open && patients.length === 0;
  const [submitable, setSubmitable] = useState(false);

  useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      secureApi(window.localStorage.getItem('alex-med-token')).post(`/patients`, {
        page: 0,
        rowsPerPage: -1
      })
        .then(response => {
          const { patients } = response.data;
          setPatients(patients);
        }).catch(e => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn('Session has been expired. You need to login again!');
            setLoggedIn(false);
            navigate('/login');
          } else {
            toast.error(e.response.data.message);
          }
        })
    })();
  }, [loading]);

  useEffect(() => {
    const submitable = appointment.patient && appointment.doctor && appointment.place && appointment.type && appointment.date && appointment.time;
    setSubmitable(submitable);
  }, [appointment]);

  return (
    <BootstrapDialog fullScreen={fullScreen} open={openModal} fullWidth maxWidth='lg'>
      <DialogTitle>
        {
          state === "add" ? "Add New Medical Appointment" : user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT) ? "Edit Medical Appointment" : "Medical Appointment"
        }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack direction='column' spacing={2}>
          <Stack direction='row' spacing={1}>
            <Autocomplete
              style={{ width: '25%' }}
              open={open}
              readOnly={user && user.role === MEDICAL_BILLING_PERSON}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              onChange={(event, newValue) => {
                setAppointment({ ...appointment, patient: newValue })
              }}
              value={appointment.patient}
              isOptionEqualToValue={(option, value) => `${option.patientInfo.first_name} ${option.patientInfo.last_name}` === `${value.patientInfo.first_name} ${value.patientInfo.last_name}`}
              getOptionLabel={(option) => `${option.patientInfo.first_name} ${option.patientInfo.last_name}`}
              options={patients}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a patient"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option['_id']}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: 'flex', width: 44 }}>
                        <ContactEmergencyIcon sx={{ color: 'text.secondary' }} />
                      </Grid>
                      <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                        <Typography variant="body2" color="text.secondary" fontWeight='bold'>
                          {option.patientInfo.first_name} {option.patientInfo.last_name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          D.O.A {option.patientInfo.date_of_accident}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          D.O.B {option.patientInfo.date_of_birth}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
            <TextField label="Doctor" value={appointment.doctor} onChange={e => setAppointment({ ...appointment, doctor: e.target.value })} style={{ width: '25%' }} disabled={user && user.role === MEDICAL_BILLING_PERSON} />
            <TextField label="Place of Service" value={appointment.place} onChange={e => setAppointment({ ...appointment, place: e.target.value })} style={{ width: '25%' }} disabled={user && user.role === MEDICAL_BILLING_PERSON} />
            <TextField label="Appointment Type" value={appointment.type} onChange={e => setAppointment({ ...appointment, type: e.target.value })} style={{ width: '25%' }} disabled={user && user.role === MEDICAL_BILLING_PERSON} />
          </Stack>
          <Stack direction='row' spacing={1}>
            <FormControl style={{ width: '25%' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker size='medium' label="Date of Appointment" value={appointment.date} onChange={e => setAppointment({ ...appointment, date: e })} readOnly={user && user.role === MEDICAL_BILLING_PERSON} />
              </LocalizationProvider>
            </FormControl>
            <FormControl style={{ width: '25%' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker size='medium' label="Time of Appointment" value={appointment.time} onChange={e => setAppointment({ ...appointment, time: e })} readOnly={user && user.role === MEDICAL_BILLING_PERSON} />
              </LocalizationProvider>
            </FormControl>
            <FormControl style={{ width: '25%' }}>
              <InputLabel>Duration of Appointment</InputLabel>
              <Select value={appointment.duration} onChange={e => setAppointment({ ...appointment, duration: e.target.value })} label="Duration of Appointment" readOnly={user && user.role === MEDICAL_BILLING_PERSON}>
                <MenuItem value="15min">15 min</MenuItem>
                <MenuItem value="30min">30 min</MenuItem>
                <MenuItem value="45min">45 min</MenuItem>
                <MenuItem value="60min">60 min</MenuItem>
                <MenuItem value="90min">90 min</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ width: '25%' }}>
              <InputLabel>Status</InputLabel>
              <Select label="Status" value={appointment.status} onChange={e => setAppointment({ ...appointment, status: e.target.value })} readOnly={user && user.role === MEDICAL_BILLING_PERSON}>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="verified">Verified</MenuItem>
                <MenuItem value="confirmed">Confirmed</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        {
          user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT) && state === 'add' &&
          <Button autoFocus onClick={(e) => handleAdd(e, appointment)} variant='contained' disabled={!submitable}>
            Add
          </Button>
        }
        {
          state === 'update' &&
          <>
            <Button autoFocus onClick={(e) => window.open(`/patient/${appointment.patient._id}`, '_blank')} variant='contained' color='secondary'>
              View Patient
            </Button>
            {
              user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT) && <>
                <Button autoFocus onClick={(e) => handleUpdate(e, appointment, appointment._id)} variant='contained' disabled={!submitable}>
                  Update
                </Button>
                <Button autoFocus onClick={(e) => handleDelete(e, appointment._id)} variant='contained' color='error'>
                  Delete
                </Button>
              </>
            }
          </>
        }
        <Button onClick={handleCancel} autoFocus variant='contained' style={{ background: 'grey' }}>
          Cancel
        </Button>
      </DialogActions>
    </BootstrapDialog>
  )
}

export default AppointmentModal;