import React, { useState, useContext } from "react";
import { Button, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import {
  Send as SendIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { Context } from "../../context";
import { API_URL } from "../../config";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImagesReviewedItem = ({ imagesReviewed, date = "", type = "" }) => {
  return (
    <Stack sx={{ mb: "10px" }}>
      <Typography variant="h5" fontWeight="bold">
        {type}IMAGES REVIEWED {date ? `(${date}):` : ":"}
      </Typography>
      {imagesReviewed.length ? (
        imagesReviewed.map((item, index) => (
          <div key={index} style={{ marginBottom: "10px" }}>
            <Typography variant="h6">
              Date of Exam: {item.dateOfExam}
            </Typography>
            <Typography variant="h6">{item.typeOfImaging}</Typography>
            {item.impression.map((item1, index1) => (
              <Typography variant="h6" key={index1}>
                {item1}
              </Typography>
            ))}
          </div>
        ))
      ) : (
        <Typography variant="h6">None</Typography>
      )}
    </Stack>
  );
};

const ImagesReviewed = ({
  imagesReviewed,
  setImagesReviewed,
  setImagesReviewedInfo,
  pastFollowupNotes,
  initialImagesReviewed,
  dateOfPainConsultNote,
}) => {
  const navigate = useNavigate();
  const { setLoggedIn } = useContext(Context);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    // Check if selected file is an image
    if (
      file &&
      (file.type.includes("image") || file.type === "application/pdf")
    ) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
    }
  };

  const handleUpload = async () => {
    if (selectedFile == null) return;
    // Create FormData object
    const formData = new FormData();
    formData.append("file", selectedFile);

    setLoading(true);

    // Make a POST request to the Django backend using axios or fetch
    axios
      .post(`${API_URL}/get_imagesReviewed`, formData, {
        headers: {
          "Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          Authorization: `Bearer ${window.localStorage.getItem(
            "alex-med-token"
          )}`,
        },
      })
      .then((response) => {
        // Handle successful upload
        const { result, imagesReviewedInfo } = response.data;
        setImagesReviewed(result);
        setImagesReviewedInfo(imagesReviewedInfo);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        if (e.response && e.response.status === 401) {
          toast.warn("Session has been expired. You need to login again!");
          setLoggedIn(false);
          navigate("/login");
        } else {
          setLoading(false);
          toast.error(e.response.data.message);
        }
      });
  };
  return (
    <Stack direction="row">
      <Stack style={{ width: "250px", marginRight: "10px" }}>
        <Stack direction="row" spacing={1}>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Select
            <VisuallyHiddenInput
              type="file"
              accept="image/*, application/pdf"
              onChange={handleFileSelect}
            />
          </Button>
          <LoadingButton
            // size="small"
            onClick={handleUpload}
            loading={loading}
            disabled={!selectedFile}
            endIcon={<SendIcon />}
            loadingPosition="end"
            // loadingIndicator="Loading…"
            variant="contained"
          >
            <span>Upload</span>
          </LoadingButton>
          {/* <Button onClick={handleUpload} variant='contained' disabled={disabled}>Upload</Button> */}
        </Stack>
        <Typography variant="h6">
          {selectedFile ? selectedFile.name : "No file selected"}
        </Typography>
      </Stack>
      <Stack direction="column">
        <ImagesReviewedItem imagesReviewed={imagesReviewed} />
        {pastFollowupNotes &&
          pastFollowupNotes.map((note, index) => (
            <ImagesReviewedItem
              imagesReviewed={note.imagesReviewed}
              date={note.dateOfService}
              key={index}
            />
          ))}
        {initialImagesReviewed && (
          <ImagesReviewedItem
            imagesReviewed={initialImagesReviewed}
            date={dateOfPainConsultNote}
            type="Initial "
          />
        )}
      </Stack>
    </Stack>
  );
};

export default ImagesReviewed;
