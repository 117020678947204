import React, { useEffect } from "react";
import {
  FormGroup,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Input,
} from "@mui/material";

const BackPhysicalExam = ({ lowBackPainInfo, setLowBackPainInfo }) => {
  useEffect(() => {
    if (lowBackPainInfo.deepTouch === "intact bilaterally") {
      setLowBackPainInfo({
        ...lowBackPainInfo,
        fromNerveDistribution: "L2",
        toNerveDistribution: "S1",
      });
    }
  }, [lowBackPainInfo]);

  const handleLumbarChange = (event) => {
    const { name, checked } = event.target;
    if (name === "full" && checked)
      setLowBackPainInfo({
        ...lowBackPainInfo,
        lumbar: {
          full: true,
          limitedFlexion: false,
          limitedExtension: false,
          limitedRotation: false,
          limitedLateralBending: false,
        },
      });
    else
      setLowBackPainInfo({
        ...lowBackPainInfo,
        lumbar: {
          ...lowBackPainInfo.lumbar,
          full: false,
          [name]: checked,
        },
      });
  };

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={5}>
        <RadioGroup
          sx={{ width: "30%" }}
          value={lowBackPainInfo.generalAppearance}
          onChange={(e) =>
            setLowBackPainInfo({
              ...lowBackPainInfo,
              generalAppearance: e.target.value,
            })
          }
        >
          <FormControlLabel value="NAD" control={<Radio />} label="NAD" />
          <FormControlLabel
            value="In acute distress"
            control={<Radio />}
            label="In Acute Distress"
          />
        </RadioGroup>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            General Appearance:
          </Typography>
          <Typography variant="h6">
            {lowBackPainInfo.generalAppearance}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <RadioGroup
          sx={{ width: "30%" }}
          value={lowBackPainInfo.gait}
          onChange={(e) =>
            setLowBackPainInfo({ ...lowBackPainInfo, gait: e.target.value })
          }
        >
          <FormControlLabel
            value="Non-antalgic"
            control={<Radio />}
            label="Non-antalgic"
          />
          <FormControlLabel
            value="antalgic"
            control={<Radio />}
            label="Antalgic"
          />
        </RadioGroup>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Gait:
          </Typography>
          <Typography variant="h6">{lowBackPainInfo.gait}</Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }}>
          <FormControl>
            <Typography variant="h6">Lumbar</Typography>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={lowBackPainInfo.lumbar.full}
                      name="full"
                      onChange={handleLumbarChange}
                    />
                  }
                  label="Full"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={lowBackPainInfo.lumbar.limitedFlexion}
                      name="limitedFlexion"
                      onChange={handleLumbarChange}
                    />
                  }
                  label="Limited Flexion"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={lowBackPainInfo.lumbar.limitedExtension}
                      name="limitedExtension"
                      onChange={handleLumbarChange}
                    />
                  }
                  label="Limited Extension"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={lowBackPainInfo.lumbar.limitedRotation}
                      name="limitedRotation"
                      onChange={handleLumbarChange}
                    />
                  }
                  label="Limited Rotation"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={lowBackPainInfo.lumbar.limitedLateralBending}
                      name="limitedLateralBending"
                      onChange={handleLumbarChange}
                    />
                  }
                  label="Limited Lateral Bending"
                />
              </FormGroup>
            </FormControl>
          </FormControl>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Rom:
          </Typography>
          {lowBackPainInfo.lumbar["full"] && (
            <Typography variant="h6">Lumbar: Full</Typography>
          )}
          {lowBackPainInfo.lumbar["limitedFlexion"] && (
            <Typography variant="h6">Lumbar: Limited Flexion</Typography>
          )}
          {lowBackPainInfo.lumbar["limitedExtension"] && (
            <Typography variant="h6">Lumbar: Limited Extension</Typography>
          )}
          {lowBackPainInfo.lumbar["limitedRotation"] && (
            <Typography variant="h6">Lumbar: Limited Rotation</Typography>
          )}
          {lowBackPainInfo.lumbar["limitedLateralBending"] && (
            <Typography variant="h6">Lumbar: Lateral Bending</Typography>
          )}
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <FormControl>
            <Typography variant="h6">Light touch </Typography>
            <RadioGroup
              value={lowBackPainInfo.lightTouch}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  lightTouch: e.target.value,
                })
              }
            >
              <FormControlLabel
                value={"decreased on the left"}
                control={<Radio />}
                label="Decreased on the left"
              />
              <FormControlLabel
                value={"decreased on the right"}
                control={<Radio />}
                label="Decreased on the right"
              />
              <FormControlLabel
                value={"decreased bilaterally"}
                control={<Radio />}
                label="Decreased bilaterally"
              />
              <FormControlLabel
                value={"intact bilaterally"}
                control={<Radio />}
                label="Intact bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <Stack direction="column">
            <FormControl>
              <Typography variant="h6">Deep touch</Typography>
              <RadioGroup
                value={lowBackPainInfo.deepTouch}
                onChange={(e) =>
                  setLowBackPainInfo({
                    ...lowBackPainInfo,
                    deepTouch: e.target.value,
                  })
                }
              >
                <FormControlLabel
                  value={"decreased on the left"}
                  control={<Radio />}
                  label="Decreased on the left"
                />
                <FormControlLabel
                  value={"decreased on the right"}
                  control={<Radio />}
                  label="Decreased on the right"
                />
                <FormControlLabel
                  value={"decreased bilaterally"}
                  control={<Radio />}
                  label="Decreased bilaterally"
                />
                <FormControlLabel
                  value={"intact bilaterally"}
                  control={<Radio />}
                  label="Intact bilaterally"
                />
              </RadioGroup>
            </FormControl>
            <Stack direction="row">
              <Select
                variant="standard"
                color="primary"
                size="medium"
                value={lowBackPainInfo.fromNerveDistribution}
                onChange={(e) =>
                  setLowBackPainInfo({
                    ...lowBackPainInfo,
                    fromNerveDistribution: e.target.value,
                  })
                }
                disabled={lowBackPainInfo.deepTouch === "intact bilaterally"}
              >
                <MenuItem value="L2">L2</MenuItem>
                <MenuItem value="L3">L3</MenuItem>
                <MenuItem value="L4">L4</MenuItem>
                <MenuItem value="L5">L5</MenuItem>
                <MenuItem value="S1">S1</MenuItem>
              </Select>
              <Select
                variant="standard"
                color="primary"
                size="medium"
                value={lowBackPainInfo.toNerveDistribution}
                onChange={(e) =>
                  setLowBackPainInfo({
                    ...lowBackPainInfo,
                    toNerveDistribution: e.target.value,
                  })
                }
                disabled={lowBackPainInfo.deepTouch === "intact bilaterally"}
                style={{ marginLeft: "20px" }}
              >
                <MenuItem value="L2">L2</MenuItem>
                <MenuItem value="L3">L3</MenuItem>
                <MenuItem value="L4">L4</MenuItem>
                <MenuItem value="L5">L5</MenuItem>
                <MenuItem value="S1">S1</MenuItem>
              </Select>
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Sensation:
          </Typography>
          <Typography variant="h6">
            Light touch: {lowBackPainInfo.lightTouch}
          </Typography>
          <Typography variant="h6">
            Deep touch:
            {` ${lowBackPainInfo.deepTouch} in ${lowBackPainInfo.fromNerveDistribution}-${lowBackPainInfo.toNerveDistribution}`}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }}>
          <FormControl>
            <Typography variant="h6">Left patella(L2-L4)</Typography>
            <RadioGroup
              value={lowBackPainInfo.leftPatella}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  leftPatella: e.target.value,
                })
              }
              row
            >
              <FormControlLabel
                value="1+"
                control={<Radio size="small" />}
                label="1+"
              />
              <FormControlLabel
                value="2+"
                control={<Radio size="small" />}
                label="2+"
              />
              <FormControlLabel
                value="3+"
                control={<Radio size="small" />}
                label="3+"
              />
              <FormControlLabel
                value="4+"
                control={<Radio size="small" />}
                label="4+"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Right patella(L2-L4)</Typography>
            <RadioGroup
              value={lowBackPainInfo.rightPatella}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  rightPatella: e.target.value,
                })
              }
              row
            >
              <FormControlLabel
                value="1+"
                control={<Radio size="small" />}
                label="1+"
              />
              <FormControlLabel
                value="2+"
                control={<Radio size="small" />}
                label="2+"
              />
              <FormControlLabel
                value="3+"
                control={<Radio size="small" />}
                label="3+"
              />
              <FormControlLabel
                value="4+"
                control={<Radio size="small" />}
                label="4+"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Achilles(S1)</Typography>
            <RadioGroup
              value={lowBackPainInfo.achilles}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  achilles: e.target.value,
                })
              }
              row
            >
              <FormControlLabel
                value="1+"
                control={<Radio size="small" />}
                label="1+"
              />
              <FormControlLabel
                value="2+"
                control={<Radio size="small" />}
                label="2+"
              />
              <FormControlLabel
                value="3+"
                control={<Radio size="small" />}
                label="3+"
              />
              <FormControlLabel
                value="4+"
                control={<Radio size="small" />}
                label="4+"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Babinski Sign</Typography>
            <RadioGroup
              value={lowBackPainInfo.babinskiSign}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  babinskiSign: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Positive on the left"
                control={<Radio />}
                label="Positive on the left"
              />
              <FormControlLabel
                value="Positive on the right"
                control={<Radio />}
                label="Positive on the right"
              />
              <FormControlLabel
                value="Negative bilaterally"
                control={<Radio />}
                label="Negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Reflexes:
          </Typography>
          <Typography variant="h6">
            Left patella(L2-L4): {lowBackPainInfo.leftPatella}
          </Typography>
          <Typography variant="h6">
            Right patella(L2-L4): {lowBackPainInfo.rightPatella}
          </Typography>
          <Typography variant="h6">
            Achilles(S1): {lowBackPainInfo.achilles}
          </Typography>
          <Typography variant="h6">
            Babinski Sign: {lowBackPainInfo.babinskiSign}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <FormControl>
            <Typography variant="h6">SLR</Typography>
            <RadioGroup
              value={lowBackPainInfo.slrSpecialTest}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  slrSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Kemp's</Typography>
            <RadioGroup
              value={lowBackPainInfo.kempsSpecialTest}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  kempsSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">FABER</Typography>
            <RadioGroup
              value={lowBackPainInfo.faberSpecialTest}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  faberSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">FADIR</Typography>
            <RadioGroup
              value={lowBackPainInfo.fadirSpecialTest}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  fadirSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Special Tests:
          </Typography>
          <Typography variant="h6">
            SLR: {lowBackPainInfo.slrSpecialTest}
          </Typography>
          <Typography variant="h6">
            Kemp's: {lowBackPainInfo.kempsSpecialTest}
          </Typography>
          <Typography variant="h6">
            FABER: {lowBackPainInfo.faberSpecialTest}
          </Typography>
          <Typography variant="h6">
            FADIR: {lowBackPainInfo.fadirSpecialTest}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <FormControl>
            <Typography variant="h6">Erector Spinae:</Typography>
            <RadioGroup
              value={lowBackPainInfo.erectorSpinae}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  erectorSpinae: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="tender on the left with taut muscle bellies reproducing referred pain."
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right with taut muscle bellies reproducing referred pain."
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally with taut muscle bellies reproducing referred pain."
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Iliopsoas:</Typography>
            <RadioGroup
              value={lowBackPainInfo.iliopsoas}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  iliopsoas: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="tender on the left with taut muscle bellies reproducing referred pain."
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right with taut muscle bellies reproducing referred pain."
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally with taut muscle bellies reproducing referred pain."
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Quadratus Lumborum:</Typography>
            <RadioGroup
              value={lowBackPainInfo.quadratusLumborum}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  quadratusLumborum: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="tender on the left with taut muscle bellies reproducing referred pain."
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right with taut muscle bellies reproducing referred pain."
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally with taut muscle bellies reproducing referred pain."
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Greater Trochanters:</Typography>
            <RadioGroup
              value={lowBackPainInfo.greaterTrochanters}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  greaterTrochanters: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="tender on the left"
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right"
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally"
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Sacroiliac Joint:</Typography>
            <RadioGroup
              value={lowBackPainInfo.sacroiliacJoint}
              onChange={(e) =>
                setLowBackPainInfo({
                  ...lowBackPainInfo,
                  sacroiliacJoint: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="tender on the left"
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right"
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally"
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Palpation:
          </Typography>
          <Typography variant="h6">
            Erector Spinae: {lowBackPainInfo.erectorSpinae}
          </Typography>
          <Typography variant="h6">
            Iliopsoas: {lowBackPainInfo.iliopsoas}
          </Typography>
          <Typography variant="h6">
            Quadratus Lumborum: {lowBackPainInfo.quadratusLumborum}
          </Typography>
          <Typography variant="h6">
            Greater Trochanters: {lowBackPainInfo.greaterTrochanters}
          </Typography>
          <Typography variant="h6">
            Sacroiliac Joint: {lowBackPainInfo.sacroiliacJoint}
          </Typography>
        </Stack>
      </Stack>
      <div style={{ marginLeft: "300px" }}>
        <Typography variant="h5" style={{ textDecoration: "underline" }}>
          Motor:
        </Typography>
        <TableContainer>
          <Table style={{ width: "500px" }}>
            <TableBody>
              <TableRow key="1">
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Right
                </TableCell>
                <TableCell
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Left
                </TableCell>
              </TableRow>
              <TableRow key="2">
                <TableCell style={{ fontWeight: "bold" }}>L2/3</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Iliacus</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Hip Flexion
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.hipFlexionRight}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        hipFlexionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.hipFlexionLeft}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        hipFlexionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="3">
                <TableCell style={{ fontWeight: "bold" }}>L3/4</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Quadratus Femoris
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Knee Extension
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.kneeExtensionRight}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        kneeExtensionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.kneeExtensionLeft}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        kneeExtensionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="4">
                <TableCell style={{ fontWeight: "bold" }}>L4/5</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Tib Anterior
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Ankle Dorsiflexion
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.ankleDorsiflexionRight}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        ankleDorsiflexionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.ankleDorsiflexionLeft}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        ankleDorsiflexionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="5">
                <TableCell style={{ fontWeight: "bold" }}>L5/S1</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Extensor Hallucis Longus
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Great toe Extension
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.greatToeExtensionRight}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        greatToeExtensionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.greatToeExtensionLeft}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        greatToeExtensionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="6">
                <TableCell style={{ fontWeight: "bold" }}>L4/5</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Tib Anterior/Posterior
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Inversion</TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.inversionRight}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        inversionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.inversionLeft}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        inversionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="7">
                <TableCell style={{ fontWeight: "bold" }}>L5/S1</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Extensor Digitorum Longus, Peroneus
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Eversion</TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.eversionRight}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        eversionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.eversionLeft}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        eversionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="8">
                <TableCell style={{ fontWeight: "bold" }}>L5/S1</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Glut Med</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Hip Abduction
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.hipAbductionRight}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        hipAbductionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.hipAbductionLeft}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        hipAbductionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="9">
                <TableCell style={{ fontWeight: "bold" }}>S1/2</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Glut Max and Hamstring
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Hip Extension
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.hipExtensionRight}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        hipExtensionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.hipExtensionLeft}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        hipExtensionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="10">
                <TableCell style={{ fontWeight: "bold" }}>S1/2</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Gastroc/Soleus
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Plantar Flexion
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.plantarFlexionRight}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        plantarFlexionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={lowBackPainInfo.plantarFlexionLeft}
                    onChange={(e) =>
                      setLowBackPainInfo({
                        ...lowBackPainInfo,
                        plantarFlexionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Stack>
  );
};

export default BackPhysicalExam;
