import React, { useEffect } from "react";
import {
  FormGroup,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Input,
} from "@mui/material";

const NeckPhysicalExam = ({ neckPainInfo, setNeckPainInfo }) => {
  useEffect(() => {
    if (neckPainInfo.deepTouch === "intact bilaterally") {
      setNeckPainInfo({
        ...neckPainInfo,
        fromNerveDistribution: "C4",
        toNerveDistribution: "T1",
      });
    }
  }, [neckPainInfo]);

  const handleCervicalChange = (event) => {
    const { name, checked } = event.target;
    if (name === "full" && checked)
      setNeckPainInfo({
        ...neckPainInfo,
        cervical: {
          full: true,
          limitedFlexion: false,
          limitedExtension: false,
          limitedRotation: false,
          limitedLateralBending: false,
        },
      });
    else
      setNeckPainInfo({
        ...neckPainInfo,
        cervical: {
          ...neckPainInfo.cervical,
          full: false,
          [name]: checked,
        },
      });
  };

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={5}>
        <RadioGroup
          sx={{ width: "30%" }}
          value={neckPainInfo.generalAppearance}
          onChange={(e) =>
            setNeckPainInfo({
              ...neckPainInfo,
              generalAppearance: e.target.value,
            })
          }
        >
          <FormControlLabel value="NAD" control={<Radio />} label="NAD" />
          <FormControlLabel
            value="In acute distress"
            control={<Radio />}
            label="In Acute Distress"
          />
        </RadioGroup>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            General Appearance:
          </Typography>
          <Typography variant="h6">{neckPainInfo.generalAppearance}</Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }}>
          <FormControl>
            <Typography variant="h6">Light touch </Typography>
            <RadioGroup
              value={neckPainInfo.lightTouch}
              onChange={(e) =>
                setNeckPainInfo({ ...neckPainInfo, lightTouch: e.target.value })
              }
            >
              <FormControlLabel
                value="decreased on the left"
                control={<Radio />}
                label="Decreased on the left"
              />
              <FormControlLabel
                value="decreased on the right"
                control={<Radio />}
                label="Decreased on the right"
              />
              <FormControlLabel
                value="decreased bilaterally"
                control={<Radio />}
                label="Decreased bilaterally"
              />
              <FormControlLabel
                value="intact bilaterally"
                control={<Radio />}
                label="Intact bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <Stack direction="column">
            <FormControl>
              <Typography variant="h6" style={{ marginTop: "10px" }}>
                Deep touch
              </Typography>
              <RadioGroup
                value={neckPainInfo.deepTouch}
                onChange={(e) =>
                  setNeckPainInfo({
                    ...neckPainInfo,
                    deepTouch: e.target.value,
                  })
                }
              >
                <FormControlLabel
                  value="decreased on the left"
                  control={<Radio />}
                  label="Decreased on the left"
                />
                <FormControlLabel
                  value="decreased on the right"
                  control={<Radio />}
                  label="Decreased on the right"
                />
                <FormControlLabel
                  value="decreased bilaterally"
                  control={<Radio />}
                  label="Decreased bilaterally"
                />
                <FormControlLabel
                  value="intact bilaterally"
                  control={<Radio />}
                  label="Intact bilaterally"
                />
              </RadioGroup>
            </FormControl>
            <Stack direction="row">
              <Select
                variant="standard"
                color="primary"
                size="medium"
                value={neckPainInfo.fromNerveDistribution}
                onChange={(e) =>
                  setNeckPainInfo({
                    ...neckPainInfo,
                    fromNerveDistribution: e.target.value,
                  })
                }
                disabled={neckPainInfo.deepTouch === "intact bilaterally"}
              >
                <MenuItem value="C4">C4</MenuItem>
                <MenuItem value="C5">C5</MenuItem>
                <MenuItem value="C6">C6</MenuItem>
                <MenuItem value="C7">C7</MenuItem>
                <MenuItem value="C8">C8</MenuItem>
                <MenuItem value="T1">T1</MenuItem>
              </Select>
              <Select
                variant="standard"
                color="primary"
                size="medium"
                value={neckPainInfo.toNerveDistribution}
                onChange={(e) =>
                  setNeckPainInfo({
                    ...neckPainInfo,
                    toNerveDistribution: e.target.value,
                  })
                }
                disabled={neckPainInfo.deepTouch === "intact bilaterally"}
                style={{ marginLeft: "20px" }}
              >
                <MenuItem value="C4">C4</MenuItem>
                <MenuItem value="C5">C5</MenuItem>
                <MenuItem value="C6">C6</MenuItem>
                <MenuItem value="C7">C7</MenuItem>
                <MenuItem value="C8">C8</MenuItem>
                <MenuItem value="T1">T1</MenuItem>
              </Select>
            </Stack>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Sensation:
          </Typography>
          <Typography variant="h6">
            Light touch:{` ${neckPainInfo.lightTouch}`}
          </Typography>
          <Typography variant="h6">
            Deep touch:
            {` ${neckPainInfo.deepTouch} in ${neckPainInfo.fromNerveDistribution}-${neckPainInfo.toNerveDistribution}`}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }}>
          <FormControl>
            <Typography variant="h6">Left tricep</Typography>
            <RadioGroup
              value={neckPainInfo.leftTricep}
              onChange={(e) =>
                setNeckPainInfo({ ...neckPainInfo, leftTricep: e.target.value })
              }
              row
            >
              <FormControlLabel
                value="1+"
                control={<Radio size="small" />}
                label="1+"
              />
              <FormControlLabel
                value="2+"
                control={<Radio size="small" />}
                label="2+"
              />
              <FormControlLabel
                value="3+"
                control={<Radio size="small" />}
                label="3+"
              />
              <FormControlLabel
                value="4+"
                control={<Radio size="small" />}
                label="4+"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Right tricep</Typography>
            <RadioGroup
              value={neckPainInfo.rightTricep}
              onChange={(e) =>
                setNeckPainInfo({
                  ...neckPainInfo,
                  rightTricep: e.target.value,
                })
              }
              row
            >
              <FormControlLabel
                value="1+"
                control={<Radio size="small" />}
                label="1+"
              />
              <FormControlLabel
                value="2+"
                control={<Radio size="small" />}
                label="2+"
              />
              <FormControlLabel
                value="3+"
                control={<Radio size="small" />}
                label="3+"
              />
              <FormControlLabel
                value="4+"
                control={<Radio size="small" />}
                label="4+"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Bilateral BR, Bicep</Typography>
            <RadioGroup
              value={neckPainInfo.bilateralBR_Bicep}
              onChange={(e) =>
                setNeckPainInfo({
                  ...neckPainInfo,
                  bilateralBR_Bicep: e.target.value,
                })
              }
              row
            >
              <FormControlLabel
                value="1+"
                control={<Radio size="small" />}
                label="1+"
              />
              <FormControlLabel
                value="2+"
                control={<Radio size="small" />}
                label="2+"
              />
              <FormControlLabel
                value="3+"
                control={<Radio size="small" />}
                label="3+"
              />
              <FormControlLabel
                value="4+"
                control={<Radio size="small" />}
                label="4+"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Hoffman</Typography>
            <RadioGroup
              value={neckPainInfo.hoffman}
              onChange={(e) =>
                setNeckPainInfo({ ...neckPainInfo, hoffman: e.target.value })
              }
              row
            >
              <FormControlLabel
                value="negative"
                control={<Radio />}
                label="negative"
              />
              <FormControlLabel
                value="positive"
                control={<Radio />}
                label="positive"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Reflexes:
          </Typography>
          <Typography variant="h6">
            Left tricep: {neckPainInfo.leftTricep}
          </Typography>
          <Typography variant="h6">
            Right tricep: {neckPainInfo.rightTricep}
          </Typography>
          <Typography variant="h6">
            Bilateral BR, Bicep: {neckPainInfo.bilateralBR_Bicep}
          </Typography>
          <Typography variant="h6">Hoffman: {neckPainInfo.hoffman} </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <FormControl>
            <Typography variant="h6">Cervical</Typography>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={neckPainInfo.cervical.full}
                      name="full"
                      onChange={handleCervicalChange}
                    />
                  }
                  label="Full"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={neckPainInfo.cervical.limitedFlexion}
                      name="limitedFlexion"
                      onChange={handleCervicalChange}
                    />
                  }
                  label="Limited Flexion"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={neckPainInfo.cervical.limitedExtension}
                      name="limitedExtension"
                      onChange={handleCervicalChange}
                    />
                  }
                  label="Limited Extension"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={neckPainInfo.cervical.limitedRotation}
                      name="limitedRotation"
                      onChange={handleCervicalChange}
                    />
                  }
                  label="Limited Rotation"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={neckPainInfo.cervical.limitedLateralBending}
                      name="limitedLateralBending"
                      onChange={handleCervicalChange}
                    />
                  }
                  label="Limited Lateral Bending"
                />
              </FormGroup>
            </FormControl>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Shoulder</Typography>
            <RadioGroup
              value={neckPainInfo.shoulder}
              onChange={(e) =>
                setNeckPainInfo({ ...neckPainInfo, shoulder: e.target.value })
              }
              row
            >
              <FormControlLabel value="Full" control={<Radio />} label="Full" />
              <FormControlLabel
                value="Limited"
                control={<Radio />}
                label="Limited"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Rom:
          </Typography>
          {neckPainInfo.cervical["full"] && (
            <Typography variant="h6">Cervical: Full</Typography>
          )}
          {neckPainInfo.cervical["limitedFlexion"] && (
            <Typography variant="h6">Cervical: Limited Flexion</Typography>
          )}
          {neckPainInfo.cervical["limitedExtension"] && (
            <Typography variant="h6">Cervical: Limited Extension</Typography>
          )}
          {neckPainInfo.cervical["limitedRotation"] && (
            <Typography variant="h6">Cervical: Limited Rotation</Typography>
          )}
          {neckPainInfo.cervical["limitedLateralBending"] && (
            <Typography variant="h6">Cervical: Lateral Bending</Typography>
          )}
          <Typography variant="h6">
            Shoulder: {neckPainInfo.shoulder}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <FormControl>
            <Typography variant="h6">Kemp's</Typography>
            <RadioGroup
              value={neckPainInfo.kempsSpecialTest}
              onChange={(e) =>
                setNeckPainInfo({
                  ...neckPainInfo,
                  kempsSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Hawkins</Typography>
            <RadioGroup
              value={neckPainInfo.hawkinsSpecialTest}
              onChange={(e) =>
                setNeckPainInfo({
                  ...neckPainInfo,
                  hawkinsSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Empty Can</Typography>
            <RadioGroup
              value={neckPainInfo.emptyCanSpecialTest}
              onChange={(e) =>
                setNeckPainInfo({
                  ...neckPainInfo,
                  emptyCanSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Neers</Typography>
            <RadioGroup
              value={neckPainInfo.neersSpecialTest}
              onChange={(e) =>
                setNeckPainInfo({
                  ...neckPainInfo,
                  neersSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Tinnel's</Typography>
            <RadioGroup
              value={neckPainInfo.tinnelsSpecialTest}
              onChange={(e) =>
                setNeckPainInfo({
                  ...neckPainInfo,
                  tinnelsSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Spurling's</Typography>
            <RadioGroup
              value={neckPainInfo.spurlingsSpecialTest}
              onChange={(e) =>
                setNeckPainInfo({
                  ...neckPainInfo,
                  spurlingsSpecialTest: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="positive on the left"
                control={<Radio />}
                label="positive on the left"
              />
              <FormControlLabel
                value="positive on the right"
                control={<Radio />}
                label="positive on the right"
              />
              <FormControlLabel
                value="positive bilaterally"
                control={<Radio />}
                label="positive bilaterally"
              />
              <FormControlLabel
                value="negative bilaterally"
                control={<Radio />}
                label="negative bilaterally"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Special Tests:
          </Typography>
          <Typography variant="h6">
            Kemp's: {neckPainInfo.kempsSpecialTest}
          </Typography>
          <Typography variant="h6">
            Hawkins: {neckPainInfo.hawkinsSpecialTest}
          </Typography>
          <Typography variant="h6">
            Empty Can: {neckPainInfo.emptyCanSpecialTest}
          </Typography>
          <Typography variant="h6">
            Neers: {neckPainInfo.neersSpecialTest}
          </Typography>
          <Typography variant="h6">
            Tinnel's: {neckPainInfo.tinnelsSpecialTest}
          </Typography>
          <Typography variant="h6">
            Spurling's: {neckPainInfo.spurlingsSpecialTest}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" spacing={5}>
        <Stack sx={{ width: "30%" }} spacing={3}>
          <FormControl>
            <Typography variant="h6">Cervical paraspinals:</Typography>
            <RadioGroup
              value={neckPainInfo.cervicalParaspinals}
              onChange={(e) =>
                setNeckPainInfo({
                  ...neckPainInfo,
                  cervicalParaspinals: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="tender on the left with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Levator scapula:</Typography>
            <RadioGroup
              value={neckPainInfo.levatorScapula}
              onChange={(e) =>
                setNeckPainInfo({
                  ...neckPainInfo,
                  levatorScapula: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="tender on the left with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Trapezius:</Typography>
            <RadioGroup
              value={neckPainInfo.trapezius}
              onChange={(e) =>
                setNeckPainInfo({ ...neckPainInfo, trapezius: e.target.value })
              }
            >
              <FormControlLabel
                value="tender on the left with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally with taut muscle bellies reproducing referred pain"
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <Typography variant="h6">Bicipital tendon:</Typography>
            <RadioGroup
              value={neckPainInfo.bicipitalTendon}
              onChange={(e) =>
                setNeckPainInfo({
                  ...neckPainInfo,
                  bicipitalTendon: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="tender on the left"
                control={<Radio />}
                label="tender on the left"
              />
              <FormControlLabel
                value="tender on the right"
                control={<Radio />}
                label="tender on the right"
              />
              <FormControlLabel
                value="tender bilaterally"
                control={<Radio />}
                label="tender bilaterally"
              />
              <FormControlLabel
                value="non-tender bilaterally"
                control={<Radio />}
                label="non-tender bilaterally"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Palpation:
          </Typography>
          <Typography variant="h6">
            Cervical paraspinals: {neckPainInfo.cervicalParaspinals}
          </Typography>
          <Typography variant="h6">
            Levator Scapula: {neckPainInfo.levatorScapula}
          </Typography>
          <Typography variant="h6">
            Trapezius: {neckPainInfo.trapezius}
          </Typography>
          <Typography variant="h6">
            Bicipital tendon: {neckPainInfo.bicipitalTendon}
          </Typography>
        </Stack>
      </Stack>

      <Stack style={{ marginLeft: "300px" }}>
        <Typography variant="h5" style={{ textDecoration: "underline" }}>
          Motor:
        </Typography>
        <TableContainer>
          <Table style={{ width: "500px" }}>
            <TableBody>
              <TableRow key="1">
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Right
                </TableCell>
                <TableCell
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Left
                </TableCell>
              </TableRow>
              <TableRow key="2">
                <TableCell style={{ fontWeight: "bold" }}>C4</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Shoulder Abduction
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.shoulderAbductionRight}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        shoulderAbductionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.shoulderAbductionLeft}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        shoulderAbductionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="3">
                <TableCell style={{ fontWeight: "bold" }}>C5</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Elbow Flexion
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.elbowFlexionRight}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        elbowFlexionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.elbowFlexionLeft}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        elbowFlexionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="4">
                <TableCell style={{ fontWeight: "bold" }}>C6</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Wrist Extension
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.wristExtensionRight}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        wristExtensionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.wristExtensionLeft}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        wristExtensionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="5">
                <TableCell style={{ fontWeight: "bold" }}>C7</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Elbow Extension
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.elbowExtensionRight}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        elbowExtensionRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.elbowExtensionLeft}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        elbowExtensionLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="6">
                <TableCell style={{ fontWeight: "bold" }}>C8/T1</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Hand Intrinsics
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.handIntrinsicsRight}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        handIntrinsicsRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.handIntrinsicsLeft}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        handIntrinsicsLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="7">
                <TableCell style={{ fontWeight: "bold" }}>C8</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  First Dorsal Interosseus
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.firstDorsalInterosseusRight}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        firstDorsalInterosseusRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.firstDorsalInterosseusLeft}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        firstDorsalInterosseusLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="8">
                <TableCell style={{ fontWeight: "bold" }}>C8</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Abductor Pollicus Brevis
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.abductorPollicusBrevisRight}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        abductorPollicusBrevisRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.abductorPollicusBrevisLeft}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        abductorPollicusBrevisLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow key="9">
                <TableCell style={{ fontWeight: "bold" }}>C5/6</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Shoulder External Rotation
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.shoulderExternalRotationRight}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        shoulderExternalRotationRight: e.target.value,
                      })
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    variant="filled"
                    color="primary"
                    size="medium"
                    type="number"
                    inputProps={{ min: 1, max: 5 }}
                    value={neckPainInfo.shoulderExternalRotationLeft}
                    onChange={(e) =>
                      setNeckPainInfo({
                        ...neckPainInfo,
                        shoulderExternalRotationLeft: e.target.value,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

export default NeckPhysicalExam;
