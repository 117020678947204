import React, { useContext } from "react";
import { Paper, Input, Typography, Stack } from "@mui/material";
import { Context } from "../../context";
import { MEDICAL_BILLING_PERSON } from "../../config";

const PatientInfoEditInput = ({
  name,
  value,
  updatePatientInfo,
  painConsultNoteSigned,
}) => {
  const { user } = useContext(Context);
  return (
    <Input
      variant="filled"
      color="primary"
      size="medium"
      fullWidth
      value={value}
      name={name}
      onChange={(e) => updatePatientInfo(e)}
      readOnly={user && user.role === MEDICAL_BILLING_PERSON}
    />
  );
};

const EmergencyContact = ({
  emergencyContact,
  setEmergencyContact,
  painConsultNoteSigned,
}) => {
  const updateEmergencyContact = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setEmergencyContact((values) => ({ ...values, [name]: value }));
  };

  return (
    <Paper style={{ padding: "20px", boxSizing: "border-box", width: "50%" }}>
      <Typography variant="h6" fontWeight="bold" marginBottom="10px">
        Emergency Contact:
      </Typography>
      <Stack direction="column" spacing={1}>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Emergency Contact Name
          </Typography>
          <PatientInfoEditInput
            name="contact_name"
            value={emergencyContact.contact_name}
            updatePatientInfo={updateEmergencyContact}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Emergency Contact Number
          </Typography>
          <PatientInfoEditInput
            name="contact_number"
            value={emergencyContact.contact_number}
            updatePatientInfo={updateEmergencyContact}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
        <Stack direction="row">
          <Typography variant="h6" width="50%">
            Emergency Contact Relation
          </Typography>
          <PatientInfoEditInput
            name="contact_relation"
            value={emergencyContact.contact_relation}
            updatePatientInfo={updateEmergencyContact}
            painConsultNoteSigned={painConsultNoteSigned}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default EmergencyContact;
