import React from "react";
import {
  Stack,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const ElbowPhysicalExam = ({ elbowPainInfo, setElbowPainInfo }) => {
  return (
    <Stack spacing={3}>
      {/* Laterality Section */}
      <Stack direction="row" spacing={5}>
        <Stack spacing={1} sx={{ width: "30%" }}>
          <Stack>
            <Typography variant="h6">Laterality</Typography>
            <RadioGroup
              value={elbowPainInfo.laterality}
              onChange={(e) =>
                setElbowPainInfo({
                  ...elbowPainInfo,
                  laterality: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Right elbow"
                control={<Radio />}
                label="Right"
              />
              <FormControlLabel
                value="Left elbow"
                control={<Radio />}
                label="Left"
              />
              <FormControlLabel
                value="Bilateral elbows"
                control={<Radio />}
                label="Bilateral"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Laterality:
          </Typography>
          <Typography variant="h6">{elbowPainInfo.laterality}</Typography>
        </Stack>
      </Stack>

      {/* Inspection Section */}
      <Stack direction="row" spacing={5}>
        <Stack spacing={1} sx={{ width: "30%" }}>
          <Stack>
            <Typography variant="h6">Apperance</Typography>
            <RadioGroup
              value={elbowPainInfo.appearance}
              onChange={(e) =>
                setElbowPainInfo({
                  ...elbowPainInfo,
                  appearance: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No visible abnormality"
                control={<Radio />}
                label="No visible abnormality"
              />
              <FormControlLabel
                value="Swelling"
                control={<Radio />}
                label="Swelling"
              />
              <FormControlLabel
                value="Deformity"
                control={<Radio />}
                label="Deformity"
              />
              <FormControlLabel
                value="Redness"
                control={<Radio />}
                label="Redness"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Skin</Typography>
            <RadioGroup
              value={elbowPainInfo.skin}
              onChange={(e) =>
                setElbowPainInfo({ ...elbowPainInfo, skin: e.target.value })
              }
            >
              <FormControlLabel
                value="Normal"
                control={<Radio />}
                label="Normal"
              />
              <FormControlLabel value="Rash" control={<Radio />} label="Rash" />
              <FormControlLabel
                value="Bruising"
                control={<Radio />}
                label="Bruising"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Elbow Inspection:
          </Typography>
          <Typography variant="h6">
            Appearance: {elbowPainInfo.appearance}
          </Typography>
          <Typography variant="h6">Skin: {elbowPainInfo.skin}</Typography>
        </Stack>
      </Stack>

      {/* Palpation section */}
      <Stack direction="row" spacing={5}>
        <Stack spacing={1} sx={{ width: "30%" }}>
          <Stack>
            <Typography variant="h6">Bony Landmarks</Typography>
            <RadioGroup
              value={elbowPainInfo.bonyLandmarks}
              onChange={(e) =>
                setElbowPainInfo({
                  ...elbowPainInfo,
                  bonyLandmarks: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No tenderness"
                control={<Radio />}
                label="No tenderness"
              />
              <FormControlLabel
                value="Tenderness at lateral epicondyle"
                control={<Radio />}
                label="Tenderness at lateral epicondyle"
              />
              <FormControlLabel
                value="Tenderness at medial epicondyle"
                control={<Radio />}
                label="Tenderness at medial epicondyle"
              />
              <FormControlLabel
                value="Tenderness at olecranon"
                control={<Radio />}
                label="Tenderness at olecranon"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Soft Tissue</Typography>
            <RadioGroup
              value={elbowPainInfo.softTissue}
              onChange={(e) =>
                setElbowPainInfo({
                  ...elbowPainInfo,
                  softTissue: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="No tenderness"
                control={<Radio />}
                label="No tenderness"
              />
              <FormControlLabel
                value="Tenderness over biceps tendon"
                control={<Radio />}
                label="Tenderness over biceps tendon"
              />
              <FormControlLabel
                value="Tenderness over triceps tendon"
                control={<Radio />}
                label="Tenderness over triceps tendon"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Elbow Palpation:
          </Typography>
          <Typography variant="h6">
            Bony landmarks: {elbowPainInfo.bonyLandmarks}
          </Typography>
          <Typography variant="h6">
            Soft tissue: {elbowPainInfo.softTissue}
          </Typography>
        </Stack>
      </Stack>

      {/* Range of Motion Section */}
      <Stack direction="row" spacing={5}>
        <Stack spacing={1} sx={{ width: "30%" }}>
          <Stack>
            <Typography variant="h6">Active</Typography>
            <RadioGroup
              value={elbowPainInfo.active}
              onChange={(e) =>
                setElbowPainInfo({ ...elbowPainInfo, active: e.target.value })
              }
            >
              <FormControlLabel
                value="Full range without pain"
                control={<Radio />}
                label="Full range without pain"
              />
              <FormControlLabel
                value="Pain with flexion"
                control={<Radio />}
                label="Pain with flexion"
              />
              <FormControlLabel
                value="Pain with extension"
                control={<Radio />}
                label="Pain with extension"
              />
              <FormControlLabel
                value="Pain with pronation"
                control={<Radio />}
                label="Pain with pronation"
              />
              <FormControlLabel
                value="Pain with supination"
                control={<Radio />}
                label="Pain with supination"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Passive</Typography>
            <RadioGroup
              value={elbowPainInfo.passive}
              onChange={(e) =>
                setElbowPainInfo({ ...elbowPainInfo, passive: e.target.value })
              }
            >
              <FormControlLabel
                value="Full range without pain"
                control={<Radio />}
                label="Full range without pain"
              />
              <FormControlLabel
                value="Limited by pain"
                control={<Radio />}
                label="Limited by pain"
              />
              <FormControlLabel
                value="Crepitus"
                control={<Radio />}
                label="Crepitus"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Range of Motion:
          </Typography>
          <Typography variant="h6">Active: {elbowPainInfo.active}</Typography>
          <Typography variant="h6">Passive: {elbowPainInfo.passive}</Typography>
        </Stack>
      </Stack>

      {/* Strength Testing Section */}
      <Stack direction="row" spacing={5}>
        <Stack spacing={1} sx={{ width: "30%" }}>
          <Stack>
            <Typography variant="h6">Biceps Muscle</Typography>
            <RadioGroup
              value={elbowPainInfo.bicepsMuscle}
              onChange={(e) =>
                setElbowPainInfo({
                  ...elbowPainInfo,
                  bicepsMuscle: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal strength"
                control={<Radio />}
                label="Normal strength"
              />
              <FormControlLabel
                value="Weakness"
                control={<Radio />}
                label="Weakness"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Triceps Muscle</Typography>
            <RadioGroup
              value={elbowPainInfo.tricepsMuscle}
              onChange={(e) =>
                setElbowPainInfo({
                  ...elbowPainInfo,
                  tricepsMuscle: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal strength"
                control={<Radio />}
                label="Normal strength"
              />
              <FormControlLabel
                value="Weakness"
                control={<Radio />}
                label="Weakness"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Wrist Extensors</Typography>
            <RadioGroup
              value={elbowPainInfo.wristExtensors}
              onChange={(e) =>
                setElbowPainInfo({
                  ...elbowPainInfo,
                  wristExtensors: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Normal strength"
                control={<Radio />}
                label="Normal strength"
              />
              <FormControlLabel
                value="Weakness"
                control={<Radio />}
                label="Weakness"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Strength Testing:
          </Typography>
          <Typography variant="h6">
            Biceps Muscle: {elbowPainInfo.bicepsMuscle}
          </Typography>
          <Typography variant="h6">
            Triceps Muscle: {elbowPainInfo.tricepsMuscle}
          </Typography>
          <Typography variant="h6">
            Wrist Extensors: {elbowPainInfo.wristExtensors}
          </Typography>
        </Stack>
      </Stack>

      {/* Special Tests Section */}
      <Stack direction="row" spacing={5}>
        <Stack spacing={1} sx={{ width: "30%" }}>
          <Stack>
            <Typography variant="h6">Epicondylitis</Typography>
            <RadioGroup
              value={elbowPainInfo.epicondylitis}
              onChange={(e) =>
                setElbowPainInfo({
                  ...elbowPainInfo,
                  epicondylitis: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Positive Cozen's test"
                control={<Radio />}
                label="Positive Cozen's test"
              />
              <FormControlLabel
                value="Positive Mill's test"
                control={<Radio />}
                label="Positive Mill's test"
              />
              <FormControlLabel
                value="Negative"
                control={<Radio />}
                label="Negative"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Nerve Entrapment</Typography>
            <RadioGroup
              value={elbowPainInfo.nerveEntrapment}
              onChange={(e) =>
                setElbowPainInfo({
                  ...elbowPainInfo,
                  nerveEntrapment: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Positive Tinel's sign at elbow"
                control={<Radio />}
                label="Positive Tinel's sign at elbow"
              />
              <FormControlLabel
                value="Positive elbow flexion test"
                control={<Radio />}
                label="Positive elbow flexion test"
              />
              <FormControlLabel
                value="Negative"
                control={<Radio />}
                label="Negative"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Special Tests:
          </Typography>
          <Typography variant="h6">
            Epicondylitis: {elbowPainInfo.epicondylitis}
          </Typography>
          <Typography variant="h6">
            Nerve Entrapment: {elbowPainInfo.nerveEntrapment}
          </Typography>
        </Stack>
      </Stack>

      {/* Neurovascular Assessment */}
      <Stack direction="row" spacing={5}>
        <Stack spacing={1} sx={{ width: "30%" }}>
          <Stack>
            <Typography variant="h6">Sensation</Typography>
            <RadioGroup
              value={elbowPainInfo.sensation}
              onChange={(e) =>
                setElbowPainInfo({
                  ...elbowPainInfo,
                  sensation: e.target.value,
                })
              }
            >
              <FormControlLabel
                value="Intact"
                control={<Radio />}
                label="Intact"
              />
              <FormControlLabel
                value="Decreased in median nerve distribution"
                control={<Radio />}
                label="Decreased in median nerve distribution"
              />
              <FormControlLabel
                value="Decreased in ulnar nerve distribution"
                control={<Radio />}
                label="Decreased in ulnar nerve distribution"
              />
              <FormControlLabel
                value="Decreased in radial nerve distribution"
                control={<Radio />}
                label="Decreased in radial nerve distribution"
              />
            </RadioGroup>
          </Stack>
          <Stack>
            <Typography variant="h6">Pulses</Typography>
            <RadioGroup
              value={elbowPainInfo.pulses}
              onChange={(e) =>
                setElbowPainInfo({ ...elbowPainInfo, pulses: e.target.value })
              }
            >
              <FormControlLabel
                value="Intact brachial pulse"
                control={<Radio />}
                label="Intact brachial pulse"
              />
              <FormControlLabel
                value="Intact radial pulse"
                control={<Radio />}
                label="Intact radial pulse"
              />
              <FormControlLabel
                value="Intact ulnar pulse"
                control={<Radio />}
                label="Intact ulnar pulse"
              />
            </RadioGroup>
          </Stack>
        </Stack>
        <Stack sx={{ width: "70%" }}>
          <Typography variant="h5" style={{ textDecoration: "underline" }}>
            Neurovascular Assessment:
          </Typography>
          <Typography variant="h6">
            Sensation: {elbowPainInfo.sensation}
          </Typography>
          <Typography variant="h6">Pulses: {elbowPainInfo.pulses}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ElbowPhysicalExam;
