import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  Paper,
  TextField,
  Button,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { api } from '../config';
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.value)
      setEmailError(false);
    else
      setEmailError(true);
    setEmail(e.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError(true);
    } else {
      setLoading(true);
      api.post('/auth/forgot-password', {
        email
      }).then(() => {
        toast.success("Please check your mail box, reset password link has been sent!");
        navigate('/login');
        setLoading(false);
      }).catch(e => {
        toast.error(e.response.data.message);
        setLoading(false);
      });
    }
  }

  const handleCancel = () => {
    navigate("/login");
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper style={{ minWidth: '400px', maxWidth: '400px', padding: '50px' }}>
        <form onSubmit={handleSubmit}>
          <Stack direction='column' spacing={2}>
            <Typography variant='h4' style={{ textAlign: 'center' }}>Forgot Password</Typography>
            <TextField label="Email" type="email" name="email" value={email} onChange={handleChange} error={emailError}></TextField>
            <Stack direction="row" spacing={1}>
              <Button variant='contained' color='error' style={{ width: '50%' }} onClick={handleCancel}>cancel</Button>
              <LoadingButton style={{ width: '50%' }} onClick={handleSubmit} loading={loading} variant="contained">
                Submit
              </LoadingButton>
            </Stack>
          </Stack>
        </form>
      </Paper>
    </div>
  )
}

export default ForgotPassword;