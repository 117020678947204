import React from "react";
import { Stack, Typography } from "@mui/material";

const PMH = ({ pastMedicalHistory }) => {
  return (
    <Stack direction="column">
      <Typography variant="h5" fontWeight="bold">
        PAST MEDICAL HISTORY:
      </Typography>
      {pastMedicalHistory.length > 0 ? (
        <Typography variant="h6">
          Medical History includes: {pastMedicalHistory.join(", ")}
        </Typography>
      ) : (
        <Typography variant="h6">None</Typography>
      )}
    </Stack>
  );
};

export default PMH;
