import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  TextField,
  Typography,
  Chip,
} from "@mui/material";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { secureApi } from "../../config";
import { Context } from "../../context";

const Eligibility = () => {
  const { setLoggedIn } = useContext(Context);
  const navigate = useNavigate();
  const { patient_id } = useParams();
  const [eligibilities, setEligibilities] = useState([]);
  const [eligibilityDialogOpen, setEligibilityDialogOpen] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [eligibilityID, setEligibilityID] = useState(null);
  const [procedure, setProcedure] = useState("");
  const [procedureError, setProcedureError] = useState(false);
  const [procedureDate, setProcedureDate] = useState(null);
  const [procedureDateError, setProcedureDateError] = useState(false);
  const [status, setStatus] = useState("Not Started");
  const [coverageLimits, setCoverageLimits] = useState("");
  const [coverageLimitsError, setCoverageLimitsError] = useState(false);
  const [currentAmountBilled, setCurrentAmountBilled] = useState("");
  const [currentAmountBilledError, setCurrentAmountBilledError] =
    useState(false);
  const [amountPaidOut, setAmountPaidOut] = useState("");
  const [amountPaidOutError, setAmountPaidOutError] = useState(false);
  const [remainingOnPolicy, setRemainingOnPolicy] = useState("");
  const [remainingOnPolicyError, setRemainingOnPolicyError] = useState(false);
  const [notes, setNotes] = useState("");
  const [contact, setContact] = useState({
    first_name: "",
    last_name: "",
    id_number: "",
    phone_number: "",
    email: "",
  });

  const handleAddButtonClick = () => {
    setEligibilityID(null);
    setEligibilityDialogOpen(true);
  };

  const handleEditButtonClick = (eligibilityID, index) => {
    setEligibilityID(eligibilityID);
    const eligibility = eligibilities[index];
    setProcedure(eligibility.procedure);
    setProcedureDate(dayjs(eligibility.procedure_date));
    setStatus(eligibility.status);
    setCoverageLimits(eligibility.coverage_limits);
    setCurrentAmountBilled(eligibility.current_amount_billed);
    setAmountPaidOut(eligibility.amount_paid_out);
    setRemainingOnPolicy(eligibility.remaining_on_policy);
    setNotes(eligibility.notes);
    setEligibilityDialogOpen(true);
  };

  const handleContactButtonClick = (eligibilityID, index) => {
    setEligibilityID(eligibilityID);
    const eligibility = eligibilities[index];
    setContact({
      first_name: eligibility.contact ? eligibility.contact.first_name : "",
      last_name: eligibility.contact ? eligibility.contact.last_name : "",
      id_number: eligibility.contact ? eligibility.contact.id_number : "",
      phone_number: eligibility.contact ? eligibility.contact.phone_number : "",
      email: eligibility.contact ? eligibility.contact.email : "",
    });
    setContactDialogOpen(true);
  };

  const handleEligibilityDialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setProcedure("");
      setProcedureError(false);
      setProcedureDate(null);
      setProcedureDateError(false);
      setStatus("Not Started");
      setCoverageLimits("");
      setCoverageLimitsError(false);
      setCurrentAmountBilled("");
      setCurrentAmountBilledError(false);
      setAmountPaidOut("");
      setAmountPaidOutError(false);
      setRemainingOnPolicy("");
      setRemainingOnPolicyError(false);
      setNotes("");
      setEligibilityDialogOpen(false);
    }
  };

  const handleProcedureDateChange = (e) => {
    setProcedureDate(e);
    if (e) setProcedureDateError(false);
    else setProcedureDateError(true);
  };

  const handleChange = (field, event) => {
    const value = event.target.value;
    if (field === "procedure") {
      setProcedure(value);
      if (value) setProcedureError(false);
      else setProcedureError(true);
    } else if (field === "status") {
      setStatus(value);
    } else if (field === "coverage limits") {
      setCoverageLimits(value);
      if (value) setCoverageLimitsError(false);
      else setCoverageLimitsError(true);
    } else if (field === "current amount billed") {
      setCurrentAmountBilled(value);
      if (value) setCurrentAmountBilledError(false);
      else setCurrentAmountBilledError(true);
    } else if (field === "amount paid out") {
      setAmountPaidOut(value);
      if (value) setAmountPaidOutError(false);
      else setAmountPaidOutError(true);
    } else if (field === "remaining on policy") {
      setRemainingOnPolicy(value);
      if (value) setRemainingOnPolicyError(false);
      else setRemainingOnPolicyError(true);
    } else if (field === "notes") {
      setNotes(value);
    }
  };

  const handleContactChange = (event) => {
    const { name, value } = event.target;
    setContact((values) => ({ ...values, [name]: value }));
  };

  const handleSave = () => {
    if (!procedure) setProcedureError(true);
    if (!procedureDate) setProcedureDateError(true);
    if (!coverageLimits) setCoverageLimitsError(true);
    if (!currentAmountBilled) setCurrentAmountBilledError(true);
    if (!amountPaidOut) setAmountPaidOutError(true);
    if (!remainingOnPolicy) setRemainingOnPolicyError(true);

    if (
      procedure &&
      procedureDate &&
      coverageLimits &&
      currentAmountBilled &&
      amountPaidOut &&
      remainingOnPolicy
    ) {
      if (eligibilityID) {
        secureApi(localStorage.getItem("alex-med-token"))
          .put(`/eligibility/${eligibilityID}`, {
            procedure,
            procedureDate: procedureDate.format("MM/DD/YYYY"),
            status,
            coverageLimits,
            currentAmountBilled,
            amountPaidOut,
            remainingOnPolicy,
            notes,
          })
          .then(() => {
            toast.success("Eligibility has been updated successfully!");
            const newEligibilities = eligibilities.map((eligibility) => {
              if (eligibility._id === eligibilityID) {
                return {
                  ...eligibility,
                  procedure,
                  procedure_date: procedureDate.format("MM/DD/YYYY"),
                  status,
                  coverage_limits: coverageLimits,
                  current_amount_billed: currentAmountBilled,
                  amount_paid_out: amountPaidOut,
                  remaining_on_policy: remainingOnPolicy,
                  notes,
                };
              } else {
                return eligibility;
              }
            });
            setEligibilities(newEligibilities);
            handleEligibilityDialogClose();
          })
          .catch((e) => {
            console.log(e);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else if (e.response && e.response.data) {
              toast.error(e.response.data.message);
            } else {
              toast.error(
                "Something went wrong while updating an eligibility."
              );
            }
          });
      } else {
        secureApi(localStorage.getItem("alex-med-token"))
          .post(`/eligibility`, {
            patient_id,
            procedure,
            procedureDate: procedureDate.format("MM/DD/YYYY"),
            status,
            coverageLimits,
            currentAmountBilled,
            amountPaidOut,
            remainingOnPolicy,
            notes,
          })
          .then((response) => {
            const { eligibility_id } = response.data;
            setEligibilities([
              ...eligibilities,
              {
                _id: eligibility_id,
                procedure,
                procedure_date: procedureDate.format("MM/DD/YYYY"),
                status,
                coverage_limits: coverageLimits,
                current_amount_billed: currentAmountBilled,
                amount_paid_out: amountPaidOut,
                remaining_on_policy: remainingOnPolicy,
                notes,
              },
            ]);
            handleEligibilityDialogClose();
            toast.success("Eligibility created successfully.");
          })
          .catch((e) => {
            console.log(e);
            if (e.response && e.response.status === 401) {
              toast.warn("Session has been expired. You need to login again!");
              setLoggedIn(false);
              navigate("/login");
            } else if (e.response && e.response.data) {
              toast.error(e.response.data.message);
            } else {
              toast.error(
                "Something went wrong while creating an eligibility."
              );
            }
          });
      }
    }
  };

  const handleSaveContact = () => {
    if (eligibilityID) {
      secureApi(localStorage.getItem("alex-med-token"))
        .put(`/eligibility-contact/${eligibilityID}`, contact)
        .then(() => {
          let newEligibilities = [...eligibilities];
          newEligibilities = newEligibilities.map((eligibility) => {
            if (eligibility._id === eligibilityID)
              return {
                ...eligibility,
                contact,
              };
            else return eligibility;
          });
          setEligibilities(newEligibilities);
          handleContactDialogClose();
          toast.success("Saved contact info successfully!");
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else if (e.response && e.response.data) {
            toast.error(e.response.data.message);
          } else {
            toast.error("Something went wrong while saving contact info.");
          }
        });
    }
  };

  const handleContactDialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setContactDialogOpen(false);
    }
  };

  useEffect(() => {
    if (patient_id) {
      secureApi(localStorage.getItem("alex-med-token"))
        .get(`/eligibilities/${patient_id}`)
        .then((response) => {
          setEligibilities(response.data.eligibilities);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else if (e.response && e.response.data) {
            toast.error(e.response.data.message);
          } else {
            toast.error("Something went wrong while getting eligibilities.");
          }
        });
    }
  }, [patient_id]);
  return (
    <Stack direction="column" spacing={1}>
      <Button
        variant="contained"
        style={{ width: "fit-content" }}
        startIcon={<AddIcon />}
        onClick={handleAddButtonClick}
      >
        Add New
      </Button>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHead>
              <TableRow style={{ background: "grey" }}>
                <TableCell
                  key="procedure"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Procedure
                </TableCell>
                <TableCell
                  key="procedure date"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Procedure Date
                </TableCell>
                <TableCell
                  key="status"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Status
                </TableCell>
                <TableCell
                  key="coverage limits"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Coverage Limits
                </TableCell>
                <TableCell
                  key="current amount billed"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Current Amount Billed
                </TableCell>
                <TableCell
                  key="amount paid out"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Amount Paid Out
                </TableCell>
                <TableCell
                  key="remaining on policy"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Remaining on Policy
                </TableCell>
                <TableCell
                  key="contact"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Contact
                </TableCell>
                <TableCell
                  key="notes"
                  padding={"normal"}
                  style={{ color: "white" }}
                  width="300px"
                >
                  Notes
                </TableCell>
                <TableCell
                  key="actions"
                  padding={"normal"}
                  style={{ color: "white" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {eligibilities &&
                eligibilities.map((eligibility, idx) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    sx={{ cursor: "pointer" }}
                    key={idx}
                  >
                    <TableCell>{eligibility.procedure}</TableCell>
                    <TableCell>{eligibility.procedure_date}</TableCell>
                    <TableCell>
                      {eligibility.status === "Not Started" && (
                        <Chip
                          label={eligibility.status}
                          color="default"
                          variant="contained"
                        ></Chip>
                      )}
                      {eligibility.status === "Under Review" && (
                        <Chip
                          label={eligibility.status}
                          color="warning"
                          variant="contained"
                        ></Chip>
                      )}
                      {eligibility.status === "Verified" && (
                        <Chip
                          label={eligibility.status}
                          color="success"
                          variant="contained"
                        ></Chip>
                      )}
                      {eligibility.status === "Rejected" && (
                        <Chip
                          label={eligibility.status}
                          color="error"
                          variant="contained"
                        ></Chip>
                      )}
                    </TableCell>
                    <TableCell>{eligibility.coverage_limits}</TableCell>
                    <TableCell>{eligibility.current_amount_billed}</TableCell>
                    <TableCell>{eligibility.amount_paid_out}</TableCell>
                    <TableCell>{eligibility.remaining_on_policy}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        onClick={() =>
                          handleContactButtonClick(eligibility._id, idx)
                        }
                      >
                        View / Edit
                      </Button>
                    </TableCell>
                    <TableCell>{eligibility.notes}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        endIcon={<EditIcon />}
                        color="secondary"
                        onClick={() =>
                          handleEditButtonClick(eligibility._id, idx)
                        }
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {(!eligibilities || eligibilities.length === 0) && (
                <TableRow hover tabIndex={-1} sx={{ cursor: "pointer" }}>
                  <TableCell colSpan={10}>
                    <Typography variant="h6" textAlign="center">
                      No Eligibilities
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog
        open={eligibilityDialogOpen}
        onClose={handleEligibilityDialogClose}
        fullWidth
      >
        <DialogTitle>
          {eligibilityID ? "Edit eligibility" : "Add a new eligibility"}
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2} marginTop="5px">
            <TextField
              label="Procedure"
              fullWidth
              value={procedure}
              error={procedureError}
              onChange={(e) => handleChange("procedure", e)}
            ></TextField>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  size="medium"
                  label="Procedure Date"
                  onChange={handleProcedureDateChange}
                  value={procedureDate}
                  disableFuture={true}
                  onError={() => setProcedureDateError(true)}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      error: procedureDateError,
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
            <FormControl>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={status}
                onChange={(e) => handleChange("status", e)}
              >
                <MenuItem value="Not Started">Not Started</MenuItem>
                <MenuItem value="Under Review">Under Reviewed</MenuItem>
                <MenuItem value="Verified">Verified</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Coverage Limits"
              fullWidth
              value={coverageLimits}
              error={coverageLimitsError}
              onChange={(e) => handleChange("coverage limits", e)}
            ></TextField>
            <TextField
              label="Current Amount Billed"
              fullWidth
              value={currentAmountBilled}
              error={currentAmountBilledError}
              onChange={(e) => handleChange("current amount billed", e)}
            ></TextField>
            <TextField
              label="Amount Paid Out"
              fullWidth
              value={amountPaidOut}
              error={amountPaidOutError}
              onChange={(e) => handleChange("amount paid out", e)}
            ></TextField>
            <TextField
              label="Remaining on Policy"
              fullWidth
              value={remainingOnPolicy}
              error={remainingOnPolicyError}
              onChange={(e) => handleChange("remaining on policy", e)}
            ></TextField>
            <TextField
              label="Notes"
              fullWidth
              value={notes}
              onChange={(e) => handleChange("notes", e)}
            ></TextField>
          </Stack>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="error"
            onClick={handleEligibilityDialogClose}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={contactDialogOpen}
        onClose={handleContactDialogClose}
        fullWidth
      >
        <DialogTitle>Point of Contact</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleContactDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <TextField
              label="First name"
              name="first_name"
              variant="standard"
              value={contact.first_name}
              onChange={handleContactChange}
            ></TextField>
            <TextField
              label="Last name"
              name="last_name"
              variant="standard"
              value={contact.last_name}
              onChange={handleContactChange}
            ></TextField>
            <TextField
              label="ID Number"
              name="id_number"
              variant="standard"
              value={contact.id_number}
              onChange={handleContactChange}
            ></TextField>
            <TextField
              label="Phone Number"
              name="phone_number"
              variant="standard"
              value={contact.phone_number}
              onChange={handleContactChange}
            ></TextField>
            <TextField
              label="Email"
              name="email"
              variant="standard"
              value={contact.email}
              onChange={handleContactChange}
            ></TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSaveContact}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default Eligibility;
