import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Stack,
  Typography,
  Input,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Save as SaveIcon, SignLanguage } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";
import { MEDICAL_BILLING_PERSON, secureApi } from "../config";
import { Context } from "../context";
import TopNav from "../components/topnav";

const useStyles = makeStyles({
  centeredInput: {
    "& input": {
      textAlign: "center",
    },
  },
  boldText: {
    fontWeight: "bold",
  },
  underlineText: {
    textDecoration: "underline",
  },
});

const today = new Date();
const year = today.getFullYear();
const month = today.getMonth() + 1;
const date = today.getDate();

const PATIENT_SIGN_TYPE = "patient";
const WITNESS_SIGN_TYPE = "witness";
const MEDICAL_PROVIDER_SIGN_TYPE = "medical provider";

const ConsentForm = () => {
  const classes = useStyles();
  const { patient_id, index } = useParams();
  const navigate = useNavigate();
  const { setLoggedIn, user } = useContext(Context);

  if (user && user.role === MEDICAL_BILLING_PERSON) {
    toast.warn("You don't have permission to access this page.");
    navigate("/");
  }

  const [patient, setPatient] = useState({});
  const [procedure, setProcedure] = useState("");
  const [medicalProviderName, setMedicalProviderName] = useState("");
  const [patientSignature, setPatientSignature] = useState("");
  const [witnessSignature, setWitnessSignature] = useState("");
  const [medicalProviderSignature, setMedicalProviderSignature] = useState("");
  const [patientSignatureDate, setPatientSignatureDate] = useState("");
  const [medicalProviderSignatureDate, setMedicalProviderSignatureDate] =
    useState("");
  const [signPad, setSignPad] = useState({});
  const [signed, setSigned] = useState(false);
  const [signType, setSignType] = useState();
  const [signModalOpen, setSignModalOpen] = useState(false);

  const handleClickPatientSign = () => {
    setSignType(PATIENT_SIGN_TYPE);
    setSignModalOpen(true);
  };

  const handleClickWitnessSign = () => {
    setSignType(WITNESS_SIGN_TYPE);
    setSignModalOpen(true);
  };

  const handleClickMedicalProviderSign = () => {
    setSignType(MEDICAL_PROVIDER_SIGN_TYPE);
    setSignModalOpen(true);
  };

  const handleClear = () => {
    signPad.clear();
    setSigned(false);
  };

  const handleSignModalClose = (event, reason) => {
    if (reason !== "backdropClick") {
      handleClear();
      setSignModalOpen(false);
    }
  };

  const handleSign = () => {
    const physicalSign = signPad.getTrimmedCanvas().toDataURL("image/png");
    if (signType === PATIENT_SIGN_TYPE) {
      setPatientSignature(physicalSign);
      if (patientSignatureDate.trim() === "")
        setPatientSignatureDate(`${month}/${date}/${year}`);
    } else if (signType === WITNESS_SIGN_TYPE)
      setWitnessSignature(physicalSign);
    else if (signType === MEDICAL_PROVIDER_SIGN_TYPE) {
      setMedicalProviderSignature(physicalSign);
      if (medicalProviderSignatureDate.trim() === "")
        setMedicalProviderSignatureDate(`${month}/${date}/${year}`);
    }

    handleSignModalClose();
  };

  const handleSave = () => {
    if (parseInt(index) >= 0)
      secureApi(localStorage.getItem("alex-med-token"))
        .put(`/consent-form/${patient_id}/${index}`, {
          patientName: `${patient.patientInfo.first_name} ${patient.patientInfo.last_name}`,
          procedure,
          patientSignature,
          patientSignatureDate,
          witnessSignature,
          medicalProviderName,
          medicalProviderSignature,
          medicalProviderSignatureDate,
        })
        .then(() => {
          toast.success("Consent form has been updated successfully!");
          navigate(`/patient/${patient_id}`);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    else {
      secureApi(localStorage.getItem("alex-med-token"))
        .post(`/consent-form/${patient_id}`, {
          patientName: `${patient.patientInfo.first_name} ${patient.patientInfo.last_name}`,
          procedure,
          patientSignature,
          patientSignatureDate,
          witnessSignature,
          medicalProviderName,
          medicalProviderSignature,
          medicalProviderSignatureDate,
        })
        .then(() => {
          toast.success("Consent form has been saved successfully!");
          navigate(`/patient/${patient_id}`);
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    }
  };

  useEffect(() => {
    if (patient_id) {
      secureApi(localStorage.getItem("alex-med-token"))
        .get(`patient/${patient_id}`)
        .then((response) => {
          const { data: patient } = response;
          setPatient(patient);

          if (parseInt(index) >= 0)
            if (patient && patient.consentForm[index]) {
              setProcedure(patient.consentForm[index].procedure);
              setMedicalProviderName(
                patient.consentForm[index].medicalProviderName
              );
              setPatientSignature(patient.consentForm[index].patientSignature);
              setWitnessSignature(patient.consentForm[index].witnessSignature);
              setMedicalProviderSignature(
                patient.consentForm[index].medicalProviderSignature
              );
              setPatientSignatureDate(
                patient.consentForm[index].patientSignatureDate
              );
              setMedicalProviderSignatureDate(
                patient.consentForm[index].medicalProviderSignatureDate
              );
            } else {
              toast.warn("Corresponding consent form doesn't exist");
              navigate(`/patient/${patient_id}`);
            }
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    } else {
      toast.warn("Invalid patient ID!");
      navigate("/");
    }
  }, [patient_id, index]);
  return (
    <>
      <TopNav />
      <Stack direction="column" padding="30px">
        <Typography variant="h3" textAlign="center" marginBottom="20px">
          Modal Medical Services PLLC
        </Typography>
        <Typography variant="h4" textAlign="center">
          Informed Consent for Pain Procedures
        </Typography>
        <p>
          You have a pain problem that has not been relieved by routine
          treatments. A procedure, specifically an injection or operation, is
          now indicated for further evaluation or treatment of your pain. There
          is{` `}
          <span className={[classes.boldText, classes.underlineText].join(" ")}>
            NO
          </span>
          <span className={classes.boldText}>{` guarantee `}</span>that a
          procedure will cure your pain, and in rare cases,
          <span className={classes.boldText}>{` it could become WORSE`}</span>,
          even when the procedure is performed in a technically perfect manner.
          The degree and duration of pain relief varies from person to person,
          so after your procedure, we will reevaluate your progress, then
          determine if further treatment is necessary. Your physician will
          explain the details of the procedure listed below. Tell the physicians
          if you are taking any blood thinners such as
          <span className={classes.boldText}>
            {` PLAVIX, Aspirin, Coumadin, Lovenox or HEPARIN`}
          </span>
          , as these can cause excessive bleeding and a procedure should{` `}
          <span className={[classes.boldText, classes.underlineText].join(" ")}>
            NOT
          </span>
          {` `}be performed. Alternatives to the procedure include medications,
          physical therapy, acupuncture, surgery, etc. Benefits include
          increased likelihood of correct diagnosis and /or of decrease or
          elimination of pain.
        </p>
        <p style={{ marginBottom: 0 }}>
          <span className={[classes.boldText, classes.underlineText].join(" ")}>
            Risks
          </span>
          {` are:`}
        </p>
        <ul>
          <li>
            <span className={classes.boldText}>
              {`Increased pain and allergic reaction `}
            </span>
            from local anesthetics, iodine, contrast (X-Ray dye), materials
            containing latex, IV anesthetics and/or other medications
          </li>
          <li>
            <span className={classes.boldText}>
              {`Allergic reaction from steroid; `}
            </span>
            facial flushing, elevation in blood glucose, headache, increased
            appetite, weight gain, swelling, menstrual irregularities,
            hoarseness, numbness, infection, abnormal heartbeats, increased
            blood pressure, stroke, heart attack, insomnia, etc.
          </li>
          <li>
            <span className={classes.boldText}>{`Infection `}</span>on skin,
            tissue, bones, joints, discs, nerves, ligaments, possibly blood
            stream (Sepsis), brain and spinal cord (Meningitis) may require
            hospitalization
          </li>
          <li>
            <span className={classes.boldText}>{`Bleeding `}</span>into epidural
            space (Epidural Hematoma) and into spinal canal (Spinal Hematoma)
            may require surgical interventions such as an evacuation of blood
            from epidural space or spinal canal and decompression surgery
          </li>
          <li className={classes.boldText}>
            Nerve damage, nerve injury, tissue injury, tissue damage, temporary
            and permanent numbness and/or weakness, paralysis, spinal cord
            injury, urinary and/or fecal incontinence
          </li>
          <li>
            <span className={classes.boldText}>{`Headache `}</span>(“Spinal
            headache”) may require blood patch (Injecting your own blood into
            epidural space) and hospitalization.
          </li>
          <li>
            <span className={classes.boldText}>
              Trigger Point Injection, Peripheral Nerve block, Occipital Nerve
              Block:
            </span>
            {` In addition to the above complications, `}
            <span
              className={classes.boldText}
            >{`air in lung (Pneumothorax) `}</span>
            requiring chest tube in hospital, local pain from tissue and/or
            nerve irritation, dimpling of/depression in skin.
          </li>
        </ul>
        <Stack direction="column">
          <Stack direction="row" alignContent="center">
            <Typography variant="h6" marginRight="10px" fontWeight="bold">
              Procedure:
            </Typography>
            <Typography variant="h6"></Typography>
            <Input
              value={procedure}
              onChange={(e) => setProcedure(e.target.value)}
              fullWidth
            ></Input>
          </Stack>
        </Stack>
        <p style={{ marginBottom: 0 }}>
          The incidence of serious complications listed above requiring
          treatment is low, but it may still occur. Your physician believes the
          benefits of the procedure outweigh its risks or it would not have been
          offered to you, and it is your decision and right to accept or decline
          to have the procedure done.{` `}
          <span className={[classes.boldText, classes.underlineText].join(" ")}>
            I have read or had read to me
          </span>
          {` the above information including the Pre-Procedure Patient
          Instruction page. I `}
          <span className={classes.boldText}>UNDERSTAND</span> there are risks
          involved with spinal procedure, to include rare complications, which
          may not have been specifically mentioned above.
        </p>
        <p style={{ marginBottom: 0 }}>
          The risks have been explained to my satisfaction and I accept them and
          consent to any procedure which is performed by
          <span className={classes.boldText}>{` Dr. Alexander Movshis. `}</span>
          I herein authorize physicians, nurse practitioners and their
          associates in Modal Medical Services PLLC to perform this procedure.
        </p>
        <p>
          I also understand that one of the greatest risks involved with pain
          management procedures involves various medications taken, allergies
          and my general medical condition. I will inform the doctor of any
          blood thinning medication taken or any changes in other medications,
          allergies or medical condition prior to any procedure.
        </p>
        <Stack direction="row" spacing={1}>
          <Stack direction="column" width="25%" textAlign="center">
            <Input
              className={classes.centeredInput}
              value={
                patient && patient.patientInfo
                  ? `${patient.patientInfo.first_name} ${patient.patientInfo.last_name}`
                  : ""
              }
              readOnly
              fullWidth
            ></Input>
            <Typography variant="h6">Patient's Name</Typography>
          </Stack>
          <Stack direction="column" width="25%" textAlign="center">
            {patientSignature ? (
              <div style={{ width: "100%", borderBottom: "1px solid grey" }}>
                <img
                  src={patientSignature}
                  alt="patient signature"
                  style={{
                    height: "27px",
                    width: "fit-content",
                    maxWidth: "80%",
                  }}
                />
              </div>
            ) : (
              <Input
                className={classes.centeredInput}
                value="Not signed yet"
                readOnly
              ></Input>
            )}
            <Typography variant="h6">Patient's Signature</Typography>
          </Stack>
          <Stack direction="column" width="25%" textAlign="center">
            <Input
              className={classes.centeredInput}
              value={patientSignatureDate}
              onChange={(e) => setPatientSignatureDate(e.target.value)}
            ></Input>
            <Typography variant="h6">Date</Typography>
          </Stack>
          <Stack direction="column" width="25%" textAlign="center">
            {witnessSignature ? (
              <div style={{ width: "100%", borderBottom: "1px solid grey" }}>
                <img
                  src={witnessSignature}
                  alt="patient signature"
                  style={{
                    height: "27px",
                    width: "fit-content",
                    maxWidth: "80%",
                  }}
                />
              </div>
            ) : (
              <Input
                className={classes.centeredInput}
                value="Not signed yet"
                readOnly
              ></Input>
            )}
            <Typography variant="h6">Witness Signature</Typography>
          </Stack>
        </Stack>
        <p>
          Medical Provider Declaration: I and/or my associate have explained the
          procedure and the pertinent contents of this document to the patient
          and have answered all the patient's questions. To the best of my
          knowledge, the patient has been adequately informed and the patient
          has consented to the above described procedure.
        </p>
        <Stack direction="row" spacing={1}>
          <Stack direction="column" width="50%" textAlign="center">
            <Input
              className={classes.centeredInput}
              value={medicalProviderName}
              onChange={(e) => setMedicalProviderName(e.target.value)}
              fullWidth
            ></Input>
            <Typography variant="h6">Medical Provider's Name</Typography>
          </Stack>
          <Stack direction="column" width="25%" textAlign="center">
            {medicalProviderSignature ? (
              <div style={{ width: "100%", borderBottom: "1px solid grey" }}>
                <img
                  src={medicalProviderSignature}
                  alt="patient signature"
                  style={{
                    height: "27px",
                    width: "fit-content",
                    maxWidth: "80%",
                  }}
                />
              </div>
            ) : (
              <Input
                className={classes.centeredInput}
                value="Not signed yet"
                readOnly
              ></Input>
            )}
            <Typography variant="h6">Medical Provider's Signature</Typography>
          </Stack>
          <Stack direction="column" width="25%" textAlign="center">
            <Input
              className={classes.centeredInput}
              value={medicalProviderSignatureDate}
              onChange={(e) => setMedicalProviderSignatureDate(e.target.value)}
            ></Input>
            <Typography variant="h6">Date</Typography>
          </Stack>
        </Stack>
        <Divider style={{ margin: "10px 0" }} />
        <Stack direction="row" justifyContent="center" spacing={1}>
          <Button
            variant="contained"
            color="error"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<SignLanguage />}
            onClick={handleClickPatientSign}
          >
            Patient Sign
          </Button>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<SignLanguage />}
            onClick={handleClickWitnessSign}
          >
            Witness Sign
          </Button>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<SignLanguage />}
            onClick={handleClickMedicalProviderSign}
          >
            Provider Sign
          </Button>
          <Button
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={handleSave}
          >
            Save
          </Button>
        </Stack>
      </Stack>
      <Dialog open={signModalOpen} onClose={handleSignModalClose} maxWidth="lg">
        <DialogTitle>
          {signType === PATIENT_SIGN_TYPE && "Patient Sign"}
          {signType === WITNESS_SIGN_TYPE && "Witness Sign"}
          {signType === MEDICAL_PROVIDER_SIGN_TYPE && "Medical Provider Sign"}
        </DialogTitle>
        <Divider />
        <DialogContent style={{ width: "fit-content" }}>
          <div
            style={{
              border: "1px solid grey",
              borderRadius: "5px",
              width: "fit-content",
            }}
          >
            <SignatureCanvas
              penColor="black"
              canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
              ref={(ref) => {
                setSignPad(ref);
              }}
              onEnd={() => setSigned(true)}
            />
          </div>
        </DialogContent>
        <Divider style={{ margin: "5px 0px" }} />
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleClear} variant="contained" color="warning">
            Clear
          </Button>
          <Button onClick={handleSign} variant="contained" disabled={!signed}>
            Sign
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConsentForm;
