import { createContext, useState } from "react";

export const Context = createContext({
  loggedIn: false,
  setLoggedIn: () => {},
  user: {},
  setUser: () => {},
  patient: {},
  setPatient: () => {},
  complaint: [0],
  setComplaint: () => {},
  muscleStrain: false,
  setMuscleStrain: () => {},
  initialHPI: [],
  setInitialHPI: () => {},
  pastMedicalHistory: [],
  setPastMedicalHistory: () => {},
  pastSurgicalHistory: [],
  setPastSurgicalHistory: () => {},
  medications: [],
  setMedications: () => {},
  allergies: [],
  setAllergies: () => {},
  imagesReviewed: [],
  setImagesReviewed: () => {},
  assessment: "",
  setAssessment: () => {},
  plans: [],
  setPlans: () => {},
  planText: "",
  setPlanText: () => {},
  authorizations: [],
  setAuthorizations: () => {},
  authorizationText: "",
  setAuthorizationText: () => {},
  dateOfProcedureNote: "",
  setDateOfProcedureNote: () => {},
});

const Provider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [patient, setPatient] = useState({
    _id: "",
    avatar: "",
    chart: "",
    type: "Personal Injury",
    patientInfo: {},
    contactInfo: {},
    emergencyContact: {},
    employment: {},
    conditionRelatedTo: {},
    insuranceLawyerInfo: {},
  });
  const [complaint, setComplaint] = useState([0]);
  const [muscleStrain, setMuscleStrain] = useState(false);
  const [initialHPI, setInitialHPI] = useState([]);
  const [pastMedicalHistory, setPastMedicalHistory] = useState([]);
  const [pastSurgicalHistory, setPastSurgicalHistory] = useState([]);
  const [medications, setMedications] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [imagesReviewed, setImagesReviewed] = useState([]);
  const [assessment, setAssessment] = useState("");
  const [plans, setPlans] = useState([]);
  const [planText, setPlanText] = useState("");
  const [authorizations, setAuthorizations] = useState([]);
  const [authorizationText, setAuthorizationText] = useState("");
  const [dateOfProcedureNote, setDateOfProcedureNote] = useState(
    new Date().toLocaleDateString()
  );

  return (
    <Context.Provider
      value={{
        loggedIn,
        setLoggedIn,
        user,
        setUser,
        patient,
        setPatient,
        complaint,
        setComplaint,
        muscleStrain,
        setMuscleStrain,
        initialHPI,
        setInitialHPI,
        pastMedicalHistory,
        setPastMedicalHistory,
        pastSurgicalHistory,
        setPastSurgicalHistory,
        medications,
        setMedications,
        allergies,
        setAllergies,
        imagesReviewed,
        setImagesReviewed,
        assessment,
        setAssessment,
        plans,
        setPlans,
        planText,
        setPlanText,
        authorizations,
        setAuthorizations,
        authorizationText,
        setAuthorizationText,
        dateOfProcedureNote,
        setDateOfProcedureNote,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
