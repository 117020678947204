import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Table,
  TableSortLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  Menu,
  MenuItem,
  Button,
  Chip,
  Stack,
  TextField,
} from "@mui/material";
import {
  Edit as EditIcon,
  PictureAsPdf as ViewIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { styled, alpha } from "@mui/material/styles";
import { toast } from "react-toastify";
import DeleteNoteModal from "./deleteNoteModal";
import {
  PAIN_CONSULT_NOTE_TYPE,
  PAIN_CONSULT_NOTE_URL,
  PROCEDURE_NOTE_TYPE,
  PROCEDURE_NOTE_URL,
  FOLLOW_UP_NOTE_TYPE,
  FOLLOW_UP_NOTE_URL,
  TELEHEALTH_CONSULT_NOTE_TYPE,
  TELEHEALTH_CONSULT_NOTE_URL,
  secureApi,
  ADMIN,
  MEDICAL_ASSISTANT,
} from "../../config";
import { Context } from "../../context";

const StyledMenu = styled((props) => (
  <Menu
    elevation={8}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function createData(id, type, provider, dateOfService, timeOfService, status) {
  dateOfService = `${dateOfService.split("/")[2]}/${
    dateOfService.split("/")[0]
  }/${dateOfService.split("/")[1]}`;
  return {
    id,
    type,
    provider,
    dateOfService,
    timeOfService,
    status,
  };
}

const headCells = [
  {
    id: "type",
    label: "Type",
  },
  {
    id: "provider",
    label: "Provider",
  },
  {
    id: "dateOfService",
    label: "Date of Service",
  },
  {
    id: "timeOfService",
    label: "Time of Service / Comments",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

export default function Notes({
  painConsultNoteExist,
  setPainConsultNoteExist,
  telehealthConsultNoteExist,
  setTelehealthConsultNoteExist,
  setPainConsultNoteSigned,
}) {
  const { setLoggedIn, user } = useContext(Context);
  const { patient_id } = useParams();
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [deleteNoteModalOpen, setDeleteNoteModalOpen] = useState(false);
  const [noteID, setNoteID] = useState();
  const [order, setOrder] = useState("desc");
  const navigate = useNavigate();

  useEffect(() => {
    if (patient_id)
      secureApi(window.localStorage.getItem("alex-med-token"))
        .get(`/notes/${patient_id}`)
        .then((response) => {
          let { notes } = response.data;
          notes = notes.filter(
            (note) =>
              note["type"] !== PROCEDURE_NOTE_TYPE ||
              (user && user.role !== MEDICAL_ASSISTANT)
          );
          notes = notes.map((note) => {
            let provider = "";
            if (
              note["type"] === PAIN_CONSULT_NOTE_TYPE ||
              note["type"] === FOLLOW_UP_NOTE_TYPE ||
              note["type"] === TELEHEALTH_CONSULT_NOTE_TYPE
            )
              provider = note["provider"];
            else if (note["type"] === PROCEDURE_NOTE_TYPE) {
              provider = note["surgeon"].split("\n").join(", ");
            }
            return createData(
              note["_id"],
              note["type"],
              provider,
              note["dateOfService"] || note["dateOfProcedure"],
              note["timeOfService"] || note["comments"] || "",
              note["status"]
            );
          });
          setNotes(notes);
          setFilteredNotes(notes);
          for (let index in notes) {
            if (notes[index].type === PAIN_CONSULT_NOTE_TYPE) {
              setPainConsultNoteExist(true);
              if (notes[index].status === "signed")
                setPainConsultNoteSigned(true);
            } else if (notes[index].type === TELEHEALTH_CONSULT_NOTE_TYPE) {
              setTelehealthConsultNoteExist(true);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
  }, [patient_id]);

  useEffect(() => {
    if (searchStr) {
      let filteredNotes = [];
      for (let i = 0; i < notes.length; i++) {
        if (
          (notes[i]["type"] !== PROCEDURE_NOTE_TYPE ||
            (user && user.role !== MEDICAL_ASSISTANT)) &&
          (notes[i]["type"].toLowerCase().includes(searchStr.toLowerCase()) ||
            notes[i]["provider"]
              .toLowerCase()
              .includes(searchStr.toLowerCase()) ||
            notes[i]["dateOfService"].includes(searchStr.toLowerCase()) ||
            notes[i]["timeOfService"]
              .toLowerCase()
              .includes(searchStr.toLowerCase()) ||
            notes[i]["status"].toLowerCase().includes(searchStr.toLowerCase()))
        ) {
          filteredNotes = [...filteredNotes, notes[i]];
        }
      }

      setFilteredNotes(filteredNotes);
    } else {
      setFilteredNotes(notes);
    }
  }, [searchStr]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (e, note) => {
    if (note.type === PAIN_CONSULT_NOTE_TYPE) {
      navigate(`/pain-consult-note?patient_id=${patient_id}`);
    } else if (note.type === PROCEDURE_NOTE_TYPE) {
      navigate(`/procedure-note/${note.id}`);
    } else if (note.type === FOLLOW_UP_NOTE_TYPE) {
      navigate(`/followup-note/${note.id}`);
    } else if (note.type === TELEHEALTH_CONSULT_NOTE_TYPE)
      navigate(`/telehealth-consult-note?patient_id=${patient_id}`);
    window.scrollTo(0, 0);
  };

  const handleView = (event, note) => {
    if (note["type"] === PAIN_CONSULT_NOTE_TYPE)
      window.open(`${PAIN_CONSULT_NOTE_URL}/${note["id"]}.pdf`, "_blank");
    else if (note["type"] === PROCEDURE_NOTE_TYPE)
      window.open(`${PROCEDURE_NOTE_URL}/${note["id"]}.pdf`, "_blank");
    else if (note["type"] === FOLLOW_UP_NOTE_TYPE)
      window.open(`${FOLLOW_UP_NOTE_URL}/${note["id"]}.pdf`, "_blank");
    else if (note["type"] === TELEHEALTH_CONSULT_NOTE_TYPE)
      window.open(`${TELEHEALTH_CONSULT_NOTE_URL}/${note["id"]}.pdf`, "_blank");
  };

  const handleDeleteButtonClick = (note) => {
    if (
      ((note.type === PAIN_CONSULT_NOTE_TYPE && !telehealthConsultNoteExist) ||
        (note.type === TELEHEALTH_CONSULT_NOTE_TYPE &&
          !painConsultNoteExist)) &&
      notes.length !== 1
    )
      toast.warning(
        "Initial note can't be deleted if there are any other types of notes!"
      );
    else {
      setNoteID(note.id);
      setDeleteNoteModalOpen(true);
    }
  };

  const handleCancelDeleteNote = () => {
    setDeleteNoteModalOpen(false);
  };

  const deleteNote = () => {
    setDeleteNoteModalOpen(false);
    if (user && user.role === ADMIN)
      secureApi(localStorage.getItem("alex-med-token"))
        .delete(`/note/${noteID}`)
        .then(() => {
          const newNotes = notes.filter((note) => note.id !== noteID);
          const newFilteredNotes = filteredNotes.filter(
            (note) => note.id !== noteID
          );
          setNotes(newNotes);
          setFilteredNotes(newFilteredNotes);
          toast.success("Note has been deleted successfully!");
        })
        .catch((e) => {
          console.log(e);
          if (e.response && e.response.status === 401) {
            toast.warn("Session has been expired. You need to login again!");
            setLoggedIn(false);
            navigate("/login");
          } else {
            toast.error(e.response.data.message);
          }
        });
    else toast.error("You don't have permission ");
  };

  const createSortHandler = () => {
    const isAsc = order === "asc";
    setOrder(isAsc ? "desc" : "asc");
  };

  useEffect(() => {
    if (order === "desc")
      filteredNotes.sort((a, b) =>
        a.dateOfService.localeCompare(b.dateOfService)
      );
    else
      filteredNotes.sort((a, b) =>
        b.dateOfService.localeCompare(a.dateOfService)
      );
    setFilteredNotes(filteredNotes);
  }, [order]);

  return (
    <Box>
      <div style={{ display: "flex", marginBottom: "10px" }}>
        {user && (user.role === ADMIN || user.role === MEDICAL_ASSISTANT) && (
          <div style={{ width: "100%" }}>
            <Button
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              disableElevation
              onClick={(e) => handleClick(e)}
              endIcon={<KeyboardArrowRightIcon />}
            >
              Create
            </Button>
            <StyledMenu
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {(painConsultNoteExist || telehealthConsultNoteExist) &&
                user.role === ADMIN && (
                  <MenuItem>
                    <a
                      data-key={patient_id}
                      href={`/procedure-note?patient_id=${patient_id}`}
                    >
                      Procedure Note
                    </a>
                    <KeyboardArrowRightIcon />
                  </MenuItem>
                )}
              {(painConsultNoteExist || telehealthConsultNoteExist) && (
                <MenuItem>
                  <a
                    data-key={patient_id}
                    href={`/followup-note?patient_id=${patient_id}`}
                  >
                    Follow Up Note
                  </a>
                  <KeyboardArrowRightIcon />
                </MenuItem>
              )}
              {!telehealthConsultNoteExist && !painConsultNoteExist && (
                <MenuItem>
                  <a
                    data-key={patient_id}
                    href={`/pain-consult-note?patient_id=${patient_id}`}
                  >
                    Pain Consult Note
                  </a>
                  <KeyboardArrowRightIcon />
                </MenuItem>
              )}
              {!telehealthConsultNoteExist && !painConsultNoteExist && (
                <MenuItem>
                  <a
                    data-key={patient_id}
                    href={`/telehealth-consult-note?patient_id=${patient_id}`}
                  >
                    New Telehealth Consult Note
                  </a>
                  <KeyboardArrowRightIcon />
                </MenuItem>
              )}
            </StyledMenu>
          </div>
        )}
        <div style={{ width: "100%" }}>
          <TextField
            size="small"
            label="Search"
            variant="outlined"
            style={{ float: "right" }}
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
          />
        </div>
      </div>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHead>
              <TableRow style={{ background: "grey" }}>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    padding={"normal"}
                    style={{ color: "white" }}
                    sortDirection={
                      headCell.id === "dateOfService" ? order : false
                    }
                  >
                    {headCell.id === "dateOfService" ? (
                      <TableSortLabel
                        active={true}
                        direction={order}
                        onClick={() => createSortHandler()}
                      >
                        {headCell.label}
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredNotes.map((row, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>{row.type}</TableCell>
                    <TableCell>{row.provider}</TableCell>
                    <TableCell>{`${row.dateOfService.split("/")[1]}/${
                      row.dateOfService.split("/")[2]
                    }/${row.dateOfService.split("/")[0]}`}</TableCell>
                    <TableCell>{row.timeOfService}</TableCell>
                    <TableCell>
                      {row.status === "signed" ? (
                        <Chip label="signed" color="success" />
                      ) : (
                        <Chip label="unsigned" color="warning" />
                      )}
                    </TableCell>
                    <TableCell style={{ width: "10%" }}>
                      <Stack direction="row" spacing={1}>
                        {user && user.role === ADMIN && (
                          <Button
                            variant="contained"
                            endIcon={<EditIcon />}
                            disabled={
                              user &&
                              user.role === MEDICAL_ASSISTANT &&
                              row.status === "signed"
                            }
                            onClick={(e) => handleEdit(e, row)}
                          >
                            Edit
                          </Button>
                        )}
                        {user && user.role === ADMIN && (
                          <Button
                            variant="contained"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={() => handleDeleteButtonClick(row)}
                          >
                            Delete
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          endIcon={<ViewIcon />}
                          disabled={row.status === "unsigned"}
                          onClick={(e) => handleView(e, row)}
                        >
                          View
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {filteredNotes.length < 1 && (
                <TableRow style={{ height: 53 }}>
                  <TableCell
                    colSpan={6}
                    style={{ textAlign: "center", fontWeight: "bold" }}
                  >
                    No notes yet
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <DeleteNoteModal
        open={deleteNoteModalOpen}
        handleCancel={handleCancelDeleteNote}
        deleteNote={deleteNote}
      />
    </Box>
  );
}
